import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import useStaticData from "./useStaticData";

const staticData = useStaticData();

const appInsights = new ApplicationInsights({
  config: !staticData.appInsightsConnection
    ? {
      // We always instantiate AppInsights even if there is no key
      // so we don't have to worry about the instance being null in app code,
      // but disable it if there is no key so we're not spamming the console with errors.
      disableTelemetry: true,
      instrumentationKey: "null",
    }
    : {
      connectionString: staticData.APPINSIGHTS_CONNECTION,
      disableFetchTracking: false,
      enableAutoRouteTracking: true,
    },
});
appInsights.loadAppInsights();

if (staticData.userName) {
  appInsights.setAuthenticatedUserContext(staticData.userName);
}

export default function useAppInsights() {
  return appInsights;
}
