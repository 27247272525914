<template>
  <div>
    <v-row class="justify-space-around my-5">
      <v-col>
        <v-row cols="12" class="justify-center">
          <v-btn
            class="mb-2"
            size="large"
            color="primary"
            @click.prevent="printTickets()"
            :loading="ticketPrintPending"
            :disabled="ticketsDisabled"
          >
            <v-icon start>fal fa-print</v-icon>
            Print Tickets
          </v-btn>
        </v-row>
        <v-row cols="12" class="justify-center mt-3">
          <v-btn
            variant="text"
            size="small"
            color="primary"
            @click.stop.prevent="downloadTickets()"
            :href="ticketDownloadUrl"
            :loading="ticketDownloadPending"
            :disabled="ticketsDisabled"
          >
            <v-icon start>fal fa-download</v-icon>
            Download Tickets
          </v-btn>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-btn
          size="large"
          variant="text"
          color="primary"
          @click="printWindow()"
        >
          <v-icon start>fal fa-print</v-icon>
          Print Receipt
        </v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-alert
        :type="ticketDownloadSuccess ? 'info' : 'warning'"
        v-if="printErrored"
      >
        Your browser encountered an error while attempting to print your
        tickets.
        <br />
        <br />
        <span v-if="ticketDownloadSuccess">
          They were downloaded as a PDF instead.
        </span>
        <span v-else> Downloading them as a PDF instead. </span>
      </v-alert>
    </v-expand-transition>
  </div>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import printJS from "print-js";
import { TicketStatus, AddonStatus } from "@/models.g";
import { computed, ref } from "vue";

const props = defineProps<{ purchase: TicketPurchaseDtoViewModel }>();
const ticketUrl = computed(() => {
  return `/api/TicketPurchaseDto/ticketsDownload/${props.purchase.publicId}`;
});

const ticketDownloadUrl = computed(() => {
  return ticketUrl.value + "?download=true&uid=" + canaryCookieUid.value;
});

const ticketPrintUrl = computed(() => {
  return ticketUrl.value;
});

const ticketsDisabled = computed(() => {
  // Disallow ticket print if every ticket is in a non-complete state.
  // This includes hold, refunded, and exchanged.
  return (
    props.purchase.tickets!.every((t) => t.status != TicketStatus.Completed) &&
    props.purchase
      .addons!.filter((a) => a.isPrintable)
      .every((t) => t.status != AddonStatus.Completed)
  );
});

function printWindow() {
  window.print();
}

const canaryCookieUid = ref(Math.floor(Math.random() * 10e14));

function regenCanaryCookieUid() {
  return (canaryCookieUid.value = Math.floor(Math.random() * 10e14));
}

function canaryCookieName(uid: number) {
  return `download-ready-${uid}`;
}

const ticketDownloadPending = ref(false);
const ticketDownloadSuccess = ref(false);

function downloadTickets() {
  const uid = regenCanaryCookieUid();
  printErrored.value = false;
  setInterval(() => {
    if (document.cookie.indexOf(canaryCookieName(uid) + "=1") >= 0) {
      ticketDownloadPending.value = false;
      ticketDownloadSuccess.value = true;
      deleteDownloadCanaryCookie(uid);
    }
  }, 5);

  ticketDownloadPending.value = true;
  ticketDownloadSuccess.value = false;
  window.location.href = ticketDownloadUrl.value;
}

function deleteDownloadCanaryCookie(uid: number) {
  document.cookie = `${canaryCookieName(
    uid,
  )}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const ticketPrintPending = ref(false);
const printErrored = ref(false);

function printTickets() {
  ticketPrintPending.value = true;
  printErrored.value = false;

  printJS({
    printable: ticketPrintUrl.value,
    type: "pdf",
    onPrintDialogClose: () => {
      ticketPrintPending.value = false;
    },
    onError: () => {
      // Fall back to just a download to work around
      // https://hagadone.visualstudio.com/Ticketing/_boards/board/t/Ticketing%20Team/Backlog%20items/?workitem=604
      downloadTickets();
      printErrored.value = true;
    },
    onLoadingEnd: () => {
      ticketPrintPending.value = false;
    },
  });
}
</script>
