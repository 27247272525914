<template>
  <v-card variant="outlined">
    <v-card-text>
      <labeled-section title="Contact Us" icon="fal fa-question">
        <p>
          Have questions?
          <slot name="suggested-questions" />
          Contact us via one of the following methods:
        </p>
        <v-row no-gutters>
          <v-col cols="12">
            <labeled-section title="Call" icon="fal fa-phone">
              <a
                class="contact-link"
                :href="`tel:${staticData.APP_SUPPORT_PHONE}`"
              >
                {{ staticData.APP_SUPPORT_PHONE }}
              </a>
            </labeled-section>
          </v-col>
          <v-col cols="12">
            <labeled-section title="Email" icon="fal fa-envelope">
              <a class="contact-link" :href="mailtoLink">
                {{ staticData.APP_SUPPORT_EMAIL }}
              </a>
            </labeled-section>
          </v-col>
        </v-row>
      </labeled-section>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import useStaticData from "@common/composables/useStaticData";

const props = defineProps<{
  subject?: string;
}>();

const staticData = useStaticData();

const mailtoLink = computed(() => {
  return (
    "mailto:" +
    staticData.APP_SUPPORT_EMAIL +
    (props.subject ? "?subject=" + encodeURIComponent(props.subject) : "")
  );
});
</script>
