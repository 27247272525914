<template>
  <div>
    <v-expand-transition>
      <div v-if="!expanded">
        <slot name="less" />
        <a @click="expanded = true" class="d-block mt-1 pointer"> Show More </a>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-if="expanded">
        <slot name="more" />
        <a @click="expanded = false" class="d-block mt-1 pointer">
          Show Less
        </a>
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const expanded = ref(false);
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
