<template>
  <v-row>
    <v-col>
      <!-- This wrapping v-form prevents this input from contributing
      any error state to the main parent form that wraps the whole purchase. -->
      <v-form>
        <c-input
          :model="service.applyDiscountCode"
          for="discountCode"
          :error-messages="service.applyDiscountCode.message"
          @input="
            () => {
              service.applyDiscountCode.message = null;
            }
          "
          @keypress.native.enter.prevent="applyDiscount()"
          :label="(staticData.isPublicUser ? 'Promo' : 'Discount') + ' Code'"
        >
        </c-input>
      </v-form>
    </v-col>
    <v-col class="flex-grow-0">
      <v-btn
        color="primary"
        :loading="service.applyDiscountCode.isLoading"
        :disabled="!service.applyDiscountCode.args.discountCode"
        variant="outlined"
        @click="applyDiscount()"
      >
        <v-icon start>fal fa-check</v-icon>
        <span v-if="staticData.isPublicUser" class="d-none d-sm-inline">
          Apply Promo Code
        </span>
        <span class="d-none d-sm-inline" v-else>Apply Discount Code</span>
        <span class="d-sm-none">Apply Code</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";

import { setupPurchaseReloadHandling } from "@common/utils";
import { onMounted } from "vue";
import useStaticData from "@common/composables/useStaticData";

const staticData = useStaticData();
const service = new PublicPurchaseServiceViewModel();

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();

async function applyDiscount() {
  const endpoint = service.applyDiscountCode;

  endpoint.args.publicId = props.purchase.publicId;

  await endpoint.invokeWithArgs();
  endpoint.args.discountCode = null;
}

onMounted(() => {
  setupPurchaseReloadHandling(props.purchase, service.applyDiscountCode);
});
</script>