<template>
  <div class="purchase-price-summary">
    <v-alert v-if="purchase.refundComment" type="info">
      Refund Comment: {{ purchase.refundComment }}
    </v-alert>

    <v-card variant="outlined" class="purchase-price-summary">
      <v-table density="compact">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Item</th>
              <th class="price-column">Price</th>
              <th class="price-column">Service Fee</th>
              <th class="price-column">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <!-- Tickets -->
            <template
              v-if="purchase?.tickets?.length || !hideEmptyTicketsSection"
              :key="ticket.ticketId + '-tickets'"
            >
              <template
                v-for="(
                  { firstTicket: ticket, allTickets }, i
                ) in groupedTickets"
              >
                <tr v-if="i === 0">
                  <td colspan="99">
                    <div class="text-h6 text-left">
                      <span class="pr-2">Tickets: </span>
                    </div>
                  </td>
                </tr>
                <tr
                  :class="
                    purchase.status == PurchaseStatus.Completed
                      ? 'no-bottom-border'
                      : ''
                  "
                >
                  <td>
                    <v-col>
                      <v-row>
                        <div>
                          {{ ticket.description }}
                          <strong v-if="allTickets.length > 1">
                            x{{ allTickets.length }}
                          </strong>
                        </div>
                      </v-row>
                      <v-row>
                        <div v-if="purchase?.eventDate?.isSeated">
                          <span
                            class="text-grey-darken-1"
                            style="line-height: 1.2; font-size: 12px"
                          >
                            Seat{{ allTickets.length == 1 ? "" : "s" }}:
                          </span>
                          {{ allTickets.map((t) => t.seatNumber).join(", ") }}
                        </div>
                      </v-row>
                    </v-col>
                  </td>

                  <td class="price-column">
                    {{ formatCurrency(ticket.price * allTickets.length) }}
                  </td>
                  <td class="price-column">
                    <span
                      v-if="purchase.waiveTicketFees && ticket.fees == 0"
                      class="font-italic font-weight-light"
                    >
                      Waived
                    </span>
                    <span v-else>
                      {{ formatCurrency(ticket.fees * allTickets.length) }}
                    </span>
                  </td>
                  <td class="price-column">
                    {{
                      formatCurrency(
                        ticket.subtotalWithFees * allTickets.length,
                      )
                    }}
                  </td>
                </tr>
                <tr
                  :class="
                    purchase.status == PurchaseStatus.Completed
                      ? 'no-top-border'
                      : ''
                  "
                  v-if="
                    purchase.status == PurchaseStatus.Completed ||
                    isRefundedOrExchanged(ticket)
                  "
                >
                  <td
                    :key="ticket.ticketId + '-ticket-row2'"
                    v-if="purchase.status == PurchaseStatus.Completed"
                  >
                    <div class="d-flex">
                      <div
                        class="flex-grow-1 mr-3 pb-2"
                        style="line-height: 1.2; font-size: 12px"
                      >
                        <span class="text-grey-darken-1">
                          Ticket ID{{ allTickets.length == 1 ? "" : "s" }}:
                        </span>
                        <span class="">
                          {{
                            ticketIDsToShortString(
                              allTickets.map((t) => t?.ticketId ?? 0),
                            )
                          }}
                        </span>
                      </div>
                      <div class="text-right text-no-wrap">
                        <template v-if="ticket.relatedPurchase">
                          <router-link
                            class="no-print"
                            :to="{
                              name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
                              params: {
                                publicId: ticket.relatedPurchase.publicId,
                              },
                            }"
                          >
                            View {{ ticket.relatedPurchase.description }}
                          </router-link>
                        </template>
                        <ticket-status-badge :ticket="ticket" class="ml-2" />
                      </div>
                    </div>
                  </td>
                  <template v-if="isRefundedOrExchanged(ticket)">
                    <td class="price-column">
                      {{ formatCurrency(-ticket.price * allTickets.length) }}
                    </td>
                    <td class="price-column">
                      <span
                        v-if="purchase.waiveTicketFees && ticket.fees == 0"
                        class="font-italic font-weight-light"
                      >
                        Waived
                      </span>
                      <span v-else>
                        {{ formatCurrency(-ticket.fees * allTickets.length) }}
                      </span>
                    </td>
                    <td class="price-column">
                      re
                      {{
                        formatCurrency(
                          -ticket.subtotalWithFees * allTickets.length,
                        )
                      }}
                    </td>
                  </template>
                  <td v-else colspan="4"></td>
                </tr>
              </template>
            </template>

            <!-- Discounts -->
            <template v-if="purchase?.discounts?.length">
              <tr>
                <td colspan="99">
                  <div class="text-h6 text-left">
                    <span class="pr-2">Discounts: </span>
                  </div>
                </td>
              </tr>
              <tr
                v-for="discount in purchase.discounts.filter(
                  (d) => (d?.amount ?? 0) > 0,
                )"
                :key="discount.discountId + '-discount-' + discount.description"
              >
                <td colspan="3">{{ discount.description }}</td>
                <td class="price-column">
                  {{ formatCurrency(-discount.amount) }}
                </td>
              </tr>
            </template>

            <!-- Extras -->
            <template
              v-if="
                purchase.refundable == RefundableState.Purchased ||
                purchase?.addons?.length ||
                purchase.convenienceFee ||
                purchase.salesTax
              "
            >
              <tr>
                <td colspan="99">
                  <div class="text-h6 text-left">
                    <span class="pr-2">Additional Items: </span>
                  </div>
                </td>
              </tr>

              <tr
                v-if="purchase.refundable == RefundableState.Purchased"
                :class="
                  purchase.refundedRefundableFee ? 'no-bottom-border' : ''
                "
              >
                <td colspan="3">Purchase Protection</td>
                <td class="price-column">
                  {{ formatCurrency(purchase.refundableFee) }}
                </td>
              </tr>
              <tr v-if="purchase.refundedRefundableFee">
                <td colspan="3">Purchase Protection (refund)</td>
                <td class="price-column">
                  {{ formatCurrency(purchase.refundedRefundableFee) }}
                </td>
              </tr>

              <template
                v-for="{ firstAddon: addon, allAddons } in groupedAddons"
                :key="addon.addonId + '-addon'"
              >
                <tr
                  :class="
                    purchase.status == PurchaseStatus.Completed
                      ? 'no-bottom-border'
                      : ''
                  "
                >
                  <td colspan="3">
                    {{ addon.name }}
                    <strong v-if="allAddons.length > 1">
                      x{{ allAddons.length }}
                    </strong>
                  </td>
                  <td class="price-column">
                    {{ formatCurrency(addon.price * allAddons.length) }}
                  </td>
                </tr>

                <tr
                  :key="addon.addonId + '-addon-row2'"
                  v-if="purchase.status == PurchaseStatus.Completed"
                >
                  <td>
                    <div class="d-flex">
                      <div
                        class="flex-grow-1 mr-3 pb-2"
                        style="line-height: 1.2; font-size: 12px"
                      >
                        <span class="text-grey-darken-1">
                          Item ID{{ allAddons.length == 1 ? "" : "s" }}:
                        </span>
                        <span class="">
                          {{
                            ticketIDsToShortString(
                              allAddons.map((t) => t?.addonId ?? 0),
                              "A",
                            )
                          }}
                        </span>
                      </div>
                      <div class="text-right text-no-wrap">
                        <template v-if="addon.relatedPurchase">
                          <router-link
                            class="no-print"
                            :to="{
                              name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
                              params: {
                                publicId: addon.relatedPurchase.publicId,
                              },
                            }"
                          >
                            View {{ addon.relatedPurchase.description }}
                          </router-link>
                        </template>
                        <addon-status-badge :addon="addon" class="ml-2" />
                      </div>
                    </div>
                  </td>
                  <template v-if="addon.refundedPrice">
                    <td class="price-column" colspan="3">
                      {{
                        formatCurrency(-addon.refundedPrice * allAddons.length)
                      }}
                    </td>
                  </template>
                  <td v-else colspan="3"></td>
                </tr>
              </template>

              <tr v-if="purchase.convenienceFee">
                <td colspan="3">Convenience Fee</td>
                <td class="price-column">
                  {{ formatCurrency(purchase.convenienceFee) }}
                </td>
              </tr>
              <tr v-if="purchase.refundedConvenienceFee">
                <td colspan="3">Convenience Fee (refund)</td>
                <td class="price-column">
                  {{ formatCurrency(purchase.refundedConvenienceFee) }}
                </td>
              </tr>

              <tr v-if="purchase.salesTax">
                <td colspan="3">Sales Tax</td>
                <td class="price-column">
                  {{ formatCurrency(purchase.salesTax) }}
                </td>
              </tr>
              <tr v-if="purchase.refundedSalesTax">
                <td colspan="3">Sales Tax (refund)</td>
                <td class="price-column">
                  {{ formatCurrency(purchase.refundedSalesTax) }}
                </td>
              </tr>
            </template>

            <tr v-if="purchase.grandTotal || !hideEmptyTicketsSection">
              <td colspan="99">
                <div class="text-h6 text-right">
                  <span class="pr-2">Grand Total: </span>
                  {{ formatCurrency(purchase.grandTotal) }}
                </div>
              </td>
            </tr>

            <!-- Payments -->
            <!-- Always show if there are any payments. Also always show for non-public purchases.
We hide for public purchases because it will always be empty with a negative balance
until the user completes payment. -->
            <template
              v-if="purchase?.payments?.length || !hideEmptyPaymentsSection"
            >
              <tr>
                <td colspan="99">
                  <div class="text-h6 text-left">
                    <span class="pr-2">Payments: </span>
                  </div>
                </td>
              </tr>
              <tr
                v-for="payment in purchase.payments"
                :key="payment.paymentId + '-payment'"
              >
                <td>
                  {{ payment.description }}

                  <span
                    class="float-right"
                    v-if="payment?.relatedPurchases?.length"
                  >
                    <div v-for="related in payment.relatedPurchases">
                      <router-link
                        class="no-print"
                        :to="{
                          name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
                          params: { publicId: related.publicId },
                        }"
                      >
                        {{ related.description }}
                      </router-link>
                    </div>
                  </span>
                </td>
                <td></td>
                <td></td>
                <td class="price-column">
                  {{ formatCurrency(payment.amount) }}
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="text-h6 text-right">
                    <span class="pr-2">Balance: </span>
                    <span :class="purchase.balance < 0 ? 'error--text' : ''">
                      {{ formatCurrency(purchase.balance) }}
                    </span>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-table>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import {
  TicketStatus,
  TicketPurchaseTicket,
  PurchaseType,
  PurchaseStatus,
  RefundableState,
} from "@/models.g";
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import {
  formatCurrency,
  getTicketGroupings,
  getAddonGroupings,
} from "@common/utils";
import { COMMON_ROUTE_NAMES } from "@common/routing";

import TicketStatusBadge from "./ticket-status-badge.vue";
import AddonStatusBadge from "./addon-status-badge.vue";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    purchase: TicketPurchaseDtoViewModel;
    hideEmptyPaymentsSection?: boolean;
  }>(),
  { hideEmptyPaymentsSection: false },
);

const hideEmptyTicketsSection = computed(() => {
  return props.purchase.type == PurchaseType.Refund;
});

const groupedTickets = computed(() => {
  return getTicketGroupings(props.purchase.tickets!, props.purchase.eventDate!);
});

const groupedAddons = computed(() => {
  return getAddonGroupings(props.purchase.addons!);
});

function isRefundedOrExchanged(ticket: TicketPurchaseTicket) {
  return (
    ticket.status == TicketStatus.Refunded ||
    ticket.status == TicketStatus.Exchanged
  );
}

function ticketIDsToShortString(ids: number[], prefix: string = "") {
  const sorted = [...ids].sort();
  let ret = "";

  for (let i = 0; i < sorted.length; i++) {
    let currentId = sorted[i];

    ret += ", " + prefix + currentId;

    let rangeLength = 0;
    for (;;) {
      const nextId = sorted[i + 1];
      if (nextId && nextId == currentId + 1) {
        i++;
        currentId = nextId;
        rangeLength++;
      } else {
        break;
      }
    }
    if (rangeLength > 1) {
      ret += "–" + prefix + currentId;
    } else if (rangeLength == 1) {
      ret += ", " + prefix + currentId;
    }
  }

  return ret.replace(/^,\s+/, "");
}
</script>

<style lang="scss">
.purchase-price-summary {
  td {
    padding: 4px 16px !important;
  }
}
</style>
