import {
  EventPriceDto,
  RefundableState,
  TicketPurchaseAddon,
  TicketPurchaseDto,
} from "@/models.g";
import { lightFormat } from "date-fns";
import { useGtm } from "@gtm-support/vue-gtm";

export function purchaseStarted(purchase: TicketPurchaseDto) {
  if (!purchase.isPublic) return;
  useGtm()?.trackEvent({
    event: "view_item",
    ecommerce: {
      currency: "USD",
      items: purchase.eventDate?.eventPrices?.map((p) =>
        priceToItem(purchase, p, 1),
      ),
    },
  });
}

export function cartQuantityChanged(
  purchase: TicketPurchaseDto,
  price: EventPriceDto,
  quantity: number,
) {
  if (!purchase.isPublic) return;
  useGtm()?.trackEvent({
    event: quantity > 0 ? "add_to_cart" : "remove_from_cart",
    ecommerce: {
      currency: "USD",
      value: price.price! * quantity,
      items: [priceToItem(purchase, price, quantity)],
    },
  });
}

export function purchaseProtectionChanged(
  purchase: TicketPurchaseDto,
  state: RefundableState,
) {
  if (!purchase.isPublic) return;
  if (purchase.refundable == RefundableState.NotOffered) return;
  const quantity = state == RefundableState.Purchased ? 1 : -1;

  useGtm()?.trackEvent({
    event: quantity > 0 ? "add_to_cart" : "remove_from_cart",
    ecommerce: {
      currency: "USD",
      value: purchase.refundableFee,
      items: [purchaseProtectionToItem(purchase)],
    },
  });
}

export function beginCheckout(purchase: TicketPurchaseDto) {
  if (!purchase.isPublic) return;

  useGtm()?.trackEvent({
    event: "begin_checkout",
    ecommerce: {
      ...purchaseToEvent(purchase),
    },
  });

  // When a user starts "checkout" (e.g. the second page of the public purchase flow),
  // then at that point they'll see purchase protection and addons.
  useGtm()?.trackEvent({
    event: "view_item",
    ecommerce: {
      currency: "USD",
      items: [
        // Addons:
        ...(purchase.addons || []).map((a) => addonToItem(purchase, a)),

        // Purchase protection:
        ...(purchase.refundable !== RefundableState.NotOffered
          ? [purchaseProtectionToItem(purchase)]
          : []),
      ],
    },
  });
}

export function purchaseComplete(purchase: TicketPurchaseDto) {
  if (!purchase.isPublic) return;

  useGtm()?.trackEvent({
    event: "purchase",
    ecommerce: {
      ...purchaseToEvent(purchase),
      transaction_id: purchase.publicId,
    },
  });
}

function purchaseToEvent(purchase: TicketPurchaseDto) {
  return {
    currency: "USD",
    value: purchase.grandTotal,
    coupon: purchase.discounts?.[0]?.description,
    items: [
      // Tickets:
      ...(purchase.tickets || [])
        .map((ticket) => ({
          ticket,
          price: purchase.eventDate?.eventPrices?.find(
            (p) => p.id == ticket.eventPriceId,
          ),
        }))
        .filter((d) => d.price)
        .map((d) => ({
          ...priceToItem(purchase, d.price!, 1),
          discount: d.ticket.discountTotal,
        })),

      // Addons:
      ...(purchase.addons || []).map((a) => addonToItem(purchase, a)),

      // Purchase protection:
      ...(purchase.refundable == RefundableState.Purchased
        ? [purchaseProtectionToItem(purchase)]
        : []),
    ],
  };
}

function priceToItem(
  purchase: TicketPurchaseDto,
  price: EventPriceDto,
  quantity: number,
) {
  const dateFormatted = purchaseDate(purchase);

  return {
    item_id: `${purchase.eventDateId}.${price.id}`,
    item_name: `${purchase.eventDate?.eventName} - ${dateFormatted} - ${price.displayName}`,
    price: price.price,
    quantity: quantity,
    item_category: "Ticket",
    item_category2: price.displayName,
    item_category3: purchase.eventDate?.eventName,
    item_category4: dateFormatted,
  };
}

function addonToItem(purchase: TicketPurchaseDto, addon: TicketPurchaseAddon) {
  return {
    item_id: `${purchase.eventDateId}.A${addon.addonItemConfigurationId}`,
    item_name: addon.name,
    price: addon.price,
    quantity: 1,
    item_category: "Addon Item",
    item_category2: addon.name,
    item_category3: purchase.eventDate?.eventName,
    item_category4: purchaseDate(purchase),
  };
}

function purchaseProtectionToItem(purchase: TicketPurchaseDto) {
  return {
    item_id: `${purchase.eventDateId}.pp`,
    item_name: `${purchase.eventDate?.eventName} - Purchase Protection`,
    price: purchase.refundableFee,
    quantity: 1,
    item_category: "Purchase Protection",
    item_category2: "Purchase Protection",
    item_category3: purchase.eventDate?.eventName,
    item_category4: purchaseDate(purchase),
  };
}

function purchaseDate(purchase: TicketPurchaseDto) {
  return lightFormat(
    purchase.eventDate?.eventStartDateTimeLocal!,
    "yyyy-MM-dd h:mm a",
  );
}
