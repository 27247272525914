export const validCountries = [
  { name: "USA", abbreviation: "US" },
  { name: "Canada", abbreviation: "CA" },
];

export function validStates(
  country: string | (typeof validCountries)[0] | null,
  fallbackToAll: boolean = false,
) {
  const countryCode =
    typeof country == "string" ? country : country?.abbreviation;

  switch (countryCode) {
    case "US":
      return usaStates;
    case "CA":
      return canadaProvinces;
    default:
      return fallbackToAll ? allStates : [];
  }
}

export const usaStates = [
  { name: "IDAHO", abbreviation: "ID" },
  { name: "WASHINGTON", abbreviation: "WA" },
  { name: "", abbreviation: "" },

  { name: "ALABAMA", abbreviation: "AL" },
  { name: "ALASKA", abbreviation: "AK" },
  { name: "AMERICAN SAMOA", abbreviation: "AS" },
  { name: "ARIZONA", abbreviation: "AZ" },
  { name: "ARKANSAS", abbreviation: "AR" },
  { name: "CALIFORNIA", abbreviation: "CA" },
  { name: "COLORADO", abbreviation: "CO" },
  { name: "CONNECTICUT", abbreviation: "CT" },
  { name: "DELAWARE", abbreviation: "DE" },
  { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
  { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
  { name: "FLORIDA", abbreviation: "FL" },
  { name: "GEORGIA", abbreviation: "GA" },
  { name: "GUAM", abbreviation: "GU" },
  { name: "HAWAII", abbreviation: "HI" },
  { name: "IDAHO", abbreviation: "ID2" },
  { name: "ILLINOIS", abbreviation: "IL" },
  { name: "INDIANA", abbreviation: "IN" },
  { name: "IOWA", abbreviation: "IA" },
  { name: "KANSAS", abbreviation: "KS" },
  { name: "KENTUCKY", abbreviation: "KY" },
  { name: "LOUISIANA", abbreviation: "LA" },
  { name: "MAINE", abbreviation: "ME" },
  { name: "MARSHALL ISLANDS", abbreviation: "MH" },
  { name: "MARYLAND", abbreviation: "MD" },
  { name: "MASSACHUSETTS", abbreviation: "MA" },
  { name: "MICHIGAN", abbreviation: "MI" },
  { name: "MINNESOTA", abbreviation: "MN" },
  { name: "MISSISSIPPI", abbreviation: "MS" },
  { name: "MISSOURI", abbreviation: "MO" },
  { name: "MONTANA", abbreviation: "MT" },
  { name: "NEBRASKA", abbreviation: "NE" },
  { name: "NEVADA", abbreviation: "NV" },
  { name: "NEW HAMPSHIRE", abbreviation: "NH" },
  { name: "NEW JERSEY", abbreviation: "NJ" },
  { name: "NEW MEXICO", abbreviation: "NM" },
  { name: "NEW YORK", abbreviation: "NY" },
  { name: "NORTH CAROLINA", abbreviation: "NC" },
  { name: "NORTH DAKOTA", abbreviation: "ND" },
  { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
  { name: "OHIO", abbreviation: "OH" },
  { name: "OKLAHOMA", abbreviation: "OK" },
  { name: "OREGON", abbreviation: "OR" },
  { name: "PALAU", abbreviation: "PW" },
  { name: "PENNSYLVANIA", abbreviation: "PA" },
  { name: "PUERTO RICO", abbreviation: "PR" },
  { name: "RHODE ISLAND", abbreviation: "RI" },
  { name: "SOUTH CAROLINA", abbreviation: "SC" },
  { name: "SOUTH DAKOTA", abbreviation: "SD" },
  { name: "TENNESSEE", abbreviation: "TN" },
  { name: "TEXAS", abbreviation: "TX" },
  { name: "UTAH", abbreviation: "UT" },
  { name: "VERMONT", abbreviation: "VT" },
  { name: "VIRGIN ISLANDS", abbreviation: "VI" },
  { name: "VIRGINIA", abbreviation: "VA" },
  { name: "WASHINGTON", abbreviation: "WA2" },
  { name: "WEST VIRGINIA", abbreviation: "WV" },
  { name: "WISCONSIN", abbreviation: "WI" },
  { name: "WYOMING", abbreviation: "WY" },
];

export const canadaProvinces = [
  { name: "ALBERTA", abbreviation: "AB" },
  { name: "BRITISH COLUMBIA", abbreviation: "BC" },
  { name: "MANITOBA", abbreviation: "MB" },
  { name: "NEW BRUNSWICK", abbreviation: "NB" },
  { name: "NEWFOUNDLAND AND LABRADOR", abbreviation: "NL" },
  { name: "NORTHWEST TERRITORIES", abbreviation: "NT" },
  { name: "NOVA SCOTIA", abbreviation: "NS" },
  { name: "NUNAVUT", abbreviation: "NU" },
  { name: "ONTARIO", abbreviation: "ON" },
  { name: "PRINCE EDWARD ISLAND", abbreviation: "PE" },
  { name: "QUEBEC", abbreviation: "QC" },
  { name: "SASKATCHEWAN", abbreviation: "SK" },
  { name: "YUKON", abbreviation: "YT" },
];

export const allStates = [...usaStates, ...canadaProvinces];
