<template>
  <div class="purchase-dietary-input-row">
    <div style="vertical-align: top">
      <v-checkbox
        class="py-4"
        :label="restriction.allergen"
        :model-value="enabled"
        @click="toggleRestriction"
      ></v-checkbox>
    </div>
    <div class="dietary-cell-quantity">
      <v-fade-transition>
        <quantity-input
          class="my-1"
          v-if="enabled"
          :value="localQuantity"
          :min="1"
          :loading="service.setDietaryRestriction.isLoading"
          @update="(v) => setQuantity(v)"
        ></quantity-input>
      </v-fade-transition>
    </div>
    <div class="dietary-cell-comment">
      <v-expand-transition>
        <div v-if="enabled && (restriction.canComment || restriction.comment)">
          <div class="my-2">
            <v-textarea
              counter="50"
              label="Comment"
              rows="1"
              auto-grow
              persistent-hint
              :hint="
                restriction.allergen == 'Other'
                  ? 'Please let us know of any additional dietary restrictions. If our Culinary Team has any questions, we will reach out.'
                  : ''
              "
              v-model="localComment"
              @update:model-value="debouncedSave"
            ></v-textarea>
          </div>
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div
          v-if="service.setDietaryRestriction.message"
          class="text-error font-weight-bold my-3"
          role="alert"
        >
          {{ service.setDietaryRestriction.message }}
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { TicketPurchaseDietaryRestriction } from "@/models.g";
import { setupPurchaseReloadHandling } from "@common/utils";
import { computed, onMounted, ref } from "vue";
import { throttle } from "lodash-es";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
  restriction: TicketPurchaseDietaryRestriction;
}>();
const service = new PublicPurchaseServiceViewModel();
const localQuantity = ref(0);
const localComment = ref("");
const debouncedSave = throttle(() => save(), 1000);

const enabled = computed(() => {
  return localQuantity.value > 0;
});

function toggleRestriction() {
  if (enabled.value) {
    setQuantity(0);
  } else {
    setQuantity(1);
  }
}

function refreshLocalFields() {
  localQuantity.value = props.restriction.guestQuantity!;
  localComment.value = props.restriction.comment || "";
}

function setQuantity(input: string | number) {
  localQuantity.value = parseInt(input.toString());
  debouncedSave();
}

async function save() {
  try {
    await service.setDietaryRestriction(
      props.purchase.publicId,
      props.restriction.allergen,
      localQuantity.value,
      localComment.value,
    );
  } catch (err) {
    console.error(err);

    // Always refresh the local quantities if there is an error.
    refreshLocalFields();
  }
}

onMounted(() => {
  service.setDietaryRestriction.setConcurrency("debounce");

  setupPurchaseReloadHandling(props.purchase, service.setDietaryRestriction);

  refreshLocalFields();
});
</script>
