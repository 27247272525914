<template>
  <div class="purchase-dietary-input">
    Do you have any dietary restrictions you want our culinary team to be aware
    of? Please note: we proudly serve Gluten-Free and Vegetarian options at all
    of our dining experiences. Please be aware that we may not be able to
    accommodate requests less than 24 hours before the event.

    <v-checkbox
      v-model="enabled"
      :disabled="anySelected"
      label="Yes, one or more members of my party have dietary restrictions
              other than Gluten-Free and Vegetarian."
    >
    </v-checkbox>
    <v-expand-transition>
      <div v-if="enabled">
        <div class="text-body-2 font-weight-bold my-2">
          Please select and provide a count for all restrictions that apply to
          your party:
        </div>
        <v-card variant="outlined">
          <div class="purchase-dietary-input-grid">
            <div class="header">Restriction</div>
            <div class="header dietary-header-quantity">Guest Quantity</div>
            <div class="header dietary-header-comment"></div>
            <purchase-dietary-input-row
              v-for="restriction in purchase.dietaryRestrictions"
              :key="restriction.allergen"
              :purchase="purchase"
              :restriction="restriction"
            />
          </div>
        </v-card>
      </div>
    </v-expand-transition>
  </div>
</template>

<style lang="scss">
.purchase-dietary-input-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 16px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;

  $border: thin solid rgba(0, 0, 0, 0.12);

  .purchase-dietary-input-row {
    display: contents;

    &:not(:last-child) > * {
      border-bottom: $border;
    }

    > * {
      padding: 0 16px;
    }
  }

  .header {
    border-bottom: $border;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    font-size: 0.75rem;
    padding: 4px 16px;
    display: grid;
  }

  .dietary-header-quantity,
  .dietary-cell-quantity {
    text-align: center;
    vertical-align: top;
    width: 196px;
  }

  .dietary-cell-quantity {
    padding: 2px 16px;
    min-height: 57px;
  }

  .dietary-cell-comment {
    grid-column: span 2;
  }

  @media only screen and (max-width: 959px) {
    .dietary-cell-comment {
      border-bottom: none !important;
    }
    .dietary-header-comment {
      display: none;
    }
  }
  @media only screen and (min-width: 960px) {
    grid-template-columns: auto auto 1fr;
    .dietary-cell-comment {
      grid-column: span 1;
    }
  }
}
</style>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import PurchaseDietaryInputRow from "./purchase-dietary-input-row.vue";
import { computed } from "vue";

const props = defineProps<{ purchase: TicketPurchaseDtoViewModel }>();
const enabled = ref(
  props.purchase.dietaryRestrictions!.some((d) => d.guestQuantity || d.comment),
);

const anySelected = computed(() => {
  return props.purchase.dietaryRestrictions?.some((d) => d.guestQuantity! > 0);
});
</script>
