<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-stepper v-model="step" class="elevation-12">
          <v-card class="ma-n3">
            <!-- Log in -->
            <v-stepper-window v-if="step === 1">
              <v-form @submit.prevent="signInService.signIn.invokeWithArgs()">
                <v-card-title> Sign In</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="signInService.signIn.args.userName"
                    label="Username"
                    name="Username"
                    prepend-icon="fal fa-at"
                    inputmode="email"
                    autofocus
                    :hide-details="false"
                  />

                  <v-text-field
                    v-model="signInService.signIn.args.password"
                    label="Password"
                    name="Password"
                    prepend-icon="fal fa-lock"
                    type="password"
                    :hide-details="false"
                  />

                  <v-checkbox
                    density="compact"
                    v-model="signInService.signIn.args.rememberMe"
                    name="RememberMe"
                    label="Remember Me?"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
                <c-loader-status
                  :loaders="{ 'no-progress': [signInService.signIn] }"
                >
                  <v-card-actions>
                    <a class="text-body-2 pl-3 cursor-pointer" @click="step++">
                      Forgot Password
                    </a>
                    <v-spacer />
                    <v-btn
                      variant="elevated"
                      color="primary"
                      type="submit"
                      :loading="
                        (signInService.signIn.isLoading ||
                          signInService.signIn.wasSuccessful) ??
                        false
                      "
                    >
                      Log In
                    </v-btn>
                  </v-card-actions>
                </c-loader-status>
              </v-form>
            </v-stepper-window>
            <!-- Password reset request -->

            <v-stepper-window v-if="step === 2">
              <v-card-title>
                <v-btn
                  icon
                  @click="step = 1"
                  color="primary"
                  variant="text"
                  class="ml-n3"
                >
                  <v-icon>fal fa-arrow-left</v-icon>
                </v-btn>
                Forgot Password
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="signInService.requestPasswordReset.args.userName"
                  label="Username"
                  name="Username"
                  prepend-icon="fal fa-at"
                  inputmode="email"
                  :hide-details="false"
                />
              </v-card-text>
              <c-loader-status
                :loaders="{
                  'no-progress': [signInService.requestPasswordReset],
                }"
              >
                <v-alert
                  type="success"
                  v-if="signInService.requestPasswordReset.wasSuccessful"
                  :value="signInService.requestPasswordReset.wasSuccessful"
                >
                  {{ signInService.requestPasswordReset.message }}
                </v-alert>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    variant="elevated"
                    color="primary"
                    type="submit"
                    :loading="signInService.requestPasswordReset.isLoading"
                    @click="signInService.requestPasswordReset.invokeWithArgs()"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </c-loader-status>
            </v-stepper-window>
          </v-card>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { SignInServiceViewModel } from "@public/viewmodels.g";
import { useTitle } from "@common/composables/useTitle";

const props = defineProps({
  returnUrl: String,
});

const step = ref(1);
const signInService = new SignInServiceViewModel();

useTitle("Sign In");

signInService.signIn.onFulfilled(() => {
  // These navigations must be performed by setting window.location
  // so that we get a full-page reload, which will then allow the server
  // to start serving up the private app (since we're currently on the public app).
  if (props.returnUrl) {
    window.location.href = props.returnUrl;
  } else {
    window.location.href = "/";
  }
});
</script>
