<template>
  <div class="hide-non-error-details purchase-contact-inputs">
    <v-form fast-fail ref="form">
      <v-row>
        <v-col cols="12" md="4">
          <c-input
            :model="purchase"
            for="firstName"
            autocomplete="given-name"
            :readonly="readonly"
            :label="`First Name  ${staticData.isPublicUser ? '(Required)' : ''}`"
            :rules="[requiredRule('First Name is required.')]"
            validate-on-blur
          />
        </v-col>
        <v-col cols="12" md="4">
          <c-input
            :model="purchase"
            for="lastName"
            autocomplete="family-name"
            :readonly="readonly"
            :label="`Last Name ${staticData.isPublicUser ? '(Required)' : ''}`"
            :rules="[requiredRule('Last Name is required.')]"
            validate-on-blur
          />
        </v-col>
        <v-col cols="12" md="4">
          <c-input
            :model="purchase"
            for="phone"
            inputmode="tel"
            autocomplete="tel"
            :readonly="readonly"
            :label="`Phone ${staticData.isPublicUser ? '(Required)' : ''}`"
            :rules="[
              ...purchase.$getRules('phone'),
              requiredRule('Phone is required.'),
            ]"
            validate-on-blur
          />
          <c-input
            v-if="purchase.tenantTextMessagingEnabled"
            class="mt-md-2"
            :model="purchase"
            for="isAllowTextMessaging"
            label="Text me important updates about my event"
            :readonly="readonly"
            hint="I agree to receive messages for marketing and updates regarding my event. Data rates may apply, reply STOP to opt out."
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <c-input
            class="mt-md-2"
            :model="purchase"
            for="email"
            inputmode="email"
            autocomplete="email"
            :readonly="readonly"
            :label="`Email ${staticData.isPublicUser ? '(Required)' : ''}`"
            :rules="[
              ...purchase.$getRules('email'),
              requiredRule('Email is required.'),
            ]"
            :hint="
              !purchase.confirmationCode
                ? 'An email confirmation of your purchase will be sent to this email address.'
                : ''
            "
            type="email"
            persistent-hint
            validate-on-blur
          />
          <slot name="under-email" />
        </v-col>
        <v-col v-if="staticData.MARKETING_ENABLED" cols="12" md="8">
          <v-radio-group
            :model-value="
              hasPickedMarketingPref
            "
            @update:model-value="hasPickedMarketingPrefChanged"
            label="Would you like to sign-up for our marketing communications?"
            class="mt-0"
            :rules="[
              (_) => props.purchase.marketingOptOut != null || 'Please select an option',
            ]"
          >
            <!-- Verbiage from #723 -->
            <v-radio
              :value="false"
              label="Yes - I'd love to hear about ticket discounts, giveaways &amp; more!"
            ></v-radio>
            <v-radio
              :value="true"
              label="No - I am not interested in hearing about the good deals."
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { PurchaseStatus } from "@/models.g";
import { ref } from "vue";
import useStaticData from "@common/composables/useStaticData";
import { VForm } from "vuetify/components";

const modelValue = defineModel<boolean | null>({
  default: null,
});

const props = withDefaults(
  defineProps<{
    purchase: TicketPurchaseDtoViewModel;
    readonly?: boolean;
  }>(),
  { readonly: false },
);

const hasPickedMarketingPref = ref<boolean>();
const staticData = useStaticData();
const requiredRule = (message: string) => (v: string | null) => {
  return !!v || !staticData.isPublicUser || message;
};
const form = ref<VForm>();

watch(
  () => form.value?.isValid,
  (v) => {
    modelValue.value = v ?? null;
  },
);

async function hasPickedMarketingPrefChanged(value: any) {
  props.purchase.marketingOptOut = value
  hasPickedMarketingPref.value = value
}

async function validate() {
  return await form.value?.validate();
}

// Default text opt-in set to true
props.purchase.isAllowTextMessaging = true;
if (props.purchase.marketingOptOut != null){
  hasPickedMarketingPref.value = props.purchase.marketingOptOut
} else {
  if (props.purchase.status == PurchaseStatus.Started) {
    hasPickedMarketingPref.value = false;
  }

  // Github #42 https://github.com/orgs/HMACDEV/projects/8/views/3?pane=issue&itemId=43256367
  if (!props.purchase.isPublic) {
    hasPickedMarketingPref.value = true;
    props.purchase.marketingOptOut = true;
  }
}




props.purchase.$addRule("phone", "phone", (v) =>
  // Modified from http://regexlib.com/REDetails.aspx?regexp_id=97
  // North american phone numbers
  v && !/^(([+]?(1|52))?([- .(]*\d{3})[- .)]*)(\d{3})([- .]*\d{4})$/.test(v)
    ? "Only valid North American phone numbers are allowed. Please include your area code."
    : true,
);

props.purchase.$addRule("email", "email", (v) =>
  //https://emailregex.com/
  v &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v,
  )
    ? "Please double-check your email address."
    : true,
);

defineExpose({ validate });
</script>

<style lang="scss">
.purchase-contact-inputs {
  .v-input--is-readonly {
    &.v-text-field .v-input__slot {
      background: #f7f7f7 !important;
    }

    &.v-input--checkbox .v-icon {
      color: #afafaf !important;
    }
  }
}
</style>
