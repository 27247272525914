import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import {
  CommonRoutes,
  COMMON_ROUTE_NAMES,
  matchCommonLegacyRoutes,
} from "@common/routing";
import useStaticData from "@common/composables/useStaticData";
import SignIn from "@public/views/sign-in.vue";
import Home from "@public/views/home.vue";
import EventDateSingle from "@common/views/event-date-single.vue";
import PurchaseRedirecting from "@common/views/purchase-redirecting.vue";
import PurchaseSingle from "@public/views/purchase-single.vue";
import Error404 from "@common/views/error-404.vue";

const staticData = useStaticData();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...(CommonRoutes as any),
    {
      path: "/sign-in",
      name: "sign-in",
      component: SignIn,
      props: (route) => ({ returnUrl: route.query.returnUrl }),
    },
    ...(staticData.APP_TID >= 1
      ? <RouteRecordRaw[]>[
          {
            path: "/",
            name: "home",
            component: Home,
          },
          {
            path: "/event-date/:id",
            name: COMMON_ROUTE_NAMES.EventDateSingle,
            component: EventDateSingle,
            props: true,
          },
          {
            path: "/purchase/:publicId",
            name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
            component: PurchaseSingle,
            props: true,
          },
          {
            path: "/purchase/:publicId/redirecting",
            name: COMMON_ROUTE_NAMES.TicketPurchaseRedirecting,
            component: PurchaseRedirecting,
            props: (route) => ({
              publicId: route.params.publicId,
              redirectUrl: route.query.redirectUrl,
            }),
          },
        ]
      : []),

    {
      name: "admin-404",
      path: "/:pathMatch(admin.*)*",
      component: Error404,
      props: { suggestLogin: true },
    },
    {
      name: "agent-404",
      path: "/:pathMatch(agent.*)*",
      component: Error404,
      props: { suggestLogin: true },
    },
    {
      name: "error-404",
      path: "/:pathMatch(.*)*",
      component: Error404,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const matchedLegacyRoute = matchCommonLegacyRoutes(to);
  if (matchedLegacyRoute) {
    next(matchedLegacyRoute);
    return;
  }

  const calendarRoute = /\/Tickets\/#\/?calendar/.exec(to.fullPath);
  if (calendarRoute) {
    next({ path: "/", query: { tab: "calendar" } });
    return;
  }

  const homeRoute = /\/Tickets\/#\/?$/.exec(to.fullPath);
  if (homeRoute) {
    next({ path: "/" });
    return;
  }

  next();
});

export default router;
