<template>
  <div class="purchase-quantity-selector">
    <div
      v-if="purchase.eventDate && purchase.eventDate.isSeated"
      class="text-caption font-italic"
    >
      <template v-if="isTouch()">
        <!-- Explanation of panning mechanism is essentially implicit from this statement,
              and so doesn't need an explanation on mobile. -->
        Pinch to zoom.
      </template>
      <template v-else>
        Use the buttons to zoom. Click-and-drag to move around.
      </template>
      Tap on a seat to view pricing and add it to your purchase.
    </div>

    <v-card variant="outlined">
      <!--      Event Has Seat Map-->
      <div v-if="purchase?.eventDate?.isSeated">
        <purchase-seat-map :purchase="purchase"></purchase-seat-map>
      </div>

      <!--      Event without Seat Map-->
      <v-table v-else dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Item</th>
              <th class="text-center ticket-quantity-cell">Quantity</th>
              <th class="text-right hidden-sm-and-down" width="140px">Price</th>
              <th class="text-right col-subtotal-with-fees">
                Subtotal with fees
              </th>
            </tr>
          </thead>
          <tbody>
            <purchase-quantity-selector-row
              v-for="eventPrice in purchase?.eventDate?.eventPrices"
              :purchase="purchase"
              :eventPrice="eventPrice"
              :key="eventPrice.id + '-price'"
            />
            <tr>
              <td>
                <v-fade-transition>
                  <div
                    class="text-h6 text-info py-3"
                    v-if="purchase?.payments?.length"
                  >
                    <span class="pr-2">Balance: </span>
                    <span
                      :class="
                        (purchase?.balance ?? -1) < 0 ? 'error--text' : ''
                      "
                    >
                      {{ formatCurrency(purchase.balance) }}
                    </span>
                  </div>
                </v-fade-transition>
              </td>
              <td :colspan="display.mdAndUp ? 3 : 2">
                <div class="text-h6 text-right py-3">
                  <span class="pr-2">Subtotal: </span>
                  {{ formatCurrency(totalPrice) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import PurchaseQuantitySelectorRow from "./purchase-quantity-selector-row.vue";
import PurchaseSeatMap from "./purchase-seat-map.vue";
import { formatCurrency, isTouch } from "@common/utils";
import { computed } from "vue";
import { useDisplay } from "vuetify";

const display = ref(useDisplay());

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();
/** Returns total price of selected tickets, not including any discounts. */
const totalPrice = computed(() => {
  return (props.purchase.tickets || []).reduce((acc, ticketQuantity) => {
    return acc + (ticketQuantity.subtotalWithFees ?? 0);
  }, 0);
});
</script>

<style lang="scss">
.purchase-quantity-selector {
  .ticket-quantity-cell {
    max-width: 300px;

    @media screen and (max-width: 599px) {
      width: 100px;
    }
  }

  .col-subtotal-with-fees {
    width: 100px;
    @media screen and (min-width: 960px) {
      width: 140px;
    }
  }

  table {
    table-layout: fixed;
  }
}
</style>
