<template>
  <!-- Unused labeled section at the top so that the nesting context 
  of the inner labeled sections causes the styling to match the other pages (purchase details & create) -->

  <!-- This page was designed to the specs given in PBI #663
    https://hagadone.visualstudio.com/Ticketing/_workitems/edit/663
  -->
  <div>
    <labeled-section class="ticket-purchase-receipt-page">
      <h1
        class="text-center text-h4 mb-4 mt-n10 font-weight-bold text-uppercase"
      >
        {{ staticData.APP_NAME }}
      </h1>

      <labeled-section title="Receipt" icon="">
        <template #header>
          <h1 class="mx-auto">
            <i class="pr-3 fal fa-receipt"></i>
            Receipt
            <c-display
              :model="purchase"
              for="creationDate"
              element="small"
              class="font-weight-regular"
            />
          </h1>
        </template>

        <div class="text-h5 mt-10">
          <v-row no-gutters>
            <v-col>
              <div>
                {{ purchase.eventDate?.eventName }}
              </div>
            </v-col>
          </v-row>
          <div>
            <strong>{{ dateTimeFormatted }}</strong>
          </div>
          <v-row no-gutters>
            <v-col class="align-self-center text-right">
              {{ purchase.firstName }} {{ purchase.lastName }}
            </v-col>
          </v-row>
        </div>
        <purchase-price-summary :purchase="purchase" />
      </labeled-section>

      <purchase-confirmation-section :purchase="purchase">
      </purchase-confirmation-section>
    </labeled-section>
  </div>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed } from "vue";
import PurchasePriceSummary from "@common/purchase/purchase-price-summary.vue";
import PurchaseConfirmationSection from "@common/purchase/purchase-confirmation-section.vue";
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import useStaticData from "@common/composables/useStaticData";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();

const staticData = useStaticData();

const eventDate = computed(() => {
  return props.purchase.eventDate!;
});

const dateTimeFormatted = computed(() => {
  return format(
    eventDate.value.eventStartDateTimeLocal!,
    "h:mm a 'on' EEEE, MMMM do, yyyy",
  );
});
</script>

<style lang="scss">
.ticket-purchase-receipt-page {
  @media screen {
    display: none !important;
  }
  @media print {
    zoom: 60%;

    .purchase-price-summary {
      zoom: 108%;

      .price-column {
        width: auto !important;
        white-space: nowrap;
      }
    }
  }
}
</style>
