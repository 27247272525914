<template>
  <v-container class="page-container">
    <c-loader-status
      :loaders="{ 'no-initial-content no-error-content': [purchase.$load] }"
    >
      <v-expand-transition group mode="out-in">
        <purchase-create
          v-if="purchase.status == PurchaseStatus.Started"
          :purchase="purchase"
          :service="service"
        />

        <div v-else>
          <purchase-receipt-print :purchase="purchase" />
          <purchase-details
            :purchase="purchase"
            :service="service"
            class="no-print"
          />
        </div>
      </v-expand-transition>
    </c-loader-status>
  </v-container>
</template>

<script setup lang="ts">
import PurchaseCreate from "@public/components/purchase-create.vue";
import PurchaseDetails from "@public/components/purchase-details.vue";
import PurchaseReceiptPrint from "@common/purchase/purchase-receipt-print.vue";

import {
  TicketPurchaseDtoViewModel,
  PublicPurchaseServiceViewModel,
} from "@public/viewmodels.g";
import { PurchaseStatus } from "@public/models.g";
import { propDisplay } from "coalesce-vue";
import { scrollToTop } from "@common/utils";
import { differenceInMilliseconds } from "date-fns";
import { useTitle } from "@common/composables/useTitle";
import { onBeforeRouteLeave } from "vue-router";

const service = new PublicPurchaseServiceViewModel();
const purchase = new TicketPurchaseDtoViewModel();

const props = defineProps<{
  publicId: string;
}>();

const pageTitle = computed(() => {
  let ret = "";

  switch (purchase.status) {
    case PurchaseStatus.Started:
      ret = "Ticket Sale";
      break;
    case PurchaseStatus.Completed:
      ret = "Purchase Confirmation";
      break;
    case null:
      ret = purchase.$load.wasSuccessful === false ? "Error" : "Loading";
      break;
    default:
      ret = propDisplay(purchase, "status") + " Purchase";
      break;
  }

  if (purchase.confirmationCode) {
    ret = ret + " - " + purchase.confirmationCode;
  }

  if (purchase.eventDate?.eventName) {
    ret = ret + " - " + purchase.eventDate.eventName;
  }

  return ret;
});

useTitle(pageTitle.value);

purchase.$load.onFulfilled((res) => {
  if (res.wasSuccessful) scrollToTop();
});

purchase.$load(props.publicId);

onBeforeRouteLeave((to, _, next) => {
  if (
    to.name == "ticket-purchase-redirecting" ||
    purchase.status !== PurchaseStatus.Started
  ) {
    return next();
  }

  // The confirm function on iOS safari instantly returns false after the back button has been pressed.
  // So, we'll time how long it takes to run. If it takes beyond some arbitrary amount of time,
  // it must have actually prompted the user, which pauses JS execution.
  // If it seems to have completed instantly, it probably didn't prompt the user.
  const startTime = new Date();
  const confirmationResult = confirm(
    "Are you sure you wish to navigate away? Your purchase is not yet complete.",
  );
  const endTime = new Date();
  const wasConfirmationPrompted =
    differenceInMilliseconds(endTime, startTime) > 300;

  if (confirmationResult || !wasConfirmationPrompted) {
    return next();
  }

  return false;
});
</script>
