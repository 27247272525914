<template>
  <v-scale-transition>
    <v-alert
      v-if="shouldShow"
      :type="isGettingClose ? 'warning' : 'info'"
      variant="outlined"
      border="start"
    >
      You have until
      <c-display
        :model="purchase"
        for="expirationDate"
        format="h:mm a"
      ></c-display>

      <template v-if="isFutureExpiration">
        (
        <c-display
          :model="purchase"
          for="expirationDate"
          :key="'fromnow-' + now.valueOf()"
          :format="{ distance: true, addSuffix: false }"
        ></c-display>
        )
      </template>
      to complete your purchase.
    </v-alert>
  </v-scale-transition>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { differenceInMinutes } from "date-fns";
import { computed, onUnmounted, ref } from "vue";

const now = ref(new Date());
const intervalId = setInterval(() => (now.value = new Date()), 10000);

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();

const isFutureExpiration = computed(() => {
  return (
    props.purchase.expirationDate && props.purchase.expirationDate > now.value
  );
});

const isGettingClose = computed(() => {
  if (!props.purchase.expirationDate) {
    return null;
  }
  return differenceInMinutes(props.purchase.expirationDate, now.value) <= 3;
});

const shouldShow = computed(() => {
  return (
    props.purchase.expirationDate != null && props.purchase.tickets?.length
  );
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>
