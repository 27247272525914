<template>
  <span class="event-date-start-purchase-btn no-print">
    <!-- Dialog for custom sold-out message -->
    <v-dialog
      v-if="
        showCustomSoldOutMessage &&
        eventDate.unpurchasableReason &&
        eventDate.eventCustomSoldOutText &&
        (eventDate.customMoreOptionsUrl == '' ||
          eventDate.customMoreOptionsUrl == null)
      "
      v-model="showCustomSoldOutMessage"
      max-width="640"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="blue"
          variant="outlined"
          v-bind="props"
          :prepend-icon="moreOptionsIcon"
        >
          {{ buttonTextMoreOptions }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>{{ buttonTextMoreOptions }}</v-card-title>
        <v-card-text>
          {{ eventDate.eventCustomSoldOutText }}
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn
            color="error"
            variant="text"
            @click="closeCustomSoldOutMessage"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Button for custom more options URL -->
    <v-btn
      v-if="
        eventDate.unpurchasableReason &&
        eventDate.customMoreOptionsUrl != '' &&
        eventDate.customMoreOptionsUrl != null
      "
      color="blue"
      variant="outlined"
      @click="openExtWindow(eventDate.customMoreOptionsUrl)"
      :prepend-icon="moreOptionsIcon"
    >
      {{ buttonTextMoreOptions }}
    </v-btn>

    <!-- Button for custom more options without a URL -->
    <v-btn
      v-if="
        eventDate.unpurchasableReason &&
        (eventDate.customMoreOptionsUrl == '' ||
          eventDate.customMoreOptionsUrl == null)
      "
      color="blue"
      variant="outlined"
      @click="openExtWindow(eventDate.customMoreOptionsUrl)"
      :prepend-icon="moreOptionsIcon"
    >
      {{ buttonTextMoreOptions }}
    </v-btn>

    <!-- Snackbar for error messages -->
    <v-snackbar
      v-if="renderSnackbar"
      color="error"
      v-model="hasError"
      :timeout="5000"
      location="top"
    >
      {{ error }}
    </v-snackbar>

    <!-- Start Purchase Button -->
    <v-btn
      v-if="!eventDate.unpurchasableReason"
      v-bind="$attrs"
      @click="startPurchase"
      :disabled="purchaseService.initiatePurchase.isLoading"
      :loading="isStartPurchaseLoading"
      variant="elevated"
    >
      <slot>
        <v-icon start theme="dark">{{ selectTicketsIcon }}</v-icon>
        {{ buttonTextSelectTickets }}
      </slot>
    </v-btn>
  </span>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { EventDateDto } from "@/models.g";
import { PublicPurchaseServiceViewModel } from "@/viewmodels.g";
import { COMMON_ROUTE_NAMES } from "@common/routing";
import { purchaseStarted } from "@common/analytics";

const props = defineProps<{
  eventDate: EventDateDto;
}>();

// Purchase service singleton
const purchaseService = new PublicPurchaseServiceViewModel();

const showCustomSoldOutMessage = ref(false);
const renderSnackbar = ref(false);
const hasError = ref(false);
const error = ref<string | null>(null);

const moreOptionsIcon = ref("fal fa-option");
const buttonTextMoreOptions = ref("More Options");
const selectTicketsIcon = ref("fal fa-money-bill-wave");
const buttonTextSelectTickets = ref("Select Tickets");
const moreOptionsUrl = ref("");
const selectTicketsUrl = ref("");

// Vue Router
const router = useRouter();

onMounted(() => {
  // Handle custom button text and icons if enabled
  if (props.eventDate.customMoreOptionsIcon) {
    moreOptionsIcon.value = props.eventDate.customMoreOptionsIcon;
  }
  if (props.eventDate.customMoreOptionsButtonText) {
    buttonTextMoreOptions.value = props.eventDate.customMoreOptionsButtonText;
  }
  if (props.eventDate.customSelectTicketsIcon) {
    selectTicketsIcon.value = props.eventDate.customSelectTicketsIcon;
  }
  if (props.eventDate.customSelectTicketsButtonText) {
    buttonTextSelectTickets.value =
      props.eventDate.customSelectTicketsButtonText;
  }

  if (props.eventDate.customMoreOptionsUrl) {
    moreOptionsUrl.value = props.eventDate.customMoreOptionsUrl;
  }

  if (props.eventDate.customSelectTicketsUrl) {
    selectTicketsUrl.value = props.eventDate.customSelectTicketsUrl;
  }
});

const isStartPurchaseLoading = computed(() => {
  return (
    purchaseService.initiatePurchase.isLoading &&
    purchaseService.initiatePurchase.args.eventDateId === props.eventDate.id
  );
});

const openExtWindow = (url: string) => {
  if (url) {
    window.open(url, "_blank");
  }
};

const startPurchase = async () => {
  // Open external URL if provided
  if (selectTicketsUrl.value) {
    window.open(selectTicketsUrl.value, "_blank");
    return;
  }

  const initiatePurchase = purchaseService.initiatePurchase;
  try {
    // Set eventDateId in args
    initiatePurchase.args.eventDateId = props.eventDate.id;
    await initiatePurchase.invokeWithArgs();

    const newPurchase = initiatePurchase.result;
    if (newPurchase) {
      purchaseStarted(newPurchase);

      // Navigate to ticket purchase page
      router.push({
        name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
        params: { publicId: newPurchase.publicId! },
      });
    }
  } catch {
    error.value = initiatePurchase.message;
    hasError.value = true;
    renderSnackbar.value = true;
  }
};

const closeCustomSoldOutMessage = () => {
  showCustomSoldOutMessage.value = false;
};
</script>

<style lang="scss">
.event-date-start-purchase-btn {
  .v-icon.fa-money-bill-wave {
    margin-right: 12px;
  }
}
</style>
