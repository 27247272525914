import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import TicketingCommon, { coalesceVuetify, vuetify } from "@common/setup";
import { createGtm } from "@gtm-support/vue-gtm";

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(coalesceVuetify);

app.use(
  createGtm({
    id: "GTM-WW7HZ9R",
    vueRouter: router,
  }),
);

app.use(TicketingCommon, {
  app: app,
  publicUser: false,
});

declare module "@public/models.g" {
  export interface TicketPurchaseDto {
    localRawCreditCardDetails?: {
      [paymentId: number]: CreditCardDetails;
    };
  }
}
app.mount("#app");
