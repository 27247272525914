<template>
  <v-alert type="error" v-if="!isCompleted" variant="outlined" border="start">
    <v-row>
      <v-col cols="12" :md="purchase.failureMessage ? 6 : 12" class="pt-4">
        <p v-if="purchase.status == PurchaseStatus.Expired">
          Unfortunately, this shopping cart has expired.
        </p>
        <p v-else>
          This purchase is
          <c-display
            :model="purchase"
            for="status"
            element="strong"
          ></c-display>
          .
        </p>

        <div v-if="purchase.failureMessage">
          <span
            style="white-space: pre-wrap; font-size: 1.2em"
            class="font-weight-bold"
            v-text="purchase.failureMessage"
          ></span>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <p v-if="canRestart">
          If you would like, you can start a new purchase for the same event:

          <event-date-start-purchase-btn
            :eventDate="purchase.eventDate"
            color="primary"
            outlined
            small
            class="d-block"
          >
            <v-icon start>fal fa-money-bill-wave</v-icon>
            Start New Purchase
          </event-date-start-purchase-btn>
        </p>

        <slot />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script setup lang="ts">
import EventDateStartPurchaseBtn from "@common/event/event-date-start-purchase-btn.vue";
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { PurchaseStatus } from "@/models.g";
import { scrollToTop } from "@common/utils";
import { computed, nextTick, onMounted } from "vue";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();

const isCompleted = computed(() => {
  return props.purchase.status === PurchaseStatus.Completed;
});

const canRestart = computed(() => {
  // Doesn't exactly match the logic of the server (probably),
  // but its good enough to not show the button on a 3-month-old event.
  return (
    (props.purchase.eventDate?.eventStartDateTimeLocal?.valueOf() ?? 0) >
    new Date().valueOf()
  );
});

onMounted(() => {
  if (!isCompleted.value) {
    nextTick(() => {
      scrollToTop();
    });
  }
});
</script>
