<template>
  <v-card
    border
    v-if="purchase?.discounts?.length"
    class="purchase-discount-summary"
  >
    <v-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="1%"></th>
            <th class="text-left">Discount Description</th>
            <th class="text-right">Applied Discount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in purchase.discounts" :key="discount.discountId">
            <td style="vertical-align: center">
              <v-btn
                color="error"
                variant="text"
                icon
                title="Remove Discount"
                @click="
                  service.removeDiscount(purchase.publicId, discount.discountId)
                "
                :loading="service.removeDiscount.isLoading"
              >
                <v-icon>fal fa-trash</v-icon>
              </v-btn>
            </td>
            <td>{{ discount.description }}</td>
            <td v-if="discount.error">
              <v-alert
                type="info"
                density="compact"
                text
                icon="fal fa-usd-square"
                class="mb-0"
              >
                {{ discount.error }}
              </v-alert>
            </td>
            <td v-else class="text-right">
              {{ formatCurrency(discount.amount) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>

    <c-loader-status
      :loaders="{ '': [service.removeDiscount] }"
      :progress-placeholder="false"
    >
    </c-loader-status>
  </v-card>
</template>

<style lang="scss">
.purchase-discount-summary {
  margin-top: 16px;

  td {
    height: 48px !important;
  }
}
</style>

<script setup lang="ts">
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { formatCurrency, setupPurchaseReloadHandling } from "@common/utils";

const service = new PublicPurchaseServiceViewModel();
service.removeDiscount.setConcurrency("disallow");

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();

setupPurchaseReloadHandling(props.purchase, service.removeDiscount);
</script>
