<template>
  <v-container fluid style="max-width: 1200px" class="public-home px-0">
    <event-dates :extra-filters="['eventId']">
      <template v-slot:list-item-descriptors="{ eventDate }">
        <div style="width: 90px; display: inline-block">
          <div v-if="eventDate.soldOut" class="text-grey">Sold Out!</div>
          <!--          this is for issue #169 logic when we need to know the number of available seat all the time for the calculation-->
          <div
            v-else-if="
              eventDate.availableSeats &&
              eventDate.availableSeats <= 20 &&
              staticData.SHOW_REMAINING_SEATS
            "
            class="font-weight-bold"
          >
            <!-- eventDate.availableSeats will be null if it would have been
          a quantity that doesn't meet the threshold where we disclose this information
          to public users. -->
            Only {{ eventDate.availableSeats }} remaining!
          </div>
          <div v-else>Available</div>
        </div>
      </template>

      <template #actions="{ eventDate }">
        <event-date-start-purchase-btn :eventDate="eventDate" color="secondary">
        </event-date-start-purchase-btn>
      </template>
    </event-dates>
  </v-container>
</template>

<style lang="scss"></style>

<script setup lang="ts">
import EventDates from "@common/components/event-dates.vue";
import EventDateStartPurchaseBtn from "@common/event/event-date-start-purchase-btn.vue";
import useStaticData from "@common/composables/useStaticData";

const staticData = useStaticData();
if(staticData.META_PIXEL_ENABLED){
  (window as any).fbq("trackCustom", "HomePage");
}
</script>

<style lang="scss">
.public-home {
  .header-pagination.c-list-pagination {
    > div:first-child {
      display: none;
    }

    .c-list-range-display--start::before {
      content: "Showing ";
    }

    @media screen and (min-width: 960px) {
      .c-list-range-display--total::after {
        content: " total events";
      }
    }
  }
}
</style>
