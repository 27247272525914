<template>
  <labeled-section
    v-if="purchase.type == PurchaseType.Refund"
    title="Refund"
    :subtitle="purchase.confirmationCode"
    icon="fal fa-undo"
  >
    <p>
      This transaction represents the payments refunded for one or more tickets
      from
      <router-link
        :to="{
          name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
          params: { publicId: purchase.originalPurchase?.publicId },
        }"
      >
        purchase #{{ purchase.originalPurchase?.description }}
      </router-link>
      .
    </p>

    <p>
      The confirmation number for this refund is
      <strong>{{ purchase.confirmationCode }}</strong
      >.
    </p>

    <v-row class="cards-row no-print">
      <v-col>
        <v-card variant="outlined">
          <v-card-text>
            <labeled-section title="Print Receipt" icon="fal fa-print">
              <v-col class="d-flex justify-center">
                <v-btn size="large" color="primary" @click="printWindow()">
                  <v-icon start>fal fa-print</v-icon>
                  Print Receipt
                </v-btn>
              </v-col>
            </labeled-section>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card variant="outlined">
          <v-card-text>
            <labeled-section title="Refunded Purchase" icon="fa fa-arrow-left">
              <v-col class="d-flex justify-center">
                <v-btn
                  size="large"
                  color="primary"
                  :to="{
                    name: COMMON_ROUTE_NAMES.TicketPurchaseSingle,
                    params: { publicId: purchase.originalPurchase?.publicId },
                  }"
                >
                  View Refunded Purchase #{{
                    purchase.originalPurchase?.description
                  }}
                </v-btn>
              </v-col>
            </labeled-section>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </labeled-section>
  <labeled-section
    v-else
    title="Confirmation Number"
    :subtitle="purchase.confirmationCode"
    icon="fal fa-ticket"
  >
    <p class="no-print">
      Thank you for purchasing tickets from {{ staticData.APP_NAME }}! Your
      confirmation number is <strong>{{ purchase.confirmationCode }}</strong
      >.
    </p>
    <p class="no-print">
      Please follow the instructions below to print your tickets.
    </p>
    <p class="print-only">
      <strong>
        This document does not constitute your
        {{ (purchase.tickets?.length ?? 0 > 1) ? "tickets" : "ticket" }}.
      </strong>
      It does not grant you entry into the event. If you're unable to print your
      tickets, bring your photo ID and your confirmation number
      <strong> {{ purchase.confirmationCode }}</strong> to the event entrance
      for assistance.
    </p>

    <slot name="append" />
  </labeled-section>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { PurchaseType } from "@/models.g";
import { COMMON_ROUTE_NAMES } from "@common/routing";
import useStaticData from "@common/composables/useStaticData";

defineProps<{ purchase: TicketPurchaseDtoViewModel }>();
const staticData = useStaticData();

function printWindow() {
  window.print();
}
</script>
