<template>
  <tr class="purchase-addon-selector-row">
    <td>
      <span class="text-h6 primary-text"> {{ addonConfig.name }} </span>
      <div class="text-caption">{{ addonConfig.priceDescription }}</div>
    </td>
    <td
      class="purchase-addon-description"
      v-text="addonConfig.description"
    ></td>
    <td v-if="addonConfig.hasQuantity" class="text-center">
      <quantity-input
        :value="localQuantity"
        @update="(v) => setQuantity(v)"
        :loading="service.setAddonQuantity.isLoading"
      ></quantity-input>

      <v-expand-transition>
        <div
          v-if="service.setAddonQuantity.message"
          class="text-caption text-error"
          role="alert"
        >
          {{ service.setAddonQuantity.message }}
        </div>
      </v-expand-transition>
    </td>
    <td v-else class="text-center">
      <v-btn
        v-if="serverQuantity == 0"
        color="primary"
        @click="setQuantity(1)"
        :loading="service.setAddonQuantity.isLoading"
      >
        <span class="d-none d-sm-inline"> Add to Cart </span>
        <span class="d-sm-none"> Add </span>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        variant="outlined"
        @click="setQuantity(0)"
        :loading="service.setAddonQuantity.isLoading"
      >
        Remove
      </v-btn>

      <v-expand-transition>
        <div
          v-if="service.setAddonQuantity.message"
          class="text-caption text-error"
          role="alert"
        >
          {{ service.setAddonQuantity.message }}
        </div>
      </v-expand-transition>
    </td>
    <td class="text-right">
      <v-fade-transition mode="out-in">
        <v-skeleton-loader
          key="subtotal-skele"
          v-if="service.setAddonQuantity.isLoading"
          type="text"
          class="pt-1"
        />
        <div v-else key="subtotal-value">{{ subtotal }}</div>
      </v-fade-transition>
    </td>
  </tr>
</template>

<style lang="scss"></style>

<script setup lang="ts">
import { EventAddonDto } from "@/models.g";
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { formatCurrency, setupPurchaseReloadHandling } from "@common/utils";
import { computed, ref } from "vue";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
  addonConfig: EventAddonDto;
}>();
const service = new PublicPurchaseServiceViewModel();

/** The current quantity of the item according to the last data returned from the server. */
const serverQuantity = computed(() => {
  return items.value.length;
});

/** The current quantity desired/inputted by the user. */
const localQuantity = ref(0);
function refreshLocalQuantity() {
  localQuantity.value = items.value.length;
}

const items = computed(() => {
  return props.purchase.addons!.filter(
    (t) => t.addonItemConfigurationId == props.addonConfig.configurationId,
  );
});

const subtotal = computed(() => {
  return formatCurrency(
    items.value.reduce((prev, cur) => prev + cur.price!, 0),
  );
});

async function setQuantity(input: string | number) {
  var num = parseInt(input.toString());
  localQuantity.value = num;

  try {
    await service.setAddonQuantity(
      props.purchase.publicId,
      props.addonConfig.configurationId,
      num,
    );
  } catch (err) {
    console.error(err);

    // Always refresh the local quantities if there is an error.
    refreshLocalQuantity();
  }
}

service.setAddonQuantity.setConcurrency("debounce");
setupPurchaseReloadHandling(props.purchase, service.setAddonQuantity);

refreshLocalQuantity();
</script>
