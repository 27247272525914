<template>
  <labeled-section
    title="Dietary Restrictions"
    icon="fal fa-utensils"
    v-if="filteredItems.length"
  >
    <v-card variant="outlined" class="purchase-dietary-summary">
      <v-table dense>
        <thead>
          <tr>
            <th width="140px">Restriction</th>
            <th width="140px">Guest Quantity</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="restriction in filteredItems" :key="restriction.allergen">
            <td>{{ restriction.allergen }}</td>
            <td>{{ restriction.guestQuantity }}</td>
            <td>{{ restriction.comment }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </labeled-section>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { computed } from "vue";

const props = defineProps<{ purchase: TicketPurchaseDtoViewModel }>();
const filteredItems = computed(() => {
  return (
    props.purchase.dietaryRestrictions?.filter((r) => r.guestQuantity! > 0) ??
    []
  );
});
</script>
