<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title> Account Setup</v-card-title>
          <v-card-text>
            <v-text-field
              :model-value="signInService.setupAccount.args.userName"
              label="Username"
              prepend-icon="fal fa-user"
              readonly
              :hide-details="false"
            />
            <v-text-field
              v-model="signInService.setupAccount.args.newPassword"
              label="New Password"
              prepend-icon="fal fa-lock"
              type="password"
              :hide-details="false"
            />
            <v-text-field
              v-model="signInService.setupAccount.args.confirmNewPassword"
              label="Confirm New Password"
              prepend-icon="fal fa-lock"
              type="password"
              :hide-details="false"
            />
          </v-card-text>
          <c-loader-status
            :loaders="{ 'no-progress': [signInService.setupAccount] }"
          >
            <v-alert
              type="success"
              :value="signInService.setupAccount.wasSuccessful"
            >
              Your account was successfully created. Redirecting...
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                type="submit"
                @click="signInService.setupAccount.invokeWithArgs()"
                :loading="
                  signInService.setupAccount.isLoading ||
                  signInService.setupAccount.wasSuccessful
                "
              >
                Submit
              </v-btn>
            </v-card-actions>
          </c-loader-status>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { SignInServiceViewModel } from "@/viewmodels.g";
import { useTitle } from "@common/composables/useTitle";
import { useRoute } from "vue-router";

const signInService = new SignInServiceViewModel();
useTitle("Setup Account");

const route = useRoute();
const { userName, code } = route.query;
signInService.setupAccount.args.userName = userName as string;
signInService.setupAccount.args.code = code as string;

signInService.setupAccount.onFulfilled(async () => {
  setTimeout(() => {
    window.location.href = "/sign-in";
  }, 3500);
});
</script>
