<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title> Reset Password</v-card-title>
          <v-card-text>
            <v-text-field
              :model-value="signInService.resetPassword.args.userName"
              label="Username"
              prepend-icon="fal fa-user"
              readonly
              :hide-details="false"
            />
            <v-text-field
              v-model="signInService.resetPassword.args.newPassword"
              label="New Password"
              prepend-icon="fal fa-lock"
              type="password"
              :hide-details="false"
            />
            <v-text-field
              v-model="signInService.resetPassword.args.confirmNewPassword"
              label="Confirm New Password"
              prepend-icon="fal fa-lock"
              type="password"
              :hide-details="false"
            />
          </v-card-text>
          <c-loader-status
            :loaders="{ 'no-progress': [signInService.resetPassword] }"
          >
            <v-alert
              type="success"
              :value="signInService.resetPassword.wasSuccessful"
              v-if="signInService.resetPassword.wasSuccessful"
            >
              Your password was successfully reset. Redirecting...
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                type="submit"
                @click="signInService.resetPassword.invokeWithArgs()"
                :loading="
                  signInService.resetPassword.isLoading ||
                  signInService.resetPassword.wasSuccessful
                "
              >
                Submit
              </v-btn>
            </v-card-actions>
          </c-loader-status>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { SignInServiceViewModel } from "@/viewmodels.g";
import { useRoute } from "vue-router";
import { useTitle } from "@common/composables/useTitle";

useTitle("Reset Password");
const signInService = new SignInServiceViewModel();
const router = useRoute();

onMounted(() => {
  const { userName, code } = router.query;
  signInService.resetPassword.args.userName = userName as string;
  signInService.resetPassword.args.code = code as string;

  signInService.resetPassword.onFulfilled(() => {
    // Give the user a chance to read the success message before going on...
    setTimeout(() => {
      window.location.href = "/sign-in";
    }, 3500);
  });
})


</script>
