<template>
  <div class="event-dates--root">
    <v-card class="pb-1">
      <div
        style="display: grid; grid-template-columns: 1fr auto 1fr"
        class="px-3 pb-3 pt-6 pt-md-2 align-center"
      >
        <div>
          <v-chip
            @click="parameters.filtersExpanded = !parameters.filtersExpanded"
            :color="parameters.hasFilters ? 'primary' : ''"
            variant="outlined"
            class="px-4 mr-3"
            prepend-icon="fa fa-filter"
            filter
          >
            Filters
          </v-chip>
        </div>

        <div class="view-selector">
          <v-chip
            variant="flat"
            :ripple="false"
            :color="parameters.mode == 'list' ? 'primary' : ''"
            @click="parameters.mode = 'list'"
            class="mx-1 px-md-4 mx-md-1"
            role="tab"
          >
            <v-icon size="small" class="mr-2">fa fa-list</v-icon>
            List
          </v-chip>
          <v-chip
            variant="flat"
            :ripple="false"
            :color="parameters.mode == 'calendar-month' ? 'primary' : ''"
            @click="parameters.mode = 'calendar-month'"
            class="mx-1 px-md-4 mx-md-1"
            role="tab"
          >
            <v-icon size="small" class="mr-2">fa fa-calendar-alt</v-icon>
            Month
          </v-chip>
          <v-chip
            variant="flat"
            :ripple="false"
            :color="parameters.mode == 'calendar-day' ? 'primary' : ''"
            @click="parameters.mode = 'calendar-day'"
            class="mx-1 px-md-4 mx-md-1"
            role="tab"
          >
            <v-icon size="small" class="mr-2">fa fa-calendar-day</v-icon>
            Day
          </v-chip>
        </div>

        <div style="justify-self: end">
          <slot name="header-right" :parameters="parameters" />
        </div>
      </div>

      <v-expand-transition>
        <v-row
          v-show="parameters.filtersExpanded"
          no-gutters
          class="hide-non-error-details event-dates-filters"
        >
          <c-select
            for="EventTypeDto"
            v-model="eventType"
            :params="{ fields: ['description', 'id'] }"
            label="Event Type Filter"
            clearable
            hide-details="auto"
            class="mx-3 py-2"
            style="max-width: 300px"
          />
          <c-select
            v-if="extraFilters && extraFilters.includes('eventId')"
            for="EventDto"
            v-model="event"
            label="Event Filter"
            :params="{
              filter: {
                eventTypeId: parameters.eventTypeId || undefined,
              },
              fields: ['description', 'id'],
            }"
            clearable
            hide-details="auto"
            class="mx-3 py-2"
            style="max-width: 500px"
          ></c-select>
          <v-text-field
            class="mx-3 py-2"
            style="max-width: 200px"
            type="number"
            v-model="parameters.availableSeatFilter"
            label="Tickets Available Filter"
          ></v-text-field>
          <v-switch
            v-if="
              parameters.tab == 'list' &&
              extraFilters &&
              extraFilters.includes('onlyPast')
            "
            v-model="parameters.onlyPast"
            label="Past Dates"
            class="align-self-center pt-0 mx-3"
          ></v-switch>
        </v-row>
      </v-expand-transition>
      <v-window
        :touch="{
          left: () => swipe('left'),
          right: () => swipe('right'),
        }"
        v-model="parameters.tab"
      >
        <v-window-item key="list" value="list">
          <event-dates-list
            :viewModel="listViewModel"
            :hasDescriptors="!!$slots['list-item-descriptors']"
          >
            <template v-slot:list-item-descriptors="scope">
              <slot name="list-item-descriptors" v-bind="scope" />
            </template>
            <template v-slot:list-item-actions="scope">
              <slot slot name="list-item-actions" v-bind="scope">
                <slot name="actions" v-bind="scope" />
              </slot>
            </template>
          </event-dates-list>
          <c-list-pagination
            :list="listViewModel"
            :page-sizes="[10, 25, 50, 100]"
            class="mt-6 mb-10 mx-3"
          ></c-list-pagination>
        </v-window-item>
        <v-window-item key="calendar" value="calendar">
          <v-container fluid class="pt-0 pb-4">
            <event-dates-calendar
              ref="calendar"
              :parameters="parameters"
              :viewModel="calendarViewModel"
              :hasDescriptors="!!$slots['list-item-descriptors']"
            >
              <template v-slot:list-item-descriptors="scope">
                <slot name="list-item-descriptors" v-bind="scope" />
              </template>
              <template v-slot:actions="scope">
                <slot name="actions" v-bind="scope" />
              </template>
            </event-dates-calendar>
          </v-container>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<style lang="scss">
.event-dates--root {
  margin-top: -12px;
  margin-bottom: -12px;

  .theme--light.v-window {
    background-color: transparent;
  }

  .v-chip__content .fa-filter {
    font-size: 1.2em;
  }

  .event-dates-filters {
    .v-select__selections {
      display: flex;
      flex-wrap: nowrap;

      * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input {
        min-width: 30px;
      }
    }
  }
}

.view-selector {
  .v-chip {
    background: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>

<script setup lang="ts">
import { watch, reactive } from "vue";
import { ListParameters, useBindToQueryString } from "coalesce-vue";
import { EventDateDto, EventDto, EventTypeDto } from "@/models.g";
import { EventDatesParameters, scrollToTop } from "@common/utils";
import { EventDateDtoListViewModel } from "@/viewmodels.g";
import EventDatesCalendar from "@common/components/event-dates-calendar.vue";
import EventDatesList from "@common/components/event-dates-list.vue";

const props = defineProps<{
  dataSourceParams?: Partial<EventDateDto.DataSources.DefaultSource>;
  extraFilters?: string[];
}>();
const parameters = reactive(new EventDatesParameters());

const listViewModel = new EventDateDtoListViewModel();
const calendarViewModel = new EventDateDtoListViewModel();

const eventType = ref<EventTypeDto | undefined>();
watch(eventType, () => {
  parameters.eventTypeId = eventType.value?.id ?? null;
  parameters.eventId = null;
  loadFreshData();
});

const event = ref<EventDto | undefined>();
watch(event, () => {
  parameters.eventId = event.value?.id ?? null;
  loadFreshData();
});

function loadFreshData() {
  if (parameters.tab === "list") {
    listViewModel.$load();
  } else if (parameters.tab == "calendar") {
    calendarViewModel.$load();
  }
}

const calendar = ref<EventDatesCalendar>();

function swipe(dir: "left" | "right") {
  if (dir === "left" && parameters.tab === "list") {
    parameters.tab = "calendar";
  }
  calendar.value?.swipe(dir);
}

useBindToQueryString(parameters, "tab");
useBindToQueryString(parameters, "onlyPast", "onlyPast", (v) => v == "true");
useBindToQueryString(parameters, "calendarType", "calView");
useBindToQueryString(parameters, "calendarDateString", "date");
useBindToQueryString(parameters, "eventTypeId", "type", (v) => +v);
useBindToQueryString(parameters, "eventId", "eventId", (v) => +v);
useBindToQueryString(
  parameters,
  "availableSeatFilter",
  "filter.availableSeatFilter",
  (v) => +v,
);

const listDataSource = new EventDateDto.DataSources.DefaultSource();
if (props.dataSourceParams) {
  Object.assign(listDataSource, props.dataSourceParams);
}

listViewModel.$params.dataSource = listDataSource;
listViewModel.$params.orderBy = "eventStartDateTime";
listViewModel.$params.includes = "none";
listViewModel.$pageSize = 50;
useBindToQueryString(listViewModel.$params, "page", "listPage", (v) => +v);
useBindToQueryString(listViewModel.$params, "pageSize", "listSize", (v) => +v);

watch(
  () => parameters.onlyPast,
  (v) => (listDataSource.onlyPast = v),
  { immediate: true },
);

const calendarDataSource = new EventDateDto.DataSources.CalendarSource();
if (props.dataSourceParams) {
  Object.assign(calendarDataSource, props.dataSourceParams);
  if (calendarDataSource.minDate == null) {
    calendarDataSource.minDate = new Date(0);
  }
}

const now = new Date();
calendarDataSource.month = now.getMonth() + 1;
calendarDataSource.year = now.getFullYear();
calendarViewModel.$params.dataSource = calendarDataSource;
calendarViewModel.$params.orderBy = "eventStartDateTime";
calendarViewModel.$params.includes = "none";

const bindCommon = (params: ListParameters) => {
  watch(
    () => parameters.eventTypeId,
    (v) => ((params.dataSource as any).eventTypeId = v || undefined),
    { immediate: true },
  );

  params.filter ??= {
    eventId: "",
    availableSeatFilter: null,
  };
  watch(
    () => parameters.eventId,
    (v) => (params.filter!.eventId = v || undefined),
    { immediate: true },
  );

  watch(
    () => parameters.availableSeatFilter,
    (v) => (params.filter!.availableSeatFilter = v || undefined),
    { immediate: true },
  );
};

bindCommon(listViewModel.$params);
bindCommon(calendarViewModel.$params);

// Most users will probably prefer one tab over the other. Load that tab
// by default to reduce the number of clicks.
if (localStorage.dateViewPreference) {
  parameters.tab = localStorage.dateViewPreference;
}

listViewModel.$useAutoLoad({ wait: 100 });

// Scroll to the top when the user changes the page.
watch(
  () => listViewModel.$params.page,
  () => scrollToTop(10),
);

watch(
  () => parameters.tab,
  (val) => (localStorage.dateViewPreference = val),
);

parameters.filtersExpanded = parameters.hasFilters;
</script>
