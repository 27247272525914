<template>
  <v-container class="page-container">
    <c-loader-status
      :loaders="{ 'no-initial-content no-error-content': [eventDate.$load] }"
    >
      <event-date-summary-section :eventDate="eventDate">
      </event-date-summary-section>
      <v-row class="justify-space-around mt-10 mb-2">
        <v-alert
          v-if="eventDate.unpurchasableReason"
          type="info"
          border="start"
        >
          <strong>Unable to start a purchase for this event.</strong>
          <div>Event {{ eventDate.unpurchasableReason }}.</div>
        </v-alert>
        <event-date-start-purchase-btn
          v-else
          :eventDate="eventDate"
          color="secondary"
          x-large
          class="mx-auto"
        >
        </event-date-start-purchase-btn>
      </v-row>
    </c-loader-status>
  </v-container>
</template>

<script setup lang="ts">
import { EventDateDtoViewModel } from "@/viewmodels.g";
import { EventDateDto } from "@/models.g";
import EventDateSummarySection from "@common/event/event-summary-section.vue";
import EventDateStartPurchaseBtn from "@common/event/event-date-start-purchase-btn.vue";
import { format } from "date-fns";
import { computed } from "vue";
import { useTitle } from "@common/composables/useTitle";

const props = defineProps<{ id: number | string }>();
const propId =
  typeof props.id === "string"
    ? parseInt(props.id as string)
    : (props.id as number | undefined);

const eventDate = new EventDateDtoViewModel();

const pageTitle = computed(() => {
  let ret = eventDate.eventName || "Loading";

  if (eventDate.eventStartDateTimeLocal) {
    ret =
      ret +
      " - " +
      format(eventDate.eventStartDateTimeLocal, "MMMM dd, yyyy 'at' h:mm a");
  }

  return ret;
});

useTitle(pageTitle.value);

const ds = (eventDate.$dataSource =
  new EventDateDto.DataSources.DefaultSource());

// Some of these will be overriden on the server for public users,
// but essentially, we don't want to make events fail to
// load when looking at the page for that specific date
// just because the event is old, or cancelled,
// because if the event is filtered out then we can't explain
// to the user why they can't purchase tickets for it.
ds.includeCancelled = true;
ds.includeInactive = true;
ds.minDate = new Date(0);

eventDate.$load(propId);
</script>
