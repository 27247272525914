<template>
  <labeled-section class="ticket-purchase-details-page">
    <template #title>
      <c-display :model="purchase" for="type" />
      <!-- Explicit whitespace character -->
      <span>&nbsp;</span>
      <c-display :model="purchase" for="status" v-if="!isCompleted"></c-display>
      <span v-else>Confirmation</span>
    </template>

    <v-card-text>
      <!-- Display success if the purchase just finished. -->
      <v-alert
        density="compact"
        v-if="service.completePublicCardPayment.wasSuccessful === true"
        class="no-print mb-4"
        type="success"
        closable
        icon="fal fa-check"
      >
        <c-display :model="purchase" for="type" />
        Complete!
      </v-alert>

      <template v-if="isCompleted">
        <purchase-confirmation-section :purchase="purchase">
          <template #append>
            <v-row class="cards-row no-print">
              <v-col>
                <v-card variant="outlined">
                  <v-card-text>
                    <labeled-section title="Print Tickets" icon="fal fa-print">
                      <p class="mb-0">
                        Please print your tickets and bring them with you to the
                        event entrance to speed up the entry process.
                      </p>
                      <purchase-print-buttons
                        :purchase="purchase"
                      ></purchase-print-buttons>
                      <p>
                        If you're unable to print your tickets, bring your photo
                        ID and your confirmation number
                        <strong> {{ purchase.confirmationCode }}</strong> to the
                        event entrance for assistance.
                      </p>
                    </labeled-section>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <contact-us-card
                  :subject="
                    'Ticket Purchase Inquiry - Confirmation #' +
                    purchase.confirmationCode
                  "
                >
                  <template #suggested-questions>
                    Need a refund, exchange, or hold of your tickets?
                  </template>
                </contact-us-card>
              </v-col>
            </v-row>
          </template>
        </purchase-confirmation-section>
        <suggested-event-carousel :purchase="purchase" />
        <labeled-section title="Receipt" icon="fal fa-receipt">
          <template #subtitle>
            <c-display :model="purchase" for="creationDate" element="small" />
          </template>
          <purchase-price-summary :purchase="purchase" />
        </labeled-section>

        <event-summary-section
          v-if="purchase.type != PurchaseType.Refund"
          :event-date="purchase.eventDate"
        />

        <labeled-section
          title="Contact Information"
          icon="fal fa-id-card"
          class="no-print"
        >
          <purchase-contact-input :purchase="purchase" readonly />
        </labeled-section>
      </template>

      <template v-else-if="!isCompleted">
        <purchase-status-alert :purchase="purchase">
          Visit our
          <router-link :to="{ name: 'home' }"> home page</router-link>
          to view all available events.
        </purchase-status-alert>

        <event-summary-section
          v-if="purchase.type != PurchaseType.Refund"
          :event-date="purchase.eventDate"
        />

        <purchase-dietary-summary-section :purchase="purchase" />

        <v-row class="cards-row">
          <v-col cols="12" md="6">
            <contact-us-card></contact-us-card>
          </v-col>
          <v-col cols="12" md="6"></v-col>
        </v-row>
      </template>
    </v-card-text>
  </labeled-section>
</template>

<script setup lang="ts">
import EventSummarySection from "@common/event/event-summary-section.vue";
import PurchasePriceSummary from "@common/purchase/purchase-price-summary.vue";
import PurchaseDietarySummarySection from "@common/purchase/purchase-dietary-summary-section.vue";
import PurchaseContactInput from "@common/purchase/purchase-contact-input.vue";
import PurchaseConfirmationSection from "@common/purchase/purchase-confirmation-section.vue";
import PurchasePrintButtons from "@common/purchase/purchase-print-buttons.vue";
import PurchaseStatusAlert from "@common/purchase/purchase-status-alert.vue";

import ContactUsCard from "@common/components/contact-us-card.vue";

import {
  TicketPurchaseDtoViewModel,
  PublicPurchaseServiceViewModel,
} from "@public/viewmodels.g";
import { PurchaseStatus, PurchaseType } from "@public/models.g";
import SuggestedEventCarousel from "@common/purchase/suggested-event-carousel.vue";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
  // Service is provided by prop so that we can read success/error states
  // off of it once purchase-create is unloaded and we're displaying purchase-details.
  service: PublicPurchaseServiceViewModel;
}>();

const isCompleted = computed(() => {
  return props.purchase.status === PurchaseStatus.Completed;
});
</script>

<style lang="scss">
.ticket-purchase-details-page {
  a.contact-link {
    text-decoration: none;
    font-size: 16px;
  }
}
</style>
