<template>
  <div v-if="purchase.eventDate && purchase.eventDate.isEventActive">
    <v-snackbar
      color="error"
      v-model="snackbar"
      :timeout="10000"
      location="top"
    >
      {{ snackbarMsg }}
    </v-snackbar>
    <labeled-section title="Purchase Tickets" class="purchase-create">
      <!--    <v-alert v-if="!recaptchaLoaded" type="error">-->
      <!--      This site requires use of Google's reCAPTCHA service, but it was unable to-->
      <!--      load. Please check your browser settings and disable any ad blockers.-->
      <!--    </v-alert>-->
      <v-card-text class="px-0 pt-0 px-md-3">
        <v-stepper v-model="step">
          <v-stepper-window v-if="step === 1">
            <v-form ref="form1" v-model="isValidStep1" lazy-validation>
              <v-img :src="currentEventDateDto.eventBannerImage" />
              <br />

              <v-text-field
                name="fooName"
                style="display: none"
                v-model="doNotFillSpot"
              />
              <event-summary-section :event-date="purchase.eventDate" />

              <labeled-section title="Tickets" icon="fal fa-ticket">
                <purchase-quantity-selector :purchase="purchase" />
                <purchase-expiration-notice :purchase="purchase" class="mt-3" />
                <v-input
                  class="ticket-selection-error pt-2"
                  :rules="
                    !purchase.tickets.length
                      ? [() => 'Please select at least one ticket.']
                      : []
                  "
                ></v-input>
              </labeled-section>

              <labeled-section
                v-if="
                  currentEventDateDto &&
                  currentEventDateDto.eventImages &&
                  currentEventDateDto.eventImages.length > 0
                "
                title="Event Images"
                icon="fal fa-image"
              >
                <v-carousel height="800" :show-arrows="currentEventDateDto.eventImages.length > 1">
                  <v-carousel-item
                    v-for="eventImage in currentEventDateDto.eventImages"
                    :key="eventImage.imageSrc"
                    :src="eventImage.imageSrc"
                    contain
                  />
                </v-carousel>
              </labeled-section>

              <labeled-section
                title="Contact Information"
                icon="fal fa-id-card"
              >
                <c-loader-status :loaders="{ 'no-progress': [purchase.$save] }">
                  <purchase-contact-input
                    ref="contactInfo"
                    :purchase="purchase"
                    v-model="validContactInfo"
                  />
                </c-loader-status>
              </labeled-section>
              <v-checkbox
                name="term"
                style="display: none"
                v-model="fakeCheckbox"
              />
            </v-form>
            <div
              :class="{ hidden: isValidStep1 != false }"
              class="text-error font-weight-bold text-right"
            >
              Please resolve the errors above before continuing.
            </div>
            <v-row no-gutters class="flex-nowrap">
              <v-spacer></v-spacer>
              <v-btn color="primary" size="large" @click="nextStep()">
                Continue
                <v-icon end>fal fa-arrow-right</v-icon>
              </v-btn>
            </v-row>
          </v-stepper-window>

          <v-stepper-window v-if="step === 2">
            <v-form ref="form2" v-model="isValidStep2" lazy-validation>
              <purchase-expiration-notice :purchase="purchase" class="mb-8" />
              <labeled-section
                v-if="purchase.dietaryRestrictions.length"
                title="Dietary Restrictions"
                icon="fal fa-utensils"
              >
                <purchase-dietary-input
                  :purchase="purchase"
                ></purchase-dietary-input>
              </labeled-section>

              <labeled-section
                v-if="!purchase.eventDate.isFreeEvent"
                title="Payment"
                icon="fal fa-credit-card"
              >
                <raw-credit-card-input
                  @setCCDetails="setCCDetails"
                  :purchase="purchase"
                  :paymentId="-1"
                  require-full-details
                >
                </raw-credit-card-input>
              </labeled-section>

              <labeled-section title="Promo Code" icon="fal fa-badge-dollar">
                <purchase-discount-code-input
                  :purchase="purchase"
                  class="mt-n3 mb-0"
                />

                <purchase-discount-summary :purchase="purchase" />
              </labeled-section>

              <purchase-protection-selector :purchase="purchase" />
              <purchase-addon-selector :purchase="purchase" />

              <labeled-section title="Summary" icon="fal fa-receipt">
                <div
                  class="text-h6 font-weight-light mb-3"
                  style="line-height: 1.1"
                >
                  {{ purchase.eventDate.eventName }}
                  <div class="d-sm-none"></div>
                  <span class="d-none d-sm-inline">&mdash;</span>

                  <!-- • -->
                  <c-display
                    :model="purchase.eventDate"
                    for="eventStartDateTimeLocal"
                    format="MMMM do, yyyy @ h:mm a"
                  />
                  (<c-display
                  :model="purchase.eventDate"
                  for="venueTimezone"
                />).
                </div>

                <purchase-price-summary
                  :purchase="purchase"
                  hideEmptyPaymentsSection
                />
              </labeled-section>
              <labeled-section>
                <p>
                  Please note: Charges will display on your bank statement as
                  Ticket Goat.
                </p>
              </labeled-section>
              <hr/>
              <br>
              <v-dialog
                v-model="showTGTermDialog"
                max-width="640"
                v-if="staticData.BANK_STATEMENT_NOTIFICATION_ENABLED"
              >
                <template v-slot:activator="{ props }">
                  <div class="d-flex flex-column">
                    <ul style="padding-left: 20px; list-style-type: disc;">
                      <li>Ticket Goat's <span
                        @click.stop="() => {showTGTermDialog = true}"
                        class="text-blue cursor-pointer">terms and conditions</span>.</li>
                      <li v-if="purchase.venueDisclaimer">Venue's <span
                        @click.stop="() => {showVenueTermDialog = true}"
                        class="text-blue cursor-pointer">terms and conditions</span>.</li>
                    </ul >

                    <v-checkbox
                      :rules="[ acceptedAgreementPurchase || 'Please read and check the agreement box before completing purchase.']"
                      v-model="acceptedAgreementPurchase"
                      class="purchase-agreement"
                      v-if="staticData.BANK_STATEMENT_NOTIFICATION_ENABLED || purchase.venueDisclaimer"
                    >
                      <template v-slot:label>
                        <p style="padding: 0 !important;">
                          By selecting the checkbox, you have read and accepted the above terms and conditions.
                        </p>
                      </template>
                    </v-checkbox>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    Ticket Goat's Terms and Conditions
                  </v-card-title>
                  <v-card-text>
                    Purchaser agrees that the agreement is between the Event Organizer and the purchaser.
                    All disputes, claims, and refunds are the sole responsibility of the Event Organizer and must be addressed
                    with them directly. By agreeing, I acknowledge that Ticket Goat cannot be held legally liable.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      variant="text"
                      @click="() => {showTGTermDialog = false}"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="showVenueTermDialog"
                v-if="purchase.venueDisclaimer"
                max-width="640"
              >
                <v-card>
                  <v-card-title>
                    Venue's Terms and Conditions
                  </v-card-title>
                  <v-card-text>
                    {{purchase.venueDisclaimer}}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      variant="text"
                      @click="() => {showVenueTermDialog = false}"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>

            <c-loader-status
              :loaders="{ '': [service.completePublicCardPayment] }"
              class="mb-10"
            >
              <div class="text-right">
                <div
                  :class="{ hidden: isValidStep2 != false }"
                  class="text-error font-weight-bold"
                >
                  Please resolve the errors above before continuing.
                </div>

                <v-row no-gutters class="flex-nowrap">
                  <v-btn
                    color="primary"
                    variant="outlined"
                    size="large"
                    @click="step = 1"
                  >
                    <v-icon start>fal fa-arrow-left</v-icon>
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    size="large"
                    @click="continueToPayment()"
                    :loading="service.completePublicCardPayment.isLoading"
                  >
                    <v-icon start>fal fa-check</v-icon>
                    Complete Purchase
                  </v-btn>
                </v-row>
              </div>
            </c-loader-status>
          </v-stepper-window>
        </v-stepper>
      </v-card-text>
    </labeled-section>
  </div>
  <div v-else>
    <v-alert type="error" variant="outlined">
      An error has occurred, please contact the Venue directly.
    </v-alert>
  </div>
</template>

<style lang="scss">
.purchase-create {
  .v-stepper {
    box-shadow: none !important;
  }

  .v-stepper__content {
    padding: 0 !important;
  }

  .ticket-selection-error {
    font-weight: bold;

    .v-messages {
      font-size: 14px;
      margin: auto;
    }
  }

  .purchase-agreement {
    .v-input__slot {
      align-items: flex-start;
    }
  }
}
</style>

<script setup lang="ts">
import EventSummarySection from "@common/event/event-summary-section.vue";
import PurchaseQuantitySelector from "@common/purchase/purchase-quantity-selector.vue";
import PurchaseAddonSelector from "@common/purchase/purchase-addon-selector.vue";
import PurchaseProtectionSelector from "@common/purchase/purchase-protection-selector.vue";
import PurchasePriceSummary from "@common/purchase/purchase-price-summary.vue";
import PurchaseContactInput from "@common/purchase/purchase-contact-input.vue";
import PurchaseDiscountSummary from "@common/purchase/purchase-discount-summary.vue";
import PurchaseExpirationNotice from "@common/purchase/purchase-expiration-notice.vue";
import PurchaseDiscountCodeInput from "@common/purchase/purchase-discount-code-input.vue";
import RawCreditCardInput from "@common/purchase/raw-credit-card-input.vue";
import PurchaseDietaryInput from "@common/purchase/purchase-dietary-input.vue";

import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
  EventDateDtoViewModel,
} from "@public/viewmodels.g";
import { VForm } from "vuetify/components";
import { reloadIfNoRequestsPending, scrollToTop } from "@common/utils";
import { beginCheckout, purchaseComplete } from "@common/analytics";
import router from "@public/router";
import { CreditCardDetails } from "@public/models.g";
import useStaticData from "@common/composables/useStaticData";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
  service: PublicPurchaseServiceViewModel;
}>();

const form1 = ref<VForm>();
const form2 = ref<VForm>();
const step = ref(1);
const hasVisitedStep2 = ref(false);
const acceptedAgreementPurchase = ref(false);
const doNotFillSpot = ref("");
const fakeCheckbox = ref(false);
const snackbarMsg = ref("");
const snackbar = ref(false);
const showTGTermDialog = ref(false);
const showVenueTermDialog = ref(false);

const isValidStep1 = ref<boolean | null>(null);
const validContactInfo = ref<boolean | null>(null);
const isValidStep2 = ref<boolean | null>(null);

const contactInfo = ref<PurchaseContactInput>();

async function validateForm1() {
  isValidStep1.value = (await form1.value?.validate())?.valid ?? false;
  validContactInfo.value =
    (await contactInfo.value?.validate())?.valid ?? false;

  return isValidStep1.value && validContactInfo.value;
}

async function validateForm2() {
  isValidStep2.value = (await form2.value?.validate())?.valid ?? false;
  return isValidStep2.value;
}

const staticData = useStaticData();

// This is not ideal, but already spent too much time to get the backend grab the right data for this
// and still cannot figure out how to get the EventImage via the ticket purchase instance
// that has EventDate included as a query param. So this is just another API call...
// TODO: will need to implement the way to get nested relational data without making this explicit call
const currentEventDateDto = new EventDateDtoViewModel();
currentEventDateDto.$load(props.purchase.eventDate?.id || undefined);

props.purchase.$saveMode = "whole";
props.purchase.$save.setConcurrency("debounce");
props.purchase.$useAutoSave({ wait: 500 });

props.purchase.$save.onRejected(() => {
  reloadIfNoRequestsPending(props.purchase, props.purchase.$save);
});

// Manually reload if continue to payment fails.
props.service.completePublicCardPayment.onRejected(() => {
  props.purchase.$load();
});

let ccDetails: CreditCardDetails[] = [];

function setCCDetails(details: CreditCardDetails[]) {
  ccDetails = details;
}

async function nextStep() {
  if (!(await passEventPriceMinimum())) return;

  if (!(await validateForm1())) {
    return;
  }

  if (!hasVisitedStep2.value) {
    hasVisitedStep2.value = true;
    beginCheckout(props.purchase);
  }

  if (doNotFillSpot.value.length > 0 || fakeCheckbox.value) {
    await props.service.markTicketPurchaseHoneyPotSpam(props.purchase.publicId);
    await props.purchase.$load();
  }

  step.value++;
  scrollToTop(0, "auto");
}

async function passEventPriceMinimum(): Promise<boolean> {
  let result = true;
  props.purchase.eventDate?.eventPrices?.map((ep) => {
    // Check if minimum is configured
    // The last addition is needed because right after user increase the quantity the availableSeat get updated immediately
    if (
      ep.minimumQuantity != null &&
      ep.minimumQuantity > 0 &&
      props.purchase.tickets &&
      ep.minimumQuantity! <=
      props.purchase.eventDate?.availableSeats! +
      props.purchase.tickets.length
    ) {
      // Check if the amount of ticket reached the minimum requirement
      let countMin = ep.minimumQuantity;
      props.purchase.tickets?.map((ticket) => {
        if (ticket.eventPriceId == ep.id) {
          countMin--;
        }
      });
      // The second argument will make sure it will skip if buyer not buying any tickets from the particular ticket type
      if (countMin > 0 && countMin < ep.minimumQuantity) {
        snackbar.value = true;
        snackbarMsg.value = `Ticket ${ep.displayName} requiring ${ep.minimumQuantity} as the minimum purchase. You only have ${ep.minimumQuantity - countMin}.`;
        result = false;
      }
    }
  });
  return result;
}

async function continueToPayment() {
  if (!(await validateForm2()) || !acceptedAgreementPurchase.value) {
    return;
  }
  const complete = props.service.completePublicCardPayment;

  await complete(props.purchase.publicId, ccDetails[0]);
  const redirectUrl = complete.result!;

  if (redirectUrl === null) {
    // Server will send back a redirect of null
    // to indicate that the purchase details should be shown.
    // Since we're already at the purchase details,
    // just reload the purchase.
    await props.purchase.$load();
    purchaseComplete(props.purchase);
    nextTick(() => scrollToTop(0, "auto"));
    return;
  }

  router.push({
    name: "ticket-purchase-redirecting",
    params: {
      publicId: props.purchase.publicId!,
    },
    query: {
      redirectUrl: redirectUrl,
    },
  });

  window.location.href = redirectUrl;
}
</script>
