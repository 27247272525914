<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title> Email Confirmation</v-card-title>
          <c-loader-status :loaders="{ '': [signInService.confirmEmail] }">
            <v-alert
              type="success"
              :value="signInService.confirmEmail.wasSuccessful"
            >
              Your email address was confirmed! Redirecting...
            </v-alert>
          </c-loader-status>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { SignInServiceViewModel } from "@/viewmodels.g";
import { useTitle } from "@common/composables/useTitle";
import { useRoute } from "vue-router";

useTitle("Email Confirmation");
const signInService = ref(new SignInServiceViewModel());
const route = useRoute();

onMounted(() => {
  const { userId, code, newEmail } = route.query;
  signInService.value.confirmEmail.args.userId = userId as string;
  signInService.value.confirmEmail.args.code = code as string;
  // newEmail is only used for email change requests
  signInService.value.confirmEmail.args.newEmail = newEmail as string;

  signInService.value.confirmEmail.onFulfilled(async () => {
    setTimeout(() => {
      window.location.href = "/sign-in";
    }, 3000);
  });

  signInService.value.confirmEmail.invokeWithArgs();
});
</script>
