<template>
  <v-bottom-sheet
    v-model="bottomSheetOpen"
    inset
    class="justify-content-center"
  >
    <v-row no-gutters justify="center">
      <!-- Min height keeps this from being too small and insignificant on a tall screen when there are very few ticket types -->
      <v-card v-if="modelValue" min-height="35vh" min-width="500">
        <v-card-title class="px-4 justify-space-between">
          <v-row no-gutters>
            <v-col cols="6">
              <div class="seat-dialog-attribute">
                <div class="text-overline">Section</div>
                <strong> {{ section?.name }} </strong>
              </div>
            </v-col>
            <v-col>
              <div class="seat-dialog-attribute">
                <div class="text-overline">
                  {{
                    section.type === SectionTypes.GeneralAdmission
                      ? "General Admission"
                      : "Seat"
                  }}
                </div>
                <strong> {{ seat?.seatNumber }} </strong>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                @click="modelValue = null"
                title="Close"
                icon="fa fa-times"
                variant="flat"
                size="small"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <slot name="before-list" />

        <v-list class="pt-0">
          <v-list-item
            v-for="eventPrice in section?.prices"
            :key="eventPrice.id"
          >
            <v-row no-gutters class="py-2">
              <v-col>
                <v-list-item-title>
                  <span class="text-h6"> {{ eventPrice.description }} </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ formatCurrency(eventPrice.price) }} +
                  <span class="text-no-wrap">
                    {{ formatCurrency(eventPrice.serviceFee) }} service fee
                  </span>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="auto">
                <v-list-item-action>
                  <slot
                    v-if="section.type === SectionTypes.IndividualSeat"
                    name="price-action"
                    :eventPrice="eventPrice"
                  />
                  <quantity-input
                    v-else-if="section.type === SectionTypes.GeneralAdmission"
                    :value="getCartItemQuantity(eventPrice)"
                    @update="(v) => updateCartQuantity(eventPrice, v)"
                  ></quantity-input>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>`
        <v-row>
          <v-col class="d-flex justify-center">
            <slot
              v-if="section.type === SectionTypes.GeneralAdmission"
              name="price-action-ga"
              :generalAdmissionToBeAddedList="generalAdmissionToBeAddedList"
              :generalAdmissionToBeRemovedList="generalAdmissionToBeRemovedList"
              :totalCombined="totalCombined"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-bottom-sheet>
</template>

<style lang="scss">
.seat-dialog-attribute {
  &,
  > * {
    line-height: 1.1 !important;
  }
}

// Remove overlay shadow on v-bottom-sheet
.v-bottom-sheet > .v-bottom-sheet__content.v-overlay__content {
  -webkit-box-shadow:
    0 0 0 0 var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0)),
    0 0 0 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0)),
    0 0 0 0 var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0));
  box-shadow:
    0 0 0 0 var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0)),
    0 0 0 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0)),
    0 0 0 0 var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0));
}
</style>

<script setup lang="ts">
import { formatCurrency } from "@common/utils";
import {
  EventPriceDto,
  SeatMapSeatDto,
  SeatMapSectionDto,
  SectionTypes,
} from "@/models.g";
import { computed, ref } from "vue";
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";

const modelValue = defineModel<{
  seat: SeatMapSeatDto;
  section: SeatMapSectionDto;
} | null>({ default: null });

const props = defineProps<{
  purchase?: TicketPurchaseDtoViewModel;
}>();

const generalAdmissionCart = ref<
  { eventPrice: EventPriceDto; quantity: number }[]
>([]);
const generalAdmissionToBeRemovedList = ref<
  { eventPrice: EventPriceDto; quantity: number }[]
>([]);
const generalAdmissionToBeAddedList = ref<
  { eventPrice: EventPriceDto; quantity: number }[]
>([]);

const originalQuantities = ref(new Map<string, number>());

const seat = computed(() => {
  return modelValue.value?.seat;
});

const bottomSheetOpen = computed({
  get: () => !!modelValue.value,
  set: (val: any) => (modelValue.value = !val ? modelValue.value : null),
});

const section = computed(() => {
  return modelValue.value?.section;
});

const totalCombined = computed(() => {
  const totalAdded = generalAdmissionToBeAddedList.value.reduce(
    (sum, item) => sum + Math.max(item.quantity, 0),
    0
  );
  const totalRemoved = generalAdmissionToBeRemovedList.value.reduce(
    (sum, item) => sum + Math.max(item.quantity, 0),
    0
  );
  return totalAdded - totalRemoved;
})
watch(
  modelValue,
  (newValue, oldValue) => {
    if (section.value) {
      section.value?.prices?.map((eventPrice) => {
        // Go through each price and get info update from the purchase.tickets
        const foundTicket = props.purchase?.tickets?.filter(
          (ticket) =>
            ticket.eventPriceId == eventPrice.id &&
            ticket.seatNumber == seat.value?.seatNumber,
        );
        const quantity = foundTicket ? foundTicket.length : 0;
        if (foundTicket && foundTicket.length >= 0) {
          generalAdmissionCart.value.push({
            eventPrice: eventPrice,
            quantity: quantity,
          });
        } else {
          generalAdmissionCart.value.push({
            eventPrice: eventPrice,
            quantity: quantity,
          });
        }
        // Store the original quantity for comparison in a map
        originalQuantities.value.set(eventPrice.id, quantity);
      });
    }
    if (!newValue && oldValue) {
      generalAdmissionCart.value = [];
      generalAdmissionToBeAddedList.value = [];
      generalAdmissionToBeRemovedList.value = [];
      originalQuantities.value.clear();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

// Retrieve the quantity for the given eventPrice
function getCartItemQuantity(eventPrice: EventPriceDto): number {
  const cartItem = generalAdmissionCart.value.find(
    (item) => item.eventPrice.id === eventPrice.id,
  );
  return cartItem ? cartItem.quantity : 0;
}

// Update the quantity for the given eventPrice
function updateCartQuantity(eventPrice: EventPriceDto, newQuantity: number) {
  const cartItem = generalAdmissionCart.value.find(
    (item) => item.eventPrice.id === eventPrice.id,
  );
  if (cartItem) {
    const originalQuantity = originalQuantities.value.get(eventPrice.id) || 0;

    // Update the cart item quantity
    cartItem.quantity = newQuantity;

    // Determine which list to add or remove the cart item from
    if (newQuantity > originalQuantity) {
      // Adding items
      updateList(
        generalAdmissionToBeAddedList.value,
        eventPrice,
        newQuantity - originalQuantity,
      );
      removeFromList(generalAdmissionToBeRemovedList.value, eventPrice);
    } else if (newQuantity < originalQuantity) {
      // Removing items
      updateList(
        generalAdmissionToBeRemovedList.value,
        eventPrice,
        originalQuantity - newQuantity,
      );
      removeFromList(generalAdmissionToBeAddedList.value, eventPrice);
    }

    // Clear from lists if quantity matches original
    if (newQuantity === originalQuantity) {
      removeFromList(generalAdmissionToBeAddedList.value, eventPrice);
      removeFromList(generalAdmissionToBeRemovedList.value, eventPrice);
    }
  }

  // Helper to update a list with the correct quantity
  function updateList(
    list: { eventPrice: EventPriceDto; quantity: number }[],
    eventPrice: EventPriceDto,
    quantity: number,
  ) {
    const existingItem = list.find(
      (item) => item.eventPrice.id === eventPrice.id,
    );
    if (quantity > 0) {
      if (existingItem) {
        existingItem.quantity = quantity;
      } else {
        list.push({ eventPrice, quantity });
      }
    }
  }

  // Helper to remove an item from a list
  function removeFromList(
    list: { eventPrice: EventPriceDto; quantity: number }[],
    eventPrice: EventPriceDto,
  ) {
    const index = list.findIndex(
      (item) => item.eventPrice.id === eventPrice.id,
    );
    if (index !== -1) {
      list.splice(index, 1);
    }
  }
}
</script>
