<template>
  <tr class="purchase-quantity-selector-row">
    <td class="pt-4 pb-3" style="vertical-align: top">
      <span class="text-h6"> {{ eventPrice.description }} </span>
      <div class="text-caption pt-1">
        {{ formatCurrency(eventPrice.price) }} +
        <span class="text-no-wrap">
          {{ formatCurrency(eventPrice.serviceFee) }} service fee
        </span>
      </div>
    </td>
    <td class="text-center py-4">
      <div class="mx-auto" style="max-width: 300px">
        <quantity-input
          :value="localQuantity"
          @update="(v) => setQuantity(v)"
          :loading="service.setTicketQuantity.isLoading"
        ></quantity-input>
        <v-expand-transition>
          <div
            v-if="service.setTicketQuantity.message"
            class="text-caption text-error"
            role="alert"
          >
            {{ service.setTicketQuantity.message }}
          </div>
        </v-expand-transition>
      </div>
    </td>
    <td class="ticket-price-total-cell hidden-sm-and-down">
      <v-fade-transition mode="out-in">
        <v-skeleton-loader
          key="s-skele"
          v-if="service.setTicketQuantity.isLoading"
          type="text"
        />
        <div v-else key="s-value">{{ subtotal }}</div>
      </v-fade-transition>
    </td>
    <td class="ticket-price-total-cell">
      <v-fade-transition mode="out-in">
        <v-skeleton-loader
          key="swf-skele"
          v-if="service.setTicketQuantity.isLoading"
          type="text"
        />
        <div v-else key="swf-value">{{ subtotalWithFees }}</div>
      </v-fade-transition>
    </td>
  </tr>
</template>

<style lang="scss">
.purchase-quantity-selector-row {
  .title {
    line-height: 1.1 !important;
  }

  .ticket-price-total-cell {
    text-align: right;

    .v-skeleton-loader__text {
      margin-bottom: 0;
    }

    * {
      transition-duration: 0.1s !important;
    }
  }
}
</style>

<script setup lang="ts">
import { EventPriceDto } from "@/models.g";
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { cartQuantityChanged } from "@common/analytics";
import { formatCurrency, setupPurchaseReloadHandling } from "@common/utils";
import { computed, ref } from "vue";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
  eventPrice: EventPriceDto;
}>();
const service = new PublicPurchaseServiceViewModel();

const localQuantity = ref(0);

function refreshLocalQuantity() {
  localQuantity.value = tickets.value.length;
}

const tickets = computed(() => {
  return props.purchase.tickets!.filter(
    (t) => t.eventPriceId == props.eventPrice.id,
  );
});

const subtotal = computed(() => {
  return formatCurrency(
    tickets.value.reduce((prev, cur) => prev + cur.price!, 0),
  );
});

const subtotalWithFees = computed(() => {
  return formatCurrency(
    tickets.value.reduce((prev, cur) => prev + cur.subtotalWithFees!, 0),
  );
});

async function setQuantity(input: string | number) {
  var num = parseInt(input.toString());
  const oldLocalQuantity = localQuantity.value;
  localQuantity.value = num;

  try {
    await service.setTicketQuantity(
      props.purchase.publicId,
      props.eventPrice.id,
      num,
    );

    cartQuantityChanged(
      props.purchase,
      props.eventPrice,
      num - oldLocalQuantity,
    );
  } catch (err) {
    console.error(err);

    // Always refresh the local quantities if there is an error.
    refreshLocalQuantity();
  }
}

service.setTicketQuantity.setConcurrency("debounce");
setupPurchaseReloadHandling(props.purchase, service.setTicketQuantity);

refreshLocalQuantity();
</script>
