<template>
  <labeled-section
    title="Purchase Protection"
    icon="fal fa-shield-check"
    v-if="premiumRefundsOffered"
  >
    <v-card variant="outlined" class="purchase-addon-selector">
      <v-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Item</th>
              <th class="purchase-addon-description-header"></th>
              <th class="text-right" width="100px">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="premiumRefundsOffered"
              class="purchase-addon-selector-row"
            >
              <td class="purchase-addon-description">
                <span class="text-h6 primary-text">Purchase Protection</span>
                <div class="text-caption" v-text="priceDescription"></div>
              </td>
              <td class="text-left pr-0">
                <v-radio-group
                  :model-value="
                    purchase.refundable
                  "
                  @update:model-value="
                    setRefundable($event);
                    hasPickedRefundable = true;
                  "
                  :disabled="purchase.$savingProps.has('refundable')"
                  class="mt-0 pt-0"
                  :rules="[
                    () => hasPickedRefundable || 'Please select an option',
                  ]"
                >
                  <template #label>
                    <div style="white-space: normal">
                      Would you like to add purchase protection, as described below, to your order?
                    </div>
                  </template>
                  <v-radio
                    :value="RefundableState.Purchased"
                    label="Yes - Please add purchase protection."
                  ></v-radio>
                  <v-radio
                    :value="RefundableState.Declined"
                    label="No - My purchase will be final and nonrefundable."
                  ></v-radio>
                </v-radio-group>

                <!-- Old buttons, pre #1154: -->
                <!--
            <v-btn
              v-if="purchase.refundable != RefundableState.Purchased"
              color="primary"
              @click="setRefundable(RefundableState.Purchased)"
              :loading="purchase.$savingProps.has('refundable')"
            >
              <span class="d-none d-sm-inline"> Add to Cart </span>
              <span class="d-sm-none"> Add </span>
            </v-btn>
            <v-btn
              v-else
              color="primary"
              outlined
              @click="setRefundable(RefundableState.Declined)"
              :loading="purchase.$savingProps.has('refundable')"
            >
              Remove
            </v-btn> -->
              </td>
              <td class="text-right">
                <v-fade-transition mode="out-in">
                  <v-skeleton-loader
                    key="skeleton"
                    v-if="purchase.$savingProps.has('refundable')"
                    type="text"
                    class="pt-1"
                  />
                  <div v-else key="value">
                    {{
                      formatCurrency(
                        purchase.refundable == RefundableState.Purchased
                          ? purchase.refundableFee
                          : 0,
                      )
                    }}
                  </div>
                </v-fade-transition>
              </td>
            </tr>
            <tr class="purchase-addon-row2">
              <td
                colspan="3"
                class="purchase-addon-description pb-0 text-center"
              >
                <span class="text-h6 primary-text">Purchase Protection</span>
                <div class="text-caption" v-text="priceDescription"></div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
      <div
        class="px-4 py-2"
        style="white-space: pre-wrap"
        v-text="
          purchase.refundPolicy || 'PURCHASE_PROTECTION_DESCRIPTION_MISSING'
        "
      ></div>
    </v-card>
  </labeled-section>
</template>

<script setup lang="ts">
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { formatCurrency } from "@common/utils";
import { PurchaseType, RefundableState } from "@/models.g";
import { purchaseProtectionChanged } from "@common/analytics";
import { computed, ref } from "vue";

const props = defineProps<{ purchase: TicketPurchaseDtoViewModel }>();

// Exchanges will have the value pre-populated from the previous purchase
const hasPickedRefundable = ref(props.purchase.type == PurchaseType.Exchange);

const premiumRefundsOffered = computed(() => {
  return props.purchase.refundable != RefundableState.NotOffered;
});

const priceDescription = computed(() => {
  const purchase = props.purchase;
  return `${formatCurrency(purchase.refundableFee)} (${(
    purchase.refundableFeePercentage || 0
  ).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })}% of ticket price)`;
});

function setRefundable(state: RefundableState) {
  purchaseProtectionChanged(props.purchase, state);
  props.purchase.$save({
    refundable: state,
  });
}
</script>
