<template>
  <v-text-field
    @update:model-value="(v) => setQuantity(v)"
    @focus="$event.target.select()"
    :model-value="value"
    class="quantity-input"
    type="number"
    :min="min"
    step="1"
    variant="outlined"
    density="compact"
    hide-details
    v-bind="$attrs"
  >
    <template v-slot:prepend v-if="showButtons">
      <v-btn
        icon
        variant="plain"
        :disabled="value <= min"
        @click="setQuantity(value - 1)"
      >
        <v-icon>fa fa-minus</v-icon>
      </v-btn>
    </template>
    <template v-slot:append v-if="showButtons">
      <v-btn icon variant="plain" @click="setQuantity(value + 1)">
        <v-icon>fa fa-plus</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<style lang="scss">
.quantity-input {
  display: inline-flex;
  margin: auto !important;
  align-items: center !important;

  > * {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .v-input__control {
    width: 70px;
  }

  input {
    font-size: 20px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>

<script setup lang="ts">
import { computed } from "vue";
import { useDisplay } from "vuetify";

withDefaults(
  defineProps<{
    value: number;
    min?: number;
  }>(),
  { min: 0 },
);

const emit = defineEmits(["update"]);

function setQuantity(input: string | number) {
  const num = parseInt(input.toString());
  emit("update", num);
}

const display = ref(useDisplay());

const showButtons = computed(() => {
  return display.value.mdAndUp;
});
</script>
