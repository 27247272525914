<template>
  <c-loader-status
    :loaders="{
      'no-initial-content no-loading-content no-secondary-progress': [
        service.getSeating,
      ],
    }"
  >
    <seat-map-render
      class="purchase-seat-map can-pan"
      v-if="context"
      :context="context"
    >
    </seat-map-render>

    <purchase-seat-price-select :purchase="purchase"  v-model="priceSelect">
      <template v-slot:before-list>
        <!--
          Render out the agent notes of any applicable restrictions.
          AgentNote is not exposed for public users,
          so this will effectively only ever render for logged in users. -->
        <v-alert
          v-for="(restriction, idx) in (priceSelect.restrictions || []).filter(
            (r) => !!r.agentNote,
          )"
          :key="'restriction-' + idx"
          type="info"
          density="compact"
          class="mb-1"
        >
          {{ restriction.agentNote }}
        </v-alert>

        <c-loader-status
          :loaders="{
            '': [service.addTicket, service.removeTicket],
          }"
        />
      </template>
      <template v-slot:price-action="{ eventPrice }">
        <v-btn
          variant="outlined"
          color="primary"
          :loading="
            service.addTicket.isLoading &&
            service.addTicket.args.eventPriceId === eventPrice.id
          "
          @click="() => addTicket(priceSelect.seat, eventPrice)"
        >
          Select
        </v-btn>
      </template>
      <template
        v-slot:price-action-ga="{
          generalAdmissionToBeAddedList,
          generalAdmissionToBeRemovedList,
          totalCombined
        }"
      >
        <div class="flex flex-col">
          <p>
            Adding new {{ totalCombined }} ticket(s) to cart
          </p>
          <p>Max tickets you can add: {{priceSelect.availableAmount}}</p>
          <div class="d-flex justify-center">
            <v-btn
              variant="outlined"
              color="primary"
              :loading="
            service.addMultipleTickets.isLoading ||
            service.removeMultipleTickets.isLoading"
              @click="
            onUpdateGACart(
              generalAdmissionToBeAddedList,
              generalAdmissionToBeRemovedList,
            )"
              :disabled="totalCombined > priceSelect.availableAmount"
            >
              Confirm
            </v-btn>
          </div>

        </div>
      </template>
    </purchase-seat-price-select>
  </c-loader-status>
</template>

<script setup lang="ts">
import { ref, watch, nextTick } from "vue";
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { EventDateSeatingRestrictionInfo } from "@/models.g";
import { setupPurchaseReloadHandling } from "@common/utils";

import { EventPriceDto, SeatMapSeatDto, SeatMapSectionDto } from "@/models.g";

import { cartQuantityChanged } from "@common/analytics";
import { PurchaseSeatMapContext } from "@common/seat-map-context";
import PurchaseSeatPriceSelect from "@common/purchase/purchase-seat-price-select.vue";
import SeatMapRender from "@common/components/seat-map-render.vue";

const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
}>();

const service = new PublicPurchaseServiceViewModel();

const context = ref<PurchaseSeatMapContext | null>(null);

const priceSelect = ref<{
  seat: SeatMapSeatDto;
  section: SeatMapSectionDto;
  availableAmount: number
  restrictions?: EventDateSeatingRestrictionInfo[];
} | null>(null);

async function addTicket(seat: SeatMapSeatDto, eventPrice: EventPriceDto) {
  try {
    // Preemptively add the seat to the set of objects
    // so that it doesn't briefly appear as "taken"
    // when the SignalR realtime availability update
    // comes before the response to addTicket.
    context.value?.selectedObjects.add(seat);
    service.addTicket.args = {
      publicId: props.purchase.publicId,
      eventPriceId: eventPrice.id,
      seat: seat.seatNumber,
    };
    // This is invoked with args for easy tracking of which event price was clicked,
    // so we can show appropriate loading indicators in the UI.
    await service.addTicket.invokeWithArgs();

    // Send analytics events:
    cartQuantityChanged(props.purchase, eventPrice, 1);
    priceSelect.value = null;
  } catch {
    context.value?.selectedObjects.delete(seat);
  }
}

async function onUpdateGACart(
  generalAdmissionToBeAddedList,
  generalAdmissionToBeRemovedList,
) {
  await addGeneralAdmissionTicket(
    priceSelect.value.seat,
    generalAdmissionToBeAddedList,
  );
  await removeGeneralAdmissionTicket(
    priceSelect.value.seat,
    generalAdmissionToBeRemovedList,
  );
  // Reset purchase and SeatingInfo
  await props.purchase.$load();
  await service.getSeating(props.purchase.publicId);
  priceSelect.value = null;
}

function getGeneralAdmissionPayload(
  generalAdmissionToBeAddedList: {
    eventPrice: EventPriceDto;
    quantity: number;
  }[],
) {
  return generalAdmissionToBeAddedList.map((eventPriceWithQuantity) => ({
    quantity: eventPriceWithQuantity.quantity,
    eventPriceId: eventPriceWithQuantity.eventPrice.id,
  }));
}

async function addGeneralAdmissionTicket(
  seat: SeatMapSeatDto,
  generalAdmissionToBeAddedList: {
    eventPrice: EventPriceDto;
    quantity: number;
  }[],
) {
  if (generalAdmissionToBeAddedList.length > 0) {
    const generalAdmissionPayload = getGeneralAdmissionPayload(
      generalAdmissionToBeAddedList,
    );
    try {
      service.addMultipleTickets.args = {
        publicId: props.purchase.publicId,
        tickets: generalAdmissionPayload,
        seat: seat.seatNumber,
      };

      await service.addMultipleTickets.invokeWithArgs();

      // TODO: this may need for Analytics
      // generalAdmissionToBeAddedList
      //   .map(eventPriceWithQuantity => {
      //     cartQuantityChanged(props.purchase, eventPriceWithQuantity.eventPrice, eventPriceWithQuantity.quantity);
      //   });
    } catch (error) {
      console.log(error);
      alert(
        "Your request was not fully processed. Some seats might have gone through, please check last section you were on.",
      );
    }
  }
}

async function removeGeneralAdmissionTicket(
  seat: SeatMapSeatDto,
  generalAdmissionToBeRemovedList: {
    eventPrice: EventPriceDto;
    quantity: number;
  }[],
) {
  if (generalAdmissionToBeRemovedList.length > 0) {
    const ticketIdList: number[] = [];

    generalAdmissionToBeRemovedList.map((item) => {
      const filteredEventPrice = props.purchase.tickets.filter(
        (ticket) => ticket.eventPriceId === item.eventPrice.id,
      );
      for (let i = 0; i < item.quantity; i++) {
        ticketIdList.push(filteredEventPrice[i].ticketId);
      }
    });
    try {
      service.removeMultipleTickets.args = {
        publicId: props.purchase.publicId,
        ticketIdList: ticketIdList,
      };
      await service.removeMultipleTickets.invokeWithArgs();
      // TODO: this may need for Analytics
      // generalAdmissionToBeAddedList
      //   .map(eventPriceWithQuantity => {
      //     cartQuantityChanged(props.purchase, eventPriceWithQuantity.eventPrice, -(eventPriceWithQuantity.quantity));
      //   });
    } catch (error) {
      console.log(error);
    }
  }
}

service.addTicket.setConcurrency("debounce");
service.removeTicket.setConcurrency("debounce");
service.addMultipleTickets.setConcurrency("debounce");

const endpoints = [service.addTicket, service.removeTicket];

for (const endpoint of endpoints) {
  setupPurchaseReloadHandling(props.purchase, endpoint, ...endpoints);
}

service.getSeating.onFulfilled( async (res) => {
  if (res.wasSuccessful) {
    const priceSelectCallback = (value: {
      seat: SeatMapSeatDto;
      section: SeatMapSectionDto;
      availableAmount: number
    }) => {
      priceSelect.value = value;
    };

    context.value = new PurchaseSeatMapContext(
      service.getSeating.result!,
      props.purchase,
      service,
      priceSelectCallback,
      nextTick,
    );
  }
});
service.getSeating(props.purchase.publicId);

watch(
  () => props.purchase.tickets,
  () => {
    if (!context.value) {
      return;
    }
    context.value.selectedObjects = new Set(
      context.value.allSeats.filter((seat) =>
        props.purchase.tickets?.some((t) => t.seatNumber == seat.seatNumber),
      ),
    );
  },
  { deep: true },
);
</script>

<style lang="scss">
.purchase-seat-map {
  .seat-map-scroll-frame {
    // Always shorter than the window (70vh),
    // but also not too tall on very tall screens (1000px ~ roughly square)
    max-height: min(70vh, 1000px) !important;
  }

  .seat {
    cursor: pointer;

    &.original-seat {
      border-style: dotted;
      border-color: black;
    }
  }

  .annotation:hover::before {
    opacity: 0 !important;
  }
}
</style>