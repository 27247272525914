<template>
  <v-container class="page-container">
    <labeled-section
      title="Purchase Tickets"
      subtitle="Redirecting to Payment"
      class="text-center"
    >
      <h1 class="mx-auto" style="margin-top: 10vh">
        Redirecting you to our trusted payment processor
      </h1>
      <v-row class="my-10">
        <v-progress-circular
          indeterminate
          size="150"
          class="mx-auto"
        ></v-progress-circular>
      </v-row>
      <h2 class="mx-auto mb-4">
        If you are not redirected automatically, click the link below:
      </h2>
      <a :href="redirectUrl" class="d-block mb-8">{{ redirectUrl }}</a>
    </labeled-section>
  </v-container>
</template>

<script setup lang="ts">
import { useTitle } from "@common/composables/useTitle";

useTitle("Redirecting");

defineProps<{ publicId: string; redirectUrl: string }>();
</script>
