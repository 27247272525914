<template>
  <div class="raw-credit-card-input">
    <v-row>
      <v-col cols="7" md="5">
        <c-input
          :model="cardDetails"
          for="cardNumber"
          label="Credit Card Number"
          inputmode="numeric"
          pattern="[0-9\s]{13,19}"
          autocomplete="cc-number"
          hide-details="auto"
          maxlength="19"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="5" md="3" class="hidden-md-and-up">
        <c-input
          v-if="requireFullDetails"
          :model="cardDetails"
          for="cvv"
          label="CVV/CVC Code"
          type="number"
          autocomplete="cc-csc"
          hide-details="auto"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="6" md="2">
        <c-input
          :model="cardDetails"
          for="expirationMonth"
          type="number"
          label="Exp. Month"
          autocomplete="cc-exp-month"
          hide-details="auto"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="6" md="2">
        <c-input
          :model="cardDetails"
          for="expirationYear"
          label="Exp. Year"
          type="number"
          autocomplete="cc-exp-year"
          hide-details="auto"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="3" md="3" class="hidden-sm-and-down">
        <c-input
          v-if="requireFullDetails"
          :model="cardDetails"
          for="cvv"
          label="CVV/CVC Code"
          type="number"
          autocomplete="cc-csc"
          hide-details="auto"
          :rules="[requiredRule]"
        />
      </v-col>
    </v-row>

    <v-row v-if="requireFullDetails">
      <v-col cols="12" md="4">
        <c-input
          :model="cardDetails"
          for="billingAddress"
          autocomplete="billing street-address"
          hide-details="auto"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="12" md="2">
        <c-input
          :model="cardDetails"
          for="billingZip"
          label="Postal Code"
          autocomplete="billing postal-code"
          hide-details="auto"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="cardDetails.billingCountry"
          variant="outlined"
          :items="validCountries"
          item-title="name"
          item-value="abbreviation"
          hide-details="auto"
          label="Country"
          :rules="[requiredRule]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="cardDetails.billingState"
          variant="outlined"
          :items="validStates"
          :disabled="!validStates.length"
          item-title="name"
          item-value="abbreviation"
          hide-details="auto"
          label="State/Province"
          :rules="[requiredRule]"
        />
      </v-col>
    </v-row>

    <v-row>
      <template v-if="cardReader">
        <v-col cols="12" md="5">
          <v-card
            border
            elevation="2"
            class="card-swipe-input-wrapper"
            @click="cardInput?.focus()"
          >
            <div class="text-overline font-weight-bold">Card Reader</div>

            <!-- Must use a native input here for performance - the card readers
            send hundreds of single keypress events in a very short amount of time. -->
            <textarea
              class="card-swipe-input"
              @input="cardSwipeChanged"
              @focus="isInputFocused = true"
              @blur="isInputFocused = false"
              tabindex="-1"
              ref="cardInput"
            />
          </v-card>
        </v-col>
        <v-col cols="12" md="7">
          <v-alert v-if="!swipeDataEntered && isInputFocused" color="info">
            Ready. Scan the credit card with the credit card reader.
          </v-alert>
          <v-alert
            v-else-if="!swipeDataEntered && !isInputFocused"
            color="warning-darken-1"
          >
            <v-btn color="white" @click="reset()" class="float-left mr-3">
              <v-icon start> fal fa-bullseye-pointer</v-icon>
              Focus
            </v-btn>
            Card reader not ready.
            <br />
            Place your cursor in the Card Reader field.
          </v-alert>
          <v-alert v-else-if="swipeDataEntered" color="success">
            Swipe Successful
            <v-btn
              icon
              variant="text"
              color="white"
              @click="reset()"
              class="float-left my-n2 mr-3"
            >
              <v-icon> fal fa-undo</v-icon>
            </v-btn>
          </v-alert>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import debounce from "lodash-es/debounce";
import { ref, computed, watch } from "vue";

import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import { CreditCardDetails } from "@/models.g";
import { PublicPurchaseServiceApiClient } from "@/api-clients.g";

import {
  validCountries,
  validStates as geographicStates,
} from "@common/geographic-data";
import { reactive } from "vue";

const emits = defineEmits<{
  (e: "setCCDetails", newValue: CreditCardDetails[]): void;
}>();

const props = withDefaults(
  defineProps<{
    paymentId?: number;
    purchase: TicketPurchaseDtoViewModel;
    cardReader?: boolean;
    requireFullDetails?: boolean;
  }>(),
  {
    paymentId: -1,
    cardReader: false,
    requireFullDetails: false,
  },
);

const isInputFocused = ref(false);
let debouncedParse!: ((input: string) => void) & { cancel(): void };

function cardSwipeChanged(e: InputEvent) {
  debouncedParse.cancel();
  debouncedParse((e.target as HTMLTextAreaElement).value);
}

const cardInput = ref<HTMLTextAreaElement>();

const cardDetails = reactive<CreditCardDetails>(
  new CreditCardDetails({ paymentId: props.paymentId }),
);

const swipeDataEntered = computed(() => {
  const details = cardDetails;
  return (
    !!details.cardNumber &&
    !!details.expirationMonth &&
    !!details.expirationYear
  );
});

watch(
  () => cardDetails.billingCountry,
  (n, o) => {
    if (n && o) {
      cardDetails.billingState = null;
    }
  },
);

watch(
  () => cardDetails.billingZip,
  (v: string | null) => {
    if ((v?.length ?? 0) < 4) return;
    new PublicPurchaseServiceApiClient().getPostalCodeInfo(v).then((r) => {
      const info = r?.data?.object;
      // Ensure the current value is still the same as when we made the request:
      if (info && cardDetails.billingZip === v) {
        cardDetails.billingState = info.stateAbbr;
        cardDetails.billingCountry = info.country;
      }
    });
  },
);

let cardUpdateTimer: NodeJS.Timeout | null = null;
watch(
  cardDetails,
  () => {
    if (cardUpdateTimer) {
      clearTimeout(cardUpdateTimer);
    }
    cardUpdateTimer = setTimeout(() => updateCard(), 200);
  },
  { deep: true },
);

debouncedParse = debounce((swipe: string) => {
  if (!swipe) return;

  // This parses raw data from track 1 of a credit card.
  // Some examples:
  // https://www.qsl.net/kb1efz/magstripe/extrawork/magexam1.html
  // http://sagan.gae.ucm.es/~padilla/extrawork/magexam1.html
  // The rest of the internet has good examples too.

  /*
%B1234567890123445^PADILLA VISDOMINE/LUIS    ^9901XXX0000000000000**XXX******?*
^^^               ^^                         ^^   ^                 ^        ^^
|||_ Card number  ||_ Card holder            ||   |_ Number1        |        ||_ LRC
||_ Format code   |_ Field separator         ||_ Expiration         |        |_ End sentinel
|_ Start sentinel                            |_ Field separator     |_ Number2

  */

  const [numPart, namePart, expirationPart] = swipe.split("^");

  if (numPart) {
    cardDetails.cardNumber = numPart.substring(2, 18);
  }
  if (expirationPart) {
    cardDetails.expirationMonth = +expirationPart.substring(2, 4);
    cardDetails.expirationYear = +expirationPart.substring(0, 2);
  }
  if (namePart) {
    // NOTE: This is what the old code did. However, it seems incorrect based on the example above.
    // Due to COVID-19, I don't currently have a scanner to test this for real.
    const firstLastMiddle = namePart.split(" ")[0];
    var firstAndLast = firstLastMiddle.split("/");

    if (!props.purchase.firstName) {
      props.purchase.firstName = firstAndLast[1];
    }
    if (!props.purchase.lastName) {
      props.purchase.lastName = firstAndLast[0];
    }
  }

  // Clear the input after we parse.
  if (cardInput.value) cardInput.value.value = "";
  updateCard();
}, 200);

function updateCard() {
  emits("setCCDetails", [cardDetails]);
}

function reset(noFocus: boolean = false) {
  cardDetails.cardNumber = null;
  cardDetails.expirationMonth = null;
  cardDetails.expirationYear = null;
  if (cardInput.value) {
    cardInput.value.value = "";
    if (!noFocus) {
      cardInput.value.focus();
    }
  }
}

const requiredRule = (v: any) => !!v || "Required";

const validStates = computed(() => {
  return geographicStates(cardDetails.billingCountry);
});
</script>

<style lang="scss">
.raw-credit-card-input {
  .card-swipe-input-wrapper {
    padding: 9px;

    .card-swipe-input {
      height: 35px;
      width: 100%;
      resize: none;
      outline: none;
      font-size: 8px;
      line-height: 1.1;
    }
  }

  .v-alert {
    color: white;
    margin-bottom: 0px;
  }
}
</style>
