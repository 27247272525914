import * as $metadata from './metadata.g'
import * as $models from './models.g'
import * as $apiClients from './api-clients.g'
import { ViewModel, ListViewModel, ViewModelCollection, ServiceViewModel, DeepPartial, defineProps } from 'coalesce-vue/lib/viewmodel'

export interface EventDateDtoViewModel extends $models.EventDateDto {
  id: number | null;
  eventStartDateTime: Date | null;
  eventEndDateTime: Date | null;
  eventPublicPurchaseDatetime: Date | null;
  
  /** The start of the event, represented in the local time of the event. */
  eventStartDateTimeLocal: Date | null;
  
  /** The end of the event, represented in the local time of the event. */
  eventEndDateTimeLocal: Date | null;
  venueTimezone: string | null;
  durationMinutes: number | null;
  eventCapacity: number | null;
  cancelled: boolean | null;
  eventId: number | null;
  eventName: string | null;
  eventShortDescription: string | null;
  eventLongDescription: string | null;
  eventLocation: string | null;
  eventBoardingLabel: string | null;
  eventBoardingMinutes: number | null;
  hasFood: boolean | null;
  availableSeats: number | null;
  customMoreOptionsButtonText: string | null;
  customMoreOptionsIcon: string | null;
  customMoreOptionsUrl: string | null;
  customSelectTicketsUrl: string | null;
  customSelectTicketsButtonText: string | null;
  customSelectTicketsIcon: string | null;
  
  /** Indicates if the event date has a seat map. */
  isSeated: boolean | null;
  soldOut: boolean | null;
  eventCustomSoldOutText: string | null;
  isEventActive: boolean | null;
  isFreeEvent: boolean | null;
  hiddenFromInitialPurchase: boolean | null;
  
  /** 
    A rollup of the various status flags of the event.
    Does not specifically indicate sellability.
  */
  statusDescription: string | null;
  
  /** 
    If non-null, contains the reason that a purchase of the
    event cannot be initiated by the current user.
  */
  unpurchasableReason: string | null;
  eventPrices: $models.EventPriceDto[] | null;
  eventBannerImage: string | null;
  eventImages: $models.EventImage[] | null;
}
export class EventDateDtoViewModel extends ViewModel<$models.EventDateDto, $apiClients.EventDateDtoApiClient, number> implements $models.EventDateDto  {
  static DataSources = $models.EventDateDto.DataSources;
  
  constructor(initialData?: DeepPartial<$models.EventDateDto> | null) {
    super($metadata.EventDateDto, new $apiClients.EventDateDtoApiClient(), initialData)
    this.$saveMode = "whole"
  }
}
defineProps(EventDateDtoViewModel, $metadata.EventDateDto)

export class EventDateDtoListViewModel extends ListViewModel<$models.EventDateDto, $apiClients.EventDateDtoApiClient, EventDateDtoViewModel> {
  static DataSources = $models.EventDateDto.DataSources;
  
  constructor() {
    super($metadata.EventDateDto, new $apiClients.EventDateDtoApiClient())
  }
}


export interface EventDtoViewModel extends $models.EventDto {
  id: number | null;
  description: string | null;
}
export class EventDtoViewModel extends ViewModel<$models.EventDto, $apiClients.EventDtoApiClient, number> implements $models.EventDto  {
  static DataSources = $models.EventDto.DataSources;
  
  constructor(initialData?: DeepPartial<$models.EventDto> | null) {
    super($metadata.EventDto, new $apiClients.EventDtoApiClient(), initialData)
    this.$saveMode = "whole"
  }
}
defineProps(EventDtoViewModel, $metadata.EventDto)

export class EventDtoListViewModel extends ListViewModel<$models.EventDto, $apiClients.EventDtoApiClient, EventDtoViewModel> {
  static DataSources = $models.EventDto.DataSources;
  
  constructor() {
    super($metadata.EventDto, new $apiClients.EventDtoApiClient())
  }
}


export interface EventTypeDtoViewModel extends $models.EventTypeDto {
  id: number | null;
  description: string | null;
}
export class EventTypeDtoViewModel extends ViewModel<$models.EventTypeDto, $apiClients.EventTypeDtoApiClient, number> implements $models.EventTypeDto  {
  static DataSources = $models.EventTypeDto.DataSources;
  
  constructor(initialData?: DeepPartial<$models.EventTypeDto> | null) {
    super($metadata.EventTypeDto, new $apiClients.EventTypeDtoApiClient(), initialData)
    this.$saveMode = "whole"
  }
}
defineProps(EventTypeDtoViewModel, $metadata.EventTypeDto)

export class EventTypeDtoListViewModel extends ListViewModel<$models.EventTypeDto, $apiClients.EventTypeDtoApiClient, EventTypeDtoViewModel> {
  static DataSources = $models.EventTypeDto.DataSources;
  
  constructor() {
    super($metadata.EventTypeDto, new $apiClients.EventTypeDtoApiClient())
  }
}


export interface TicketPurchaseDtoViewModel extends $models.TicketPurchaseDto {
  
  /** 
    A non-sequential identifier for the purchase.
    NOT the TicketPurchase.Id.
  */
  publicId: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  marketingOptOut: boolean | null;
  refundable: $models.RefundableState | null;
  isAllowTextMessaging: boolean | null;
  tenantTextMessagingEnabled: boolean | null;
  addressStreet: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressZip: string | null;
  addressCountry: string | null;
  roomNumber: string | null;
  willCall: boolean | null;
  
  /** 
    Whether the Service Fee is waived for this purchase. Does not affect the Convenience Fee.
    
    Related work:
    https://hagadone.visualstudio.com/Ticketing/_workitems/edit/1186
    https://hagadone.visualstudio.com/Ticketing/_workitems/edit/1193
  */
  waiveTicketFees: boolean | null;
  canWaiveTicketFees: boolean | null;
  creationDate: Date | null;
  expirationDate: Date | null;
  isPublic: boolean | null;
  type: $models.PurchaseType | null;
  status: $models.PurchaseStatus | null;
  failureMessage: string | null;
  refundComment: string | null;
  confirmationCode: string | null;
  refundPolicy: string | null;
  
  /** 
    The actual amount that was charged for the premium refund option,
    or the amount that would/will be charged if the purchase is not yet complete.
  */
  refundableFee: number | null;
  refundedRefundableFee: number | null;
  refundableFeePercentage: number | null;
  convenienceFee: number | null;
  refundedConvenienceFee: number | null;
  salesTax: number | null;
  refundedSalesTax: number | null;
  balance: number | null;
  grandTotal: number | null;
  eventDateId: number | null;
  get eventDate(): EventDateDtoViewModel | null;
  set eventDate(value: EventDateDtoViewModel | $models.EventDateDto | null);
  venueDisclaimer: string | null;
  tickets: $models.TicketPurchaseTicket[] | null;
  addons: $models.TicketPurchaseAddon[] | null;
  discounts: $models.TicketPurchaseDiscount[] | null;
  payments: $models.TicketPurchasePayment[] | null;
  dietaryRestrictions: $models.TicketPurchaseDietaryRestriction[] | null;
  availableAddons: $models.EventAddonDto[] | null;
  eventSuggestedEvents: $models.EventSuggestedEvent[] | null;
  originalPurchase: $models.TicketPurchaseRelatedPurchase | null;
}
export class TicketPurchaseDtoViewModel extends ViewModel<$models.TicketPurchaseDto, $apiClients.TicketPurchaseDtoApiClient, string> implements $models.TicketPurchaseDto  {
  static DataSources = $models.TicketPurchaseDto.DataSources;
  
  constructor(initialData?: DeepPartial<$models.TicketPurchaseDto> | null) {
    super($metadata.TicketPurchaseDto, new $apiClients.TicketPurchaseDtoApiClient(), initialData)
    this.$saveMode = "whole"
  }
}
defineProps(TicketPurchaseDtoViewModel, $metadata.TicketPurchaseDto)

export class TicketPurchaseDtoListViewModel extends ListViewModel<$models.TicketPurchaseDto, $apiClients.TicketPurchaseDtoApiClient, TicketPurchaseDtoViewModel> {
  static DataSources = $models.TicketPurchaseDto.DataSources;
  
  constructor() {
    super($metadata.TicketPurchaseDto, new $apiClients.TicketPurchaseDtoApiClient())
  }
}


export class PublicPurchaseServiceViewModel extends ServiceViewModel<typeof $metadata.PublicPurchaseService, $apiClients.PublicPurchaseServiceApiClient> {
  
  public get initiatePurchase() {
    const initiatePurchase = this.$apiClient.$makeCaller(
      this.$metadata.methods.initiatePurchase,
      (c, eventDateId?: number | null) => c.initiatePurchase(eventDateId),
      () => ({eventDateId: null as number | null, }),
      (c, args) => c.initiatePurchase(args.eventDateId))
    
    Object.defineProperty(this, 'initiatePurchase', {value: initiatePurchase});
    return initiatePurchase
  }
  
  public get getSeating() {
    const getSeating = this.$apiClient.$makeCaller(
      this.$metadata.methods.getSeating,
      (c, publicId: string | null) => c.getSeating(publicId),
      () => ({publicId: null as string | null, }),
      (c, args) => c.getSeating(args.publicId))
    
    Object.defineProperty(this, 'getSeating', {value: getSeating});
    return getSeating
  }
  
  /** Ticket adjustment for non-seated events */
  public get setTicketQuantity() {
    const setTicketQuantity = this.$apiClient.$makeCaller(
      this.$metadata.methods.setTicketQuantity,
      (c, publicId: string | null, eventPriceId?: number | null, quantity?: number | null) => c.setTicketQuantity(publicId, eventPriceId, quantity),
      () => ({publicId: null as string | null, eventPriceId: null as number | null, quantity: null as number | null, }),
      (c, args) => c.setTicketQuantity(args.publicId, args.eventPriceId, args.quantity))
    
    Object.defineProperty(this, 'setTicketQuantity', {value: setTicketQuantity});
    return setTicketQuantity
  }
  
  /** Ticket addition for seated events */
  public get addTicket() {
    const addTicket = this.$apiClient.$makeCaller(
      this.$metadata.methods.addTicket,
      (c, publicId: string | null, eventPriceId: number | null, seat: string | null) => c.addTicket(publicId, eventPriceId, seat),
      () => ({publicId: null as string | null, eventPriceId: null as number | null, seat: null as string | null, }),
      (c, args) => c.addTicket(args.publicId, args.eventPriceId, args.seat))
    
    Object.defineProperty(this, 'addTicket', {value: addTicket});
    return addTicket
  }
  
  /** Ticket addition for seated events (multiple tickets) */
  public get addMultipleTickets() {
    const addMultipleTickets = this.$apiClient.$makeCaller(
      this.$metadata.methods.addMultipleTickets,
      (c, publicId: string | null, tickets: $models.TicketInput[] | null, seat: string | null) => c.addMultipleTickets(publicId, tickets, seat),
      () => ({publicId: null as string | null, tickets: null as $models.TicketInput[] | null, seat: null as string | null, }),
      (c, args) => c.addMultipleTickets(args.publicId, args.tickets, args.seat))
    
    Object.defineProperty(this, 'addMultipleTickets', {value: addMultipleTickets});
    return addMultipleTickets
  }
  
  /** Ticket addition for seated events (multiple tickets) */
  public get removeMultipleTickets() {
    const removeMultipleTickets = this.$apiClient.$makeCaller(
      this.$metadata.methods.removeMultipleTickets,
      (c, publicId: string | null, ticketIdList: number[] | null) => c.removeMultipleTickets(publicId, ticketIdList),
      () => ({publicId: null as string | null, ticketIdList: null as number[] | null, }),
      (c, args) => c.removeMultipleTickets(args.publicId, args.ticketIdList))
    
    Object.defineProperty(this, 'removeMultipleTickets', {value: removeMultipleTickets});
    return removeMultipleTickets
  }
  
  public get removeTicket() {
    const removeTicket = this.$apiClient.$makeCaller(
      this.$metadata.methods.removeTicket,
      (c, publicId: string | null, ticketId?: number | null) => c.removeTicket(publicId, ticketId),
      () => ({publicId: null as string | null, ticketId: null as number | null, }),
      (c, args) => c.removeTicket(args.publicId, args.ticketId))
    
    Object.defineProperty(this, 'removeTicket', {value: removeTicket});
    return removeTicket
  }
  
  public get setAddonQuantity() {
    const setAddonQuantity = this.$apiClient.$makeCaller(
      this.$metadata.methods.setAddonQuantity,
      (c, publicId: string | null, addonConfigId?: number | null, quantity?: number | null) => c.setAddonQuantity(publicId, addonConfigId, quantity),
      () => ({publicId: null as string | null, addonConfigId: null as number | null, quantity: null as number | null, }),
      (c, args) => c.setAddonQuantity(args.publicId, args.addonConfigId, args.quantity))
    
    Object.defineProperty(this, 'setAddonQuantity', {value: setAddonQuantity});
    return setAddonQuantity
  }
  
  public get setDietaryRestriction() {
    const setDietaryRestriction = this.$apiClient.$makeCaller(
      this.$metadata.methods.setDietaryRestriction,
      (c, publicId: string | null, allergen: string | null, quantity?: number | null, comment?: string | null) => c.setDietaryRestriction(publicId, allergen, quantity, comment),
      () => ({publicId: null as string | null, allergen: null as string | null, quantity: null as number | null, comment: null as string | null, }),
      (c, args) => c.setDietaryRestriction(args.publicId, args.allergen, args.quantity, args.comment))
    
    Object.defineProperty(this, 'setDietaryRestriction', {value: setDietaryRestriction});
    return setDietaryRestriction
  }
  
  public get removeDiscount() {
    const removeDiscount = this.$apiClient.$makeCaller(
      this.$metadata.methods.removeDiscount,
      (c, publicId: string | null, discountId?: number | null) => c.removeDiscount(publicId, discountId),
      () => ({publicId: null as string | null, discountId: null as number | null, }),
      (c, args) => c.removeDiscount(args.publicId, args.discountId))
    
    Object.defineProperty(this, 'removeDiscount', {value: removeDiscount});
    return removeDiscount
  }
  
  public get applyDiscountCode() {
    const applyDiscountCode = this.$apiClient.$makeCaller(
      this.$metadata.methods.applyDiscountCode,
      (c, publicId: string | null, discountCode?: string | null) => c.applyDiscountCode(publicId, discountCode),
      () => ({publicId: null as string | null, discountCode: null as string | null, }),
      (c, args) => c.applyDiscountCode(args.publicId, args.discountCode))
    
    Object.defineProperty(this, 'applyDiscountCode', {value: applyDiscountCode});
    return applyDiscountCode
  }
  
  /** 
    Complete a public purchase,
    using the provided cardDetails if the current paymentProcessor can process card payments on the server.
    Otherwise, the cardDetails are ignored.
  */
  public get completePublicCardPayment() {
    const completePublicCardPayment = this.$apiClient.$makeCaller(
      this.$metadata.methods.completePublicCardPayment,
      (c, publicId: string | null, cardDetails: $models.CreditCardDetails | null) => c.completePublicCardPayment(publicId, cardDetails),
      () => ({publicId: null as string | null, cardDetails: null as $models.CreditCardDetails | null, }),
      (c, args) => c.completePublicCardPayment(args.publicId, args.cardDetails))
    
    Object.defineProperty(this, 'completePublicCardPayment', {value: completePublicCardPayment});
    return completePublicCardPayment
  }
  
  public get getPostalCodeInfo() {
    const getPostalCodeInfo = this.$apiClient.$makeCaller(
      this.$metadata.methods.getPostalCodeInfo,
      (c, postalCode: string | null) => c.getPostalCodeInfo(postalCode),
      () => ({postalCode: null as string | null, }),
      (c, args) => c.getPostalCodeInfo(args.postalCode))
    
    Object.defineProperty(this, 'getPostalCodeInfo', {value: getPostalCodeInfo});
    return getPostalCodeInfo
  }
  
  /** Once got triggered the ticket purchase will be cancelled and resulted as spam */
  public get markTicketPurchaseHoneyPotSpam() {
    const markTicketPurchaseHoneyPotSpam = this.$apiClient.$makeCaller(
      this.$metadata.methods.markTicketPurchaseHoneyPotSpam,
      (c, publicId: string | null) => c.markTicketPurchaseHoneyPotSpam(publicId),
      () => ({publicId: null as string | null, }),
      (c, args) => c.markTicketPurchaseHoneyPotSpam(args.publicId))
    
    Object.defineProperty(this, 'markTicketPurchaseHoneyPotSpam', {value: markTicketPurchaseHoneyPotSpam});
    return markTicketPurchaseHoneyPotSpam
  }
  
  constructor() {
    super($metadata.PublicPurchaseService, new $apiClients.PublicPurchaseServiceApiClient())
  }
}


export class SignInServiceViewModel extends ServiceViewModel<typeof $metadata.SignInService, $apiClients.SignInServiceApiClient> {
  
  public get signIn() {
    const signIn = this.$apiClient.$makeCaller(
      this.$metadata.methods.signIn,
      (c, userName: string | null, password: string | null, rememberMe?: boolean | null) => c.signIn(userName, password, rememberMe),
      () => ({userName: null as string | null, password: null as string | null, rememberMe: null as boolean | null, }),
      (c, args) => c.signIn(args.userName, args.password, args.rememberMe))
    
    Object.defineProperty(this, 'signIn', {value: signIn});
    return signIn
  }
  
  public get requestPasswordReset() {
    const requestPasswordReset = this.$apiClient.$makeCaller(
      this.$metadata.methods.requestPasswordReset,
      (c, userName: string | null) => c.requestPasswordReset(userName),
      () => ({userName: null as string | null, }),
      (c, args) => c.requestPasswordReset(args.userName))
    
    Object.defineProperty(this, 'requestPasswordReset', {value: requestPasswordReset});
    return requestPasswordReset
  }
  
  public get resetPassword() {
    const resetPassword = this.$apiClient.$makeCaller(
      this.$metadata.methods.resetPassword,
      (c, userName: string | null, code: string | null, newPassword: string | null, confirmNewPassword: string | null) => c.resetPassword(userName, code, newPassword, confirmNewPassword),
      () => ({userName: null as string | null, code: null as string | null, newPassword: null as string | null, confirmNewPassword: null as string | null, }),
      (c, args) => c.resetPassword(args.userName, args.code, args.newPassword, args.confirmNewPassword))
    
    Object.defineProperty(this, 'resetPassword', {value: resetPassword});
    return resetPassword
  }
  
  public get setupAccount() {
    const setupAccount = this.$apiClient.$makeCaller(
      this.$metadata.methods.setupAccount,
      (c, userName: string | null, code: string | null, newPassword: string | null, confirmNewPassword: string | null) => c.setupAccount(userName, code, newPassword, confirmNewPassword),
      () => ({userName: null as string | null, code: null as string | null, newPassword: null as string | null, confirmNewPassword: null as string | null, }),
      (c, args) => c.setupAccount(args.userName, args.code, args.newPassword, args.confirmNewPassword))
    
    Object.defineProperty(this, 'setupAccount', {value: setupAccount});
    return setupAccount
  }
  
  public get confirmEmail() {
    const confirmEmail = this.$apiClient.$makeCaller(
      this.$metadata.methods.confirmEmail,
      (c, userId: string | null, code: string | null, newEmail?: string | null) => c.confirmEmail(userId, code, newEmail),
      () => ({userId: null as string | null, code: null as string | null, newEmail: null as string | null, }),
      (c, args) => c.confirmEmail(args.userId, args.code, args.newEmail))
    
    Object.defineProperty(this, 'confirmEmail', {value: confirmEmail});
    return confirmEmail
  }
  
  constructor() {
    super($metadata.SignInService, new $apiClients.SignInServiceApiClient())
  }
}


const viewModelTypeLookup = ViewModel.typeLookup = {
  EventDateDto: EventDateDtoViewModel,
  EventDto: EventDtoViewModel,
  EventTypeDto: EventTypeDtoViewModel,
  TicketPurchaseDto: TicketPurchaseDtoViewModel,
}
const listViewModelTypeLookup = ListViewModel.typeLookup = {
  EventDateDto: EventDateDtoListViewModel,
  EventDto: EventDtoListViewModel,
  EventTypeDto: EventTypeDtoListViewModel,
  TicketPurchaseDto: TicketPurchaseDtoListViewModel,
}
const serviceViewModelTypeLookup = ServiceViewModel.typeLookup = {
  PublicPurchaseService: PublicPurchaseServiceViewModel,
  SignInService: SignInServiceViewModel,
}

