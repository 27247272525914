import { AppRoles } from "../../app-private/consts";

declare const STATIC_DATA: {
  readonly APPINSIGHTS_INSTRUMENTATIONKEY: string;
  readonly RECAPTCHA_SITE_KEY: string | undefined;
  readonly APPINSIGHTS_CONNECTION: string;

  readonly USERNAME: string | null;
  readonly userId: string | null;
  readonly userRoles: AppRoles[];
  readonly isPublicUser: boolean;

  readonly APP_NAME: string;
  readonly APP_TID: number;
  readonly BANK_STATEMENT_NOTIFICATION_ENABLED: string;
  readonly APP_SUPPORT_EMAIL: string;
  readonly APP_SUPPORT_PHONE: string;
  readonly MARKETING_ENABLED: boolean;
  readonly TEXT_MESSAGING_ENABLED: boolean;
  readonly SHOW_REMAINING_SEATS: string;
  readonly HAS_CUSTOM_BUTTON_TEXT: boolean;
  readonly SHOW_STATUS_COLUMN_ON_LIST_VIEW: boolean;
  readonly SHOW_IMAGE_ON_LIST_VIEW: boolean;
  readonly META_PIXEL_ENABLED: boolean;

  readonly COLOR_PRIMARY: string;
  readonly COLOR_SECONDARY: string;
  readonly COLOR_ACCENT: string;
};

export function isInAnyRole(r: AppRoles | AppRoles[]): boolean {
  return Array.isArray(r)
    ? STATIC_DATA.userRoles.some((x) => r.includes(x))
    : STATIC_DATA.userRoles.includes(r);
}

const canAccessAdminArea = (() => {
  const roles = [AppRoles.Admin, AppRoles.EventManager];
  return roles.some((r) => isInAnyRole(r));
})();

const canAccessAgentArea = (() => {
  const roles = [AppRoles.Agent, AppRoles.Admin, AppRoles.EventManager];
  return roles.some((r) => isInAnyRole(r));
})();

const isConsignee = isInAnyRole(AppRoles.Consignee);

export default function useStaticData() {
  return {
    ...STATIC_DATA,
    isInAnyRole: isInAnyRole,
    canAccessAdminArea,
    canAccessAgentArea,
    isConsignee,

    // These asset URLs are to prevent Vue from seeing them and turning them into
    // JS `import` statements, which bricks the app when these assets 404.
    faviconSrc: "/assets/favicon",
    fullLogoSrc: "/assets/fulllogo",
  };
}
