// No unused vars disabled here for override matching
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AvailableSeatsDetails,
  EventDateSeatingInfo,
  EventDateSeatingRestrictionInfo,
  SeatMapAnnotationDto,
  SeatMapDto,
  SeatMapSeatDto,
  SeatMapSectionDto,
  SectionTypes,
} from '@/models.g';
import {
  SeatMapSeatDto as $seatMeta,
  SeatMapAnnotationDto as $annotationMeta,
} from "@/metadata.g";
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { cartQuantityChanged } from "@common/analytics";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { GeneralAdmissionSeatNumber } from "../app-private/consts";

export type Drawable = SeatMapSeatDto | SeatMapAnnotationDto;

export function isSeat(object: Drawable | null): object is SeatMapSeatDto {
  return object?.$metadata == $seatMeta;
}

export function isAnnotation(
  object: Drawable | null,
): object is SeatMapAnnotationDto {
  return object?.$metadata == $annotationMeta;
}

export class SeatMapContext {
  constructor(
    public seatMap: SeatMapDto,
    public nextTick: (x: () => void) => void,
  ) {
    const mouseUp = (event: MouseEvent) => this.onGlobalMouseUp(event);
    document.addEventListener("mouseup", mouseUp);

    const mouseMove = (event: MouseEvent) => this._onGlobalMouseMove(event);
    document.addEventListener("mousemove", mouseMove);

    const keyDown = (event: KeyboardEvent) => this.onGlobalKeyDown(event);
    document.addEventListener("keydown", keyDown, { capture: true });

    const keyUp = (event: KeyboardEvent) => this.onGlobalKeyUp(event);
    document.addEventListener("keyup", keyUp, { capture: true });

    this._destroyFunctions.push(() => {
      document.removeEventListener("mouseup", mouseUp);
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("keydown", keyDown);
      document.removeEventListener("keyup", keyUp);
    });
  }

  protected _destroyFunctions: (() => void)[] = [];

  public onDestroy = () => this._destroyFunctions.forEach((x) => x());

  /** Size in pixels of a seat icon */
  readonly seatSize = 30;

  /** Amount of extra empty space to render on the right and bottom edges. */
  bottomRightBuffer = 500;

  private _zoom = 1;
  public get zoom() {
    return this._zoom;
  }

  public set zoom(value) {
    this._zoom = +Math.min(this.zoomMax, Math.max(this.zoomMin, value)).toFixed(
      4,
    );
  }

  zoomMin = 0.01;
  zoomMax = 3;

  get hideSeatText() {
    return this.zoom < 0.6;
  }

  showGrid = false;

  /** True if a drag has been processed in the current mouseDown action. */
  hasDragged = false;

  currentPinchZoom: null | {
    startDist: number;
    startZoom: number;
    oldXCoord: number;
    oldYCoord: number;
    scrollContainer: HTMLElement;
  } = null;

  currentPanAction: {
    /** The X coordinate of the origin. */
    x: number;
    /** The Y coordinate of the origin. */
    y: number;
    el: HTMLElement;
    distance: number;
  } | null = null;

  private _selectedObjects = reactive(new Set<Drawable>());
  private _selectedObject: Drawable | null = null;

  public get selectedObjects() {
    return this._selectedObjects;
  }

  // Add a getter for the size
  public get selectedObjectsSize() {
    return computed(() => this._selectedObjects.size);
  }

  // Update selectedObjects setter to trigger reactivity.
  public set selectedObjects(value: Set<Drawable>) {
    this._selectedObjects.clear();
    value.forEach((item) => this._selectedObjects.add(item));
    this._selectedObject =
      value.size == 1 ? value.values().next().value! : null;
  }

  public get selectedObject() {
    return this._selectedObject;
  }

  get drawingTransform() {
    return "scale(" + this.zoom + ") ";
  }

  get allSeats() {
    return this.seatMap.sections!.reduce(
      (p, c) => [...p, ...c.seats!],
      <SeatMapSeatDto[]>[],
    );
  }

  /** Details about scroll position used when handling mouse moves. */
  pointerEventOrigin: {
    /** An element that should be watched for scroll changes during drag events so that the position deltas can compensate for scroll changes. */
    scrollEl: HTMLElement;
    scrollX: number;
    scrollY: number;
  } | null = null;

  /** The selected seat if exactly one object is selected and that object is a seat. Otherwise null. */
  get selectedSeat() {
    return isSeat(this.selectedObject) ? this.selectedObject : null;
  }

  get selectedSeats() {
    return [...this.selectedObjects].filter(isSeat);
  }

  /** The selected annotation if exactly one object is selected and that object is a annotation. Otherwise null. */
  get selectedAnnotation() {
    return isAnnotation(this.selectedObject) ? this.selectedObject : null;
  }

  startPointerEvent(event: MouseEvent) {
    const scrollEl = (event.target as HTMLElement).closest<HTMLElement>(
      ".seat-map-scroll-frame",
    )!;
    this.pointerEventOrigin = {
      scrollEl,
      scrollX: scrollEl.scrollLeft,
      scrollY: scrollEl.scrollTop,
    };
  }

  onDrawingMouseDown(event: MouseEvent) {
    if (event.button != 0) return;
    this.panBegin(event);
  }

  _lastMouseMove?: MouseEvent;

  _onGlobalMouseMove(event: MouseEvent) {
    let deltaX = 0;
    let deltaY = 0;

    if (this._lastMouseMove) {
      // event.movementX and event.movementY cannot be used,
      // as they do not properly factor in the browser's zoom level.
      // `window.devicePixelRatio` cannot be used for this because it
      // also factors in OS display scaling, which IS already factored into
      // the `.movement*` event props.
      deltaX = event.clientX - this._lastMouseMove.clientX;
      deltaY = event.clientY - this._lastMouseMove.clientY;
    }

    this._lastMouseMove = event;

    if (this.pointerEventOrigin) {
      // Check if the scroll frame has shifted position since the last update,
      // e.g. via auto scrolling when near the edge (firefox default behavior),
      // or by a simultaneous middle-click-and-drag.
      // Add any scroll deltas to the overall movement delta.
      const origin = this.pointerEventOrigin;
      const { scrollLeft, scrollTop } = origin.scrollEl;

      deltaX -= origin.scrollX - scrollLeft;
      origin.scrollX = scrollLeft;

      deltaY -= origin.scrollY - scrollTop;
      origin.scrollY = scrollTop;
    }

    // The "true" movement deltas, measured in units comparable to
    // the positions and sizes of seats and annotations.
    deltaX /= this.zoom;
    deltaY /= this.zoom;

    this.onGlobalMouseMove(event, deltaX, deltaY);
  }

  onGlobalMouseMove(event: MouseEvent, deltaX: number, deltaY: number) {
    this.panMove(event);
  }

  onGlobalMouseUp(event: MouseEvent) {
    this.panEnd();
  }

  onObjectClick(event: MouseEvent, object: Drawable) {}

  onObjectMouseDown(event: MouseEvent, object: Drawable) {}

  onGlobalKeyDown(event: KeyboardEvent) {}

  onGlobalKeyUp(event: KeyboardEvent) {}

  getSeatClasses(seat: SeatMapSeatDto, section: SeatMapSectionDto): {} {
    return {
      active: this.selectedObjects.has(seat),
      gaa: section.type === SectionTypes.GeneralAdmission,
    };
  }

  onTouchStart(e: TouchEvent) {
    if (e.touches.length === 2) {
      // Initiate Pinch-to-zoom
      const scrollContainer = e.currentTarget as HTMLElement;

      const xMidpoint = (e.touches[0].pageX + e.touches[1].pageX) / 2;
      const yMidpoint = (e.touches[0].pageY + e.touches[1].pageY) / 2;
      const oldZoom = this.zoom;

      this.currentPinchZoom = {
        startDist: Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY,
        ),
        // old*Coord is the diagram-relative (comparable to seat/annotation x/y values)
        // position of the midpoint of the pinch gesture.
        oldXCoord:
          (scrollContainer.scrollLeft +
            xMidpoint -
            scrollContainer.getBoundingClientRect().left) /
          oldZoom,
        oldYCoord:
          (scrollContainer.scrollTop +
            yMidpoint -
            (scrollContainer.getBoundingClientRect().top + window.scrollY)) /
          oldZoom,
        startZoom: oldZoom,
        scrollContainer: scrollContainer,
      };
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onTouchMove(e: TouchEvent) {
    if (this.currentPinchZoom) {
      // Handle pinch-to-zoom adjustment
      e.preventDefault();
      e.stopPropagation();
      const dist = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY,
      );
      const xMidpoint = (e.touches[0].pageX + e.touches[1].pageX) / 2;
      const yMidpoint = (e.touches[0].pageY + e.touches[1].pageY) / 2;

      const oldZoom = this.zoom;
      this.zoom =
        this.currentPinchZoom.startZoom *
        (dist / this.currentPinchZoom.startDist);

      this.adjustScrollAfterZoom(
        this.currentPinchZoom.scrollContainer,
        xMidpoint,
        yMidpoint,
        oldZoom,
      );
    }
  }

  onTouchEnd(event: TouchEvent) {
    // Terminate pinch-to-zoom
    this.currentPinchZoom = null;
  }

  onMouseWheel(event: WheelEvent) {
    if (event.ctrlKey) {
      // Handle ctrl+mousewheel zooms.
      event.stopPropagation();
      event.preventDefault();

      const scrollContainer = event.currentTarget as HTMLElement;
      const oldZoom = this.zoom;

      // This weird math makes it so that a zoom in followed by a zoom out
      // of the same deltaY magnitude returns us to the exact original zoom.
      // Essentially you can think of `this.zoom` as the output
      // of a function `f(x) = base^x`.
      // We take the `log_base` of our zoom to give us the `x` value,
      // then we take a step along the `x` axis scaled in proportion to the wheel movement,
      // then we reapply the exponent.
      // This is exponential because the more zoomed in you are, the bigger the changes in
      // zoom need to be. When you're at 6x zoom, doubling that is 12x zoom.

      // `base` and `xStepMult` chosen through trial and error.
      // A base of 2 and a mult of 1 would double/half the zoom
      // for each typical scrollwheel click, for example.
      const base = 2;
      const xStepMult = 0.5;
      this.zoom = Math.pow(
        base,
        Math.log(this.zoom) / Math.log(base) -
          (xStepMult * event.deltaY) /
            100 /* Typical zoom step of a stepped (non-smooth) mousewheel is 100)*/,
      );

      this.adjustScrollAfterZoom(
        scrollContainer,
        event.pageX,
        event.pageY,
        oldZoom,
      );
    }
  }

  onDoubleClick(event: MouseEvent) {
    if (event.button != 0) return;
    event.preventDefault();
    event.stopPropagation();

    const scrollContainer = event.currentTarget as HTMLElement;
    const oldZoom = this.zoom;
    this.zoom *= 2;

    this.adjustScrollAfterZoom(
      scrollContainer,
      event.pageX,
      event.pageY,
      oldZoom,
    );
  }

  /** Adjust the scrolling of the target element so that the content located
   * at the provided page-relative coordinates remains at the same location on screen
   * after the zoom of the scroll container was adjusted. */
  private adjustScrollAfterZoom(
    scrollContainer: HTMLElement,
    pageX: number,
    pageY: number,
    oldZoom: number,
  ) {
    const newZoom = this.zoom;

    if (oldZoom == newZoom) {
      // If the zoom was clamped by min/max, don't adjust the scroll position.
      return;
    }

    const { x: oldXCoord, y: oldYCoord } = getRelativeCoordinates(
      pageX,
      pageY,
      scrollContainer,
    );

    const newXCoord = (oldXCoord / oldZoom) * newZoom;
    const newYCoord = (oldYCoord / oldZoom) * newZoom;

    // Wait for vue to render the new zoom into the DOM.
    // If we don't wait, and we're zooming in, we might not be able
    // to scroll as far as we need to since the scroll element's
    // content area won't have its larger size yet.
    const oldLeft = scrollContainer.scrollLeft;
    const oldTop = scrollContainer.scrollTop;
    this.nextTick(() => {
      // scrollLeft/scrollTop normally *truncate* to an integer
      // when they're provided with a non-integar number.
      // However, we want to *round* to the nearest integer.
      // Otherwise, when pinch-zooming, the map will slowly slide towards the bottom right
      // (i.e. scrolling up and left), even when fingers are held stationary.
      scrollContainer.scrollLeft = Math.round(oldLeft + newXCoord - oldXCoord);
      scrollContainer.scrollTop = Math.round(oldTop + newYCoord - oldYCoord);
    });
  }

  zoomButton(event: MouseEvent, mult: number) {
    const oldZoom = this.zoom;
    this.zoom *= mult;

    const scrollFrame = (event.target as HTMLElement)
      .closest<HTMLElement>(".seat-map-container")!
      .querySelector<HTMLElement>(".seat-map-scroll-frame")!;

    const boundingRect = scrollFrame.getBoundingClientRect();
    this.adjustScrollAfterZoom(
      scrollFrame,
      boundingRect.left + scrollFrame.clientWidth / 2 + window.scrollX,
      boundingRect.top + scrollFrame.clientHeight / 2 + window.scrollY,
      oldZoom,
    );
  }

  zoomOutButton(event: MouseEvent) {
    this.zoom /= 2;
  }

  panBegin(event: MouseEvent) {
    const scrollFrame = (event.target as HTMLElement).closest<HTMLElement>(
      ".seat-map-scroll-frame",
    )!;

    this.currentPanAction = {
      x: event.pageX,
      y: event.pageY,
      el: scrollFrame,
      distance: 0,
    };
    event.preventDefault();
  }

  panMove(event: MouseEvent) {
    if (this.currentPanAction) {
      const deltaX = event.movementX;
      const deltaY = event.movementY;

      event.preventDefault();

      this.currentPanAction.distance = Math.sqrt(
        Math.pow(this.currentPanAction.x - event.pageX, 2) +
          Math.pow(this.currentPanAction.y - event.pageY, 2),
      );
      this.currentPanAction.el.scrollLeft -= deltaX;
      this.currentPanAction.el.scrollTop -= deltaY;

      if ((this.currentPanAction?.distance ?? 0) > 30) {
        // If the user was panning, don't handle as a click if they went beyond some amount.
        this.hasDragged = true;
      }

      return true;
    }
    return false;
  }

  panEnd() {
    const wasPanning = !!this.currentPanAction;
    this.currentPanAction = null;
    if (this.hasDragged) {
      setTimeout(() => (this.hasDragged = false), 100);
    }
    return wasPanning;
  }

  getSectionForSeat(seat: SeatMapSeatDto) {
    return this.seatMap.sections?.find((s) => s.seats?.includes(seat)) ?? null;
  }
}

/**
 * Given a pair of page-relative coordinates,
 * compute the coordinates relative to the top left corner of `referenceElement`.
 * Modified from https://stackoverflow.com/a/36860652.
 */
function getRelativeCoordinates(
  pageX: number,
  pageY: number,
  referenceElement: HTMLElement | null,
) {
  const offset = {
    left: 0,
    top: 0,
  };

  let reference = referenceElement;

  while (reference) {
    offset.left +=
      reference.offsetLeft -
      reference.scrollLeft +
      parseInt(getComputedStyle(reference).paddingLeft);
    offset.top +=
      reference.offsetTop -
      reference.scrollTop +
      parseInt(getComputedStyle(reference).paddingTop);
    reference = reference.offsetParent as HTMLElement | null;
  }

  return {
    x: pageX - offset.left,
    y: pageY - offset.top,
  };
}

export class EventPreviewSeatMapContext extends SeatMapContext {
  constructor(
    seatMap: SeatMapDto,
    protected setPriceSelect: (value: {
      seat: SeatMapSeatDto;
      section: SeatMapSectionDto;
    }) => void,
    nextTick: (x: () => void) => void,
  ) {
    super(seatMap, nextTick);
  }

  override getSeatClasses(seat: SeatMapSeatDto, section: SeatMapSectionDto) {
    return {
      active: true,
    };
  }

  override onObjectClick(event: MouseEvent, obj: Drawable) {
    if (this.hasDragged || !isSeat(obj)) {
      return;
    }

    const section = this.getSectionForSeat(obj)!;
    this.setPriceSelect({ seat: obj, section: section });
  }
}

export class PurchaseSeatMapContext extends SeatMapContext {
  originalPurchase: TicketPurchaseDtoViewModel | null = null;
  seatsBeingExchanged: Set<string> = new Set();

  constructor(
    private info: EventDateSeatingInfo,
    protected purchase: TicketPurchaseDtoViewModel,
    protected service: PublicPurchaseServiceViewModel,
    protected setPriceSelect: (value: {
      seat: SeatMapSeatDto;
      section: SeatMapSectionDto;
      restrictions: EventDateSeatingRestrictionInfo[] | undefined;
      availableAmount: number | undefined;
    }) => void,
    nextTick: (x: () => void) => void,
  ) {
    super(info.seatMap!, nextTick);

    if (this.purchase.originalPurchase) {
      this.originalPurchase = new TicketPurchaseDtoViewModel();
      this.originalPurchase
        .$load(this.purchase.originalPurchase!.publicId!)
        .then(() => {
          this.seatsBeingExchanged = new Set();
          if (
            this.originalPurchase?.eventDateId == this.purchase.eventDateId!
          ) {
            // The tickets from the original purchase that are being exchanged.
            // These seats are also available for selection
            // (e.g. exchanging an adult ticket for a child ticket, for the same seat)
            const pendingExchangeTickets =
              this.originalPurchase.tickets?.filter((t) =>
                this.purchase.originalPurchase?.ticketIds?.includes(
                  t.ticketId!,
                ),
              )!;

            for (const pendingExchangeTicket of pendingExchangeTickets) {
              if (pendingExchangeTicket.seatNumber)
                this.seatsBeingExchanged.add(pendingExchangeTicket.seatNumber);
            }
          }
        });
    }
    this._destroyFunctions.push(() => this.stopListening());
    this.startListening();
  }

  get availableSeats() {
    const seats = new Set([
      ...this.info.availableSeats!,
      ...this.seatsBeingExchanged,
    ]);

    const purchase = this.purchase;
    for (const restriction of this.info.restrictions || []) {
      // Per #813 - agent-only seats for public purchases look exactly like sold seats.
      if (restriction.agentOnly && purchase.isPublic) {
        for (const seat of restriction.seats!) seats.delete(seat);
      }
    }

    return seats;
  }

  override getSeatClasses(seat: SeatMapSeatDto, section: SeatMapSectionDto) {
    const availableSeats = this.availableSeats;
    return this.selectedObjects.has(seat) &&
      section.type != SectionTypes.GeneralAdmission
      ? {
          // The seat is chosen by the current purchase.
          checked: true,
        }
      : {
          taken: !availableSeats.has(seat.seatNumber!),
          active: availableSeats.has(seat.seatNumber!),

          // The seat is one that belongs to the current purchase being exchanged.
          // Distinguish the old seats so the agent can easily find them if the exchange
          // is for the same seats.
          "original-seat": this.seatsBeingExchanged.has(seat.seatNumber!),
        };
  }

  // Click on a seat
  override onObjectClick(event: MouseEvent, obj: Drawable) {
    if (this.hasDragged || !isSeat(obj)) {
      return;
    }
    if (this.selectedObjects.has(obj)) {
      const ticket = this.purchase.tickets?.find(
        (t) => t.seatNumber == obj.seatNumber,
      );
      if (!ticket) return;

      if (!ticket.seatNumber?.includes(GeneralAdmissionSeatNumber.Prefix)) {
        this.service
          .removeTicket(this.purchase.publicId, ticket.ticketId)
          .then(() => {
            this.availableSeats.add(obj.seatNumber!);

            // Send analytics events:
            const price = this.purchase.eventDate?.eventPrices?.find(
              (p) => p.id == ticket.eventPriceId,
            );
            if (price) {
              cartQuantityChanged(this.purchase, price, -1);
            }
          });
        return;
      }
    } else if (!this.availableSeats.has(obj.seatNumber!)) {
      return;
    }

    // Load the seat into the bottom sheet popup.
    const section = this.getSectionForSeat(obj)!;
    this.setPriceSelect({
      seat: obj,
      section: section,
      restrictions: this.info.restrictions?.filter((r) =>
        r.seats?.includes(obj.seatNumber!),
      ),
      availableAmount: this.info.availableSeatsDetails?.filter(asd => asd.seatNumber === obj.seatNumber)[0].amountOfSeats || 0
    });

    // Clear service error state that may be lingering from a previous attempt to pick a seat:
    this.service.addTicket.wasSuccessful = null;
    this.service.addTicket.message = null;
    this.service.addMultipleTickets.wasSuccessful = null;
    this.service.addMultipleTickets.message = null;
    this.service.removeTicket.wasSuccessful = null;
    this.service.removeTicket.message = null;
  }

  _connection: HubConnection | null = null;

  private async startListening() {
    if (this._connection != null) return;

    this._connection = new HubConnectionBuilder()
      .withUrl("/hubs/seating", {
        // Configured to avoid issues with load balancing:
        // https://docs.microsoft.com/en-us/aspnet/core/signalr/scale?view=aspnetcore-6.0#sticky-sessions
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    await this._connection.start();
    await this._connection.send(
      "SubscribeToSeating",
      this.purchase.eventDateId,
    );
    this._connection.on(
      "SeatAvailabilityChanged",
      (data: { eventDateId: number; availableSeats: string[], availableSeatDetail: AvailableSeatsDetails[] }) => {
        if (data.eventDateId == this.info.eventDateId) {
          this.info.availableSeats = data.availableSeats;
          this.info.availableSeatsDetails = data.availableSeatDetail;
        }
      },
    );
  }

  private stopListening() {
    this._connection?.stop();
    this._connection = null;
  }
}
