import * as metadata from './metadata.g'
import { Model, DataSource, convertToModel, mapToModel, reactiveDataSource } from 'coalesce-vue/lib/model'

export enum AddonItemType {
  
  /** Items must be refunded/exchanged individually. */
  FixedPerAddon = 0,
  
  /** When admission tickets are refunded/exchanged, items of this type are too. */
  FixedPerTicket = 1,
  
  /** When admission tickets are refunded/exchanged, items of this type are too. */
  PercentPerTicket = 2,
}


export enum AddonStatus {
  Completed = 0,
  Exchanged = 2,
  Refunded = 3,
}


export enum BoardingLabel {
  Doors = 0,
  Boarding = 1,
  Arrive = 2,
}


export enum DiscountType {
  PerPurchaseFlatRateDiscount = 0,
  PerPurchasePercentageDiscount = 1,
  PerTicketFlatRateDiscount = 2,
  PerTicketPercentageDiscount = 3,
}


export enum EmailType {
  ImmediatelyAfterPurchase = 0,
  WithinOneDay = 1,
  ThreeHoursAfterTheEvent = 2,
  OneHourAfterTheEvent = 3,
  Confirmation = 100,
  Cancellation = 101,
}


export enum PaymentKind {
  
  /** 
    Maps to existing TPH discriminator column from old database. 
    Do not change without adding a migration to change the column value.
  */
  Payment = 0,
  
  /** 
    Maps to existing TPH discriminator column from old database. 
    Do not change without adding a migration to change the column value.
  */
  CreditCardPayment = 1,
}


export enum PurchaseStatus {
  Started = 0,
  Completed = 1,
  Canceled = 2,
  Expired = 3,
}


export enum PurchaseType {
  Purchase = 0,
  Exchange = 1,
  Refund = 2,
}


export enum RefundableState {
  
  /** Premium refunds option was not available as a choice for this purchase. */
  NotOffered = 0,
  
  /** Premium refund option was purchased. */
  Purchased = 1,
  
  /** Premium refund option was offered, but declined. */
  Declined = 2,
}


export enum SeatMapAnnotationShape {
  Rectangle = 0,
  RoundedRectangle = 1,
  Oval = 2,
  
  /** No border */
  None = 3,
}


export enum SectionTypes {
  IndividualSeat = 0,
  GeneralAdmission = 1,
}


export enum TicketStatus {
  Completed = 0,
  Hold = 1,
  Exchanged = 2,
  Refunded = 3,
}


export interface EventDateDto extends Model<typeof metadata.EventDateDto> {
  id: number | null
  eventStartDateTime: Date | null
  eventEndDateTime: Date | null
  eventPublicPurchaseDatetime: Date | null
  
  /** The start of the event, represented in the local time of the event. */
  eventStartDateTimeLocal: Date | null
  
  /** The end of the event, represented in the local time of the event. */
  eventEndDateTimeLocal: Date | null
  venueTimezone: string | null
  durationMinutes: number | null
  eventCapacity: number | null
  cancelled: boolean | null
  eventId: number | null
  eventName: string | null
  eventShortDescription: string | null
  eventLongDescription: string | null
  eventLocation: string | null
  eventBoardingLabel: string | null
  eventBoardingMinutes: number | null
  hasFood: boolean | null
  availableSeats: number | null
  customMoreOptionsButtonText: string | null
  customMoreOptionsIcon: string | null
  customMoreOptionsUrl: string | null
  customSelectTicketsUrl: string | null
  customSelectTicketsButtonText: string | null
  customSelectTicketsIcon: string | null
  
  /** Indicates if the event date has a seat map. */
  isSeated: boolean | null
  soldOut: boolean | null
  eventCustomSoldOutText: string | null
  isEventActive: boolean | null
  isFreeEvent: boolean | null
  hiddenFromInitialPurchase: boolean | null
  
  /** 
    A rollup of the various status flags of the event.
    Does not specifically indicate sellability.
  */
  statusDescription: string | null
  
  /** 
    If non-null, contains the reason that a purchase of the
    event cannot be initiated by the current user.
  */
  unpurchasableReason: string | null
  eventPrices: EventPriceDto[] | null
  eventBannerImage: string | null
  eventImages: EventImage[] | null
}
export class EventDateDto {
  
  /** Mutates the input object and its descendents into a valid EventDateDto implementation. */
  static convert(data?: Partial<EventDateDto>): EventDateDto {
    return convertToModel(data || {}, metadata.EventDateDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDateDto implementation. */
  static map(data?: Partial<EventDateDto>): EventDateDto {
    return mapToModel(data || {}, metadata.EventDateDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDateDto; }
  
  /** Instantiate a new EventDateDto, optionally basing it on the given data. */
  constructor(data?: Partial<EventDateDto> | {[k: string]: any}) {
    Object.assign(this, EventDateDto.map(data || {}));
  }
}
export namespace EventDateDto {
  export namespace DataSources {
    
    export class CalendarSource implements DataSource<typeof metadata.EventDateDto.dataSources.calendarSource> {
      readonly $metadata = metadata.EventDateDto.dataSources.calendarSource
      year: number | null = null
      month: number | null = null
      includeForWeb: boolean | null = null
      includeWebUnlisted: boolean | null = null
      includeForPos: boolean | null = null
      includeSoldOut: boolean | null = null
      includeInactive: boolean | null = null
      includeCancelled: boolean | null = null
      minDate: Date | null = null
      onlyPast: boolean | null = null
      eventTypeId: number | null = null
      
      constructor(params?: Omit<Partial<CalendarSource>, '$metadata'>) {
        if (params) Object.assign(this, params);
        return reactiveDataSource(this);
      }
    }
    
    export class DefaultSource implements DataSource<typeof metadata.EventDateDto.dataSources.defaultSource> {
      readonly $metadata = metadata.EventDateDto.dataSources.defaultSource
      includeForWeb: boolean | null = null
      includeWebUnlisted: boolean | null = null
      includeForPos: boolean | null = null
      includeSoldOut: boolean | null = null
      includeInactive: boolean | null = null
      includeCancelled: boolean | null = null
      minDate: Date | null = null
      onlyPast: boolean | null = null
      eventTypeId: number | null = null
      
      constructor(params?: Omit<Partial<DefaultSource>, '$metadata'>) {
        if (params) Object.assign(this, params);
        return reactiveDataSource(this);
      }
    }
  }
}


export interface EventDto extends Model<typeof metadata.EventDto> {
  id: number | null
  description: string | null
}
export class EventDto {
  
  /** Mutates the input object and its descendents into a valid EventDto implementation. */
  static convert(data?: Partial<EventDto>): EventDto {
    return convertToModel(data || {}, metadata.EventDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDto implementation. */
  static map(data?: Partial<EventDto>): EventDto {
    return mapToModel(data || {}, metadata.EventDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDto; }
  
  /** Instantiate a new EventDto, optionally basing it on the given data. */
  constructor(data?: Partial<EventDto> | {[k: string]: any}) {
    Object.assign(this, EventDto.map(data || {}));
  }
}
export namespace EventDto {
  export namespace DataSources {
    
    export class DefaultSource implements DataSource<typeof metadata.EventDto.dataSources.defaultSource> {
      readonly $metadata = metadata.EventDto.dataSources.defaultSource
    }
  }
}


export interface EventTypeDto extends Model<typeof metadata.EventTypeDto> {
  id: number | null
  description: string | null
}
export class EventTypeDto {
  
  /** Mutates the input object and its descendents into a valid EventTypeDto implementation. */
  static convert(data?: Partial<EventTypeDto>): EventTypeDto {
    return convertToModel(data || {}, metadata.EventTypeDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventTypeDto implementation. */
  static map(data?: Partial<EventTypeDto>): EventTypeDto {
    return mapToModel(data || {}, metadata.EventTypeDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventTypeDto; }
  
  /** Instantiate a new EventTypeDto, optionally basing it on the given data. */
  constructor(data?: Partial<EventTypeDto> | {[k: string]: any}) {
    Object.assign(this, EventTypeDto.map(data || {}));
  }
}
export namespace EventTypeDto {
  export namespace DataSources {
    
    export class DefaultSource implements DataSource<typeof metadata.EventTypeDto.dataSources.defaultSource> {
      readonly $metadata = metadata.EventTypeDto.dataSources.defaultSource
    }
  }
}


export interface TicketPurchaseDto extends Model<typeof metadata.TicketPurchaseDto> {
  
  /** 
    A non-sequential identifier for the purchase.
    NOT the TicketPurchase.Id.
  */
  publicId: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  marketingOptOut: boolean | null
  refundable: RefundableState | null
  isAllowTextMessaging: boolean | null
  tenantTextMessagingEnabled: boolean | null
  addressStreet: string | null
  addressCity: string | null
  addressState: string | null
  addressZip: string | null
  addressCountry: string | null
  roomNumber: string | null
  willCall: boolean | null
  
  /** 
    Whether the Service Fee is waived for this purchase. Does not affect the Convenience Fee.
    
    Related work:
    https://hagadone.visualstudio.com/Ticketing/_workitems/edit/1186
    https://hagadone.visualstudio.com/Ticketing/_workitems/edit/1193
  */
  waiveTicketFees: boolean | null
  canWaiveTicketFees: boolean | null
  creationDate: Date | null
  expirationDate: Date | null
  isPublic: boolean | null
  type: PurchaseType | null
  status: PurchaseStatus | null
  failureMessage: string | null
  refundComment: string | null
  confirmationCode: string | null
  refundPolicy: string | null
  
  /** 
    The actual amount that was charged for the premium refund option,
    or the amount that would/will be charged if the purchase is not yet complete.
  */
  refundableFee: number | null
  refundedRefundableFee: number | null
  refundableFeePercentage: number | null
  convenienceFee: number | null
  refundedConvenienceFee: number | null
  salesTax: number | null
  refundedSalesTax: number | null
  balance: number | null
  grandTotal: number | null
  eventDateId: number | null
  eventDate: EventDateDto | null
  venueDisclaimer: string | null
  tickets: TicketPurchaseTicket[] | null
  addons: TicketPurchaseAddon[] | null
  discounts: TicketPurchaseDiscount[] | null
  payments: TicketPurchasePayment[] | null
  dietaryRestrictions: TicketPurchaseDietaryRestriction[] | null
  availableAddons: EventAddonDto[] | null
  eventSuggestedEvents: EventSuggestedEvent[] | null
  originalPurchase: TicketPurchaseRelatedPurchase | null
}
export class TicketPurchaseDto {
  
  /** Mutates the input object and its descendents into a valid TicketPurchaseDto implementation. */
  static convert(data?: Partial<TicketPurchaseDto>): TicketPurchaseDto {
    return convertToModel(data || {}, metadata.TicketPurchaseDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchaseDto implementation. */
  static map(data?: Partial<TicketPurchaseDto>): TicketPurchaseDto {
    return mapToModel(data || {}, metadata.TicketPurchaseDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchaseDto; }
  
  /** Instantiate a new TicketPurchaseDto, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchaseDto> | {[k: string]: any}) {
    Object.assign(this, TicketPurchaseDto.map(data || {}));
  }
}
export namespace TicketPurchaseDto {
  export namespace DataSources {
    
    export class DefaultSource implements DataSource<typeof metadata.TicketPurchaseDto.dataSources.defaultSource> {
      readonly $metadata = metadata.TicketPurchaseDto.dataSources.defaultSource
    }
  }
}


export interface AddonItemConfiguration extends Model<typeof metadata.AddonItemConfiguration> {
  id: number | null
  addonItemDefinitionId: number | null
  addonItemDefinition: AddonItemDefinition | null
  name: string | null
  marketingDescription: string | null
  
  /** 
    Items with quantities can have any number of that item per purchase.
    Items without a quantity can have zero or one item per purchase.
  */
  hasQuantity: boolean | null
  type: AddonItemType | null
  fixedAmount: number | null
  percentAmount: number | null
  
  /** 
    An optional ticket template for this item that will generate 
    additional printable tickets for each addon purchased.
  */
  ticketTemplateId: number | null
  ticketTemplate: TicketTemplate | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class AddonItemConfiguration {
  
  /** Mutates the input object and its descendents into a valid AddonItemConfiguration implementation. */
  static convert(data?: Partial<AddonItemConfiguration>): AddonItemConfiguration {
    return convertToModel(data || {}, metadata.AddonItemConfiguration) 
  }
  
  /** Maps the input object and its descendents to a new, valid AddonItemConfiguration implementation. */
  static map(data?: Partial<AddonItemConfiguration>): AddonItemConfiguration {
    return mapToModel(data || {}, metadata.AddonItemConfiguration) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AddonItemConfiguration; }
  
  /** Instantiate a new AddonItemConfiguration, optionally basing it on the given data. */
  constructor(data?: Partial<AddonItemConfiguration> | {[k: string]: any}) {
    Object.assign(this, AddonItemConfiguration.map(data || {}));
  }
}


export interface AddonItemDefinition extends Model<typeof metadata.AddonItemDefinition> {
  id: number | null
  
  /** An admin-facing name of the item. Customer-facing name is in Name. */
  name: string | null
  
  /** Any notes about this item for admins/event managers. E.g. info on when to use or not use this item. */
  adminNote: string | null
  
  /** The ID of the currently active configuration for this item. */
  currentConfigurationId: number | null
  currentConfiguration: AddonItemConfiguration | null
  
  /** The maximum number of these addons that can be sold for each day. */
  dailyMaximumQuantity: number | null
  showOnPatronManifest: boolean | null
  
  /** Github #112: the goal is to permanently hiding the unwanted data by default */
  isHidden: boolean | null
  
  /** An email to notify when an item of this type is purchased. */
  purchaseNotificationEmail: string | null
  eventAddonItems: EventAddonItemDefinition[] | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class AddonItemDefinition {
  
  /** Mutates the input object and its descendents into a valid AddonItemDefinition implementation. */
  static convert(data?: Partial<AddonItemDefinition>): AddonItemDefinition {
    return convertToModel(data || {}, metadata.AddonItemDefinition) 
  }
  
  /** Maps the input object and its descendents to a new, valid AddonItemDefinition implementation. */
  static map(data?: Partial<AddonItemDefinition>): AddonItemDefinition {
    return mapToModel(data || {}, metadata.AddonItemDefinition) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AddonItemDefinition; }
  
  /** Instantiate a new AddonItemDefinition, optionally basing it on the given data. */
  constructor(data?: Partial<AddonItemDefinition> | {[k: string]: any}) {
    Object.assign(this, AddonItemDefinition.map(data || {}));
  }
}


export interface AppRole extends Model<typeof metadata.AppRole> {
  name: string | null
  id: string | null
}
export class AppRole {
  
  /** Mutates the input object and its descendents into a valid AppRole implementation. */
  static convert(data?: Partial<AppRole>): AppRole {
    return convertToModel(data || {}, metadata.AppRole) 
  }
  
  /** Maps the input object and its descendents to a new, valid AppRole implementation. */
  static map(data?: Partial<AppRole>): AppRole {
    return mapToModel(data || {}, metadata.AppRole) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AppRole; }
  
  /** Instantiate a new AppRole, optionally basing it on the given data. */
  constructor(data?: Partial<AppRole> | {[k: string]: any}) {
    Object.assign(this, AppRole.map(data || {}));
  }
}


export interface AppUser extends Model<typeof metadata.AppUser> {
  
  /** Gets or sets the user name for this user. */
  userName: string | null
  firstName: string | null
  lastName: string | null
  receiveLowInventoryNotifications: boolean | null
  
  /** 
    Navigation property for the roles this user belongs to.
    USE WITH CAUTION - Bridges between tenant-specific (roles) and tenant-independent (users) data.
  */
  userRoles: AppUserRole[] | null
  
  /** Roles assigned to the user when they log into any tenant. Implicitly grants membership to all tenants. */
  appUserAllTenantRoles: AppUserAllTenantRole[] | null
  
  /** The names of the user's roles, for purposes of display on the user list admin page. */
  roleNames: string | null
  tenantMemberships: TenantMembership[] | null
  
  /** 
    Whether the user can administrate tenants (create/edit tenants).
    This is not handled as an AppUserRole because AppUserRoles are per-tenant,
    while this role transcends all tenants.
  */
  isTenantAdmin: boolean | null
  
  /** Gets or sets the email address for the user. */
  email: string | null
  
  /** Gets or sets a flag indicating if a user has confirmed their email address. */
  emailConfirmed: boolean | null
  
  /** Gets or sets the number of failed login attempts for the current user. */
  accessFailedCount: number | null
  
  /** 
    Gets or sets the date and time, in UTC, when any user lockout ends.
    A date in the past means the user is not locked out.
  */
  lockoutEnd: Date | null
  id: string | null
}
export class AppUser {
  
  /** Mutates the input object and its descendents into a valid AppUser implementation. */
  static convert(data?: Partial<AppUser>): AppUser {
    return convertToModel(data || {}, metadata.AppUser) 
  }
  
  /** Maps the input object and its descendents to a new, valid AppUser implementation. */
  static map(data?: Partial<AppUser>): AppUser {
    return mapToModel(data || {}, metadata.AppUser) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AppUser; }
  
  /** Instantiate a new AppUser, optionally basing it on the given data. */
  constructor(data?: Partial<AppUser> | {[k: string]: any}) {
    Object.assign(this, AppUser.map(data || {}));
  }
}


export interface AppUserAllTenantRole extends Model<typeof metadata.AppUserAllTenantRole> {
  id: number | null
  userId: string | null
  user: AppUser | null
  roleId: string | null
  role: AppRole | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class AppUserAllTenantRole {
  
  /** Mutates the input object and its descendents into a valid AppUserAllTenantRole implementation. */
  static convert(data?: Partial<AppUserAllTenantRole>): AppUserAllTenantRole {
    return convertToModel(data || {}, metadata.AppUserAllTenantRole) 
  }
  
  /** Maps the input object and its descendents to a new, valid AppUserAllTenantRole implementation. */
  static map(data?: Partial<AppUserAllTenantRole>): AppUserAllTenantRole {
    return mapToModel(data || {}, metadata.AppUserAllTenantRole) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AppUserAllTenantRole; }
  
  /** Instantiate a new AppUserAllTenantRole, optionally basing it on the given data. */
  constructor(data?: Partial<AppUserAllTenantRole> | {[k: string]: any}) {
    Object.assign(this, AppUserAllTenantRole.map(data || {}));
  }
}


export interface AppUserRole extends Model<typeof metadata.AppUserRole> {
  id: string | null
  userId: string | null
  user: AppUser | null
  roleId: string | null
  role: AppRole | null
}
export class AppUserRole {
  
  /** Mutates the input object and its descendents into a valid AppUserRole implementation. */
  static convert(data?: Partial<AppUserRole>): AppUserRole {
    return convertToModel(data || {}, metadata.AppUserRole) 
  }
  
  /** Maps the input object and its descendents to a new, valid AppUserRole implementation. */
  static map(data?: Partial<AppUserRole>): AppUserRole {
    return mapToModel(data || {}, metadata.AppUserRole) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AppUserRole; }
  
  /** Instantiate a new AppUserRole, optionally basing it on the given data. */
  constructor(data?: Partial<AppUserRole> | {[k: string]: any}) {
    Object.assign(this, AppUserRole.map(data || {}));
  }
}


export interface AvailableSeatsDetails extends Model<typeof metadata.AvailableSeatsDetails> {
  seatNumber: string | null
  amountOfSeats: number | null
}
export class AvailableSeatsDetails {
  
  /** Mutates the input object and its descendents into a valid AvailableSeatsDetails implementation. */
  static convert(data?: Partial<AvailableSeatsDetails>): AvailableSeatsDetails {
    return convertToModel(data || {}, metadata.AvailableSeatsDetails) 
  }
  
  /** Maps the input object and its descendents to a new, valid AvailableSeatsDetails implementation. */
  static map(data?: Partial<AvailableSeatsDetails>): AvailableSeatsDetails {
    return mapToModel(data || {}, metadata.AvailableSeatsDetails) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.AvailableSeatsDetails; }
  
  /** Instantiate a new AvailableSeatsDetails, optionally basing it on the given data. */
  constructor(data?: Partial<AvailableSeatsDetails> | {[k: string]: any}) {
    Object.assign(this, AvailableSeatsDetails.map(data || {}));
  }
}


export interface Consignee extends Model<typeof metadata.Consignee> {
  id: number | null
  name: string | null
  address: string | null
  contactPhone: string | null
  taxId: string | null
  consigneeDiscountDefinitions: ConsigneeDiscountDefinition[] | null
  consigneeReps: ConsigneeRep[] | null
  consigneeCommissions: ConsigneeCommission[] | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class Consignee {
  
  /** Mutates the input object and its descendents into a valid Consignee implementation. */
  static convert(data?: Partial<Consignee>): Consignee {
    return convertToModel(data || {}, metadata.Consignee) 
  }
  
  /** Maps the input object and its descendents to a new, valid Consignee implementation. */
  static map(data?: Partial<Consignee>): Consignee {
    return mapToModel(data || {}, metadata.Consignee) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.Consignee; }
  
  /** Instantiate a new Consignee, optionally basing it on the given data. */
  constructor(data?: Partial<Consignee> | {[k: string]: any}) {
    Object.assign(this, Consignee.map(data || {}));
  }
}


export interface ConsigneeCommission extends Model<typeof metadata.ConsigneeCommission> {
  id: number | null
  commissionPercent: number | null
  commissionRate: number | null
  consigneeId: number | null
  consignee: Consignee | null
  
  /** Whether this is the singular active commission on the linked consignee. */
  isActive: boolean | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class ConsigneeCommission {
  
  /** Mutates the input object and its descendents into a valid ConsigneeCommission implementation. */
  static convert(data?: Partial<ConsigneeCommission>): ConsigneeCommission {
    return convertToModel(data || {}, metadata.ConsigneeCommission) 
  }
  
  /** Maps the input object and its descendents to a new, valid ConsigneeCommission implementation. */
  static map(data?: Partial<ConsigneeCommission>): ConsigneeCommission {
    return mapToModel(data || {}, metadata.ConsigneeCommission) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.ConsigneeCommission; }
  
  /** Instantiate a new ConsigneeCommission, optionally basing it on the given data. */
  constructor(data?: Partial<ConsigneeCommission> | {[k: string]: any}) {
    Object.assign(this, ConsigneeCommission.map(data || {}));
  }
}


export interface ConsigneeDiscountDefinition extends Model<typeof metadata.ConsigneeDiscountDefinition> {
  id: number | null
  consigneeId: number | null
  discountDefinitionId: number | null
  consignee: Consignee | null
  discountDefinition: DiscountDefinition | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class ConsigneeDiscountDefinition {
  
  /** Mutates the input object and its descendents into a valid ConsigneeDiscountDefinition implementation. */
  static convert(data?: Partial<ConsigneeDiscountDefinition>): ConsigneeDiscountDefinition {
    return convertToModel(data || {}, metadata.ConsigneeDiscountDefinition) 
  }
  
  /** Maps the input object and its descendents to a new, valid ConsigneeDiscountDefinition implementation. */
  static map(data?: Partial<ConsigneeDiscountDefinition>): ConsigneeDiscountDefinition {
    return mapToModel(data || {}, metadata.ConsigneeDiscountDefinition) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.ConsigneeDiscountDefinition; }
  
  /** Instantiate a new ConsigneeDiscountDefinition, optionally basing it on the given data. */
  constructor(data?: Partial<ConsigneeDiscountDefinition> | {[k: string]: any}) {
    Object.assign(this, ConsigneeDiscountDefinition.map(data || {}));
  }
}


export interface ConsigneeRep extends Model<typeof metadata.ConsigneeRep> {
  id: number | null
  consigneeId: number | null
  consignee: Consignee | null
  userId: string | null
  user: AppUser | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class ConsigneeRep {
  
  /** Mutates the input object and its descendents into a valid ConsigneeRep implementation. */
  static convert(data?: Partial<ConsigneeRep>): ConsigneeRep {
    return convertToModel(data || {}, metadata.ConsigneeRep) 
  }
  
  /** Maps the input object and its descendents to a new, valid ConsigneeRep implementation. */
  static map(data?: Partial<ConsigneeRep>): ConsigneeRep {
    return mapToModel(data || {}, metadata.ConsigneeRep) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.ConsigneeRep; }
  
  /** Instantiate a new ConsigneeRep, optionally basing it on the given data. */
  constructor(data?: Partial<ConsigneeRep> | {[k: string]: any}) {
    Object.assign(this, ConsigneeRep.map(data || {}));
  }
}


export interface CreditCardDetails extends Model<typeof metadata.CreditCardDetails> {
  paymentId: number | null
  cardNumber: string | null
  expirationMonth: number | null
  expirationYear: number | null
  cvv: string | null
  billingAddress: string | null
  billingZip: string | null
  billingCountry: string | null
  billingState: string | null
}
export class CreditCardDetails {
  
  /** Mutates the input object and its descendents into a valid CreditCardDetails implementation. */
  static convert(data?: Partial<CreditCardDetails>): CreditCardDetails {
    return convertToModel(data || {}, metadata.CreditCardDetails) 
  }
  
  /** Maps the input object and its descendents to a new, valid CreditCardDetails implementation. */
  static map(data?: Partial<CreditCardDetails>): CreditCardDetails {
    return mapToModel(data || {}, metadata.CreditCardDetails) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.CreditCardDetails; }
  
  /** Instantiate a new CreditCardDetails, optionally basing it on the given data. */
  constructor(data?: Partial<CreditCardDetails> | {[k: string]: any}) {
    Object.assign(this, CreditCardDetails.map(data || {}));
  }
}


export interface DiscountDefinition extends Model<typeof metadata.DiscountDefinition> {
  id: number | null
  
  /** The name of the discount, shown to patrons. */
  displayName: string | null
  
  /** An internal description of the discount, shown to admins and event managers. */
  details: string | null
  effectSummary: string | null
  nonUniqueCode: string | null
  isActive: boolean | null
  availableOnWeb: boolean | null
  availableOnPos: boolean | null
  
  /** 
    Applies automatically when the minimums established by MinimumTicketCount,
    MinimumPurchaseValue, and ExpireDaysBefore are met.
  */
  autoApply: boolean | null
  
  /** Github #112: the goal is to permanently hiding the unwanted data by default */
  isHidden: boolean | null
  
  /** The ID of the currently active configuration for this item. */
  currentConfigurationId: number | null
  currentConfiguration: DiscountDefinitionConfiguration | null
  consigneeDiscountDefinitions: ConsigneeDiscountDefinition[] | null
  eventDiscountDefinitions: EventDiscountDefinition[] | null
  eventDateDiscountDefinitions: EventDateDiscountDefinition[] | null
  isPairedWithCouponCodeGroup: boolean | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class DiscountDefinition {
  
  /** Mutates the input object and its descendents into a valid DiscountDefinition implementation. */
  static convert(data?: Partial<DiscountDefinition>): DiscountDefinition {
    return convertToModel(data || {}, metadata.DiscountDefinition) 
  }
  
  /** Maps the input object and its descendents to a new, valid DiscountDefinition implementation. */
  static map(data?: Partial<DiscountDefinition>): DiscountDefinition {
    return mapToModel(data || {}, metadata.DiscountDefinition) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.DiscountDefinition; }
  
  /** Instantiate a new DiscountDefinition, optionally basing it on the given data. */
  constructor(data?: Partial<DiscountDefinition> | {[k: string]: any}) {
    Object.assign(this, DiscountDefinition.map(data || {}));
  }
}


export interface DiscountDefinitionConfiguration extends Model<typeof metadata.DiscountDefinitionConfiguration> {
  id: number | null
  discountDefinitionId: number | null
  discountDefinition: DiscountDefinition | null
  
  /** The name of the discount, shown to patrons. */
  displayName: string | null
  discountType: DiscountType | null
  amountOff: number | null
  percentageOff: number | null
  
  /** Discount only applies to purchases with at least this many tickets. */
  minimumTicketCount: number | null
  
  /** Discount only applies to purchases with at least this subtotal. */
  minimumPurchaseValue: number | null
  
  /** 
    The number of days before an event date when this discount becomes unavailable.
    e.g. a value of 7 means "discount is only available more than a week before the event".
    Use case is "early bird special" kinds of discounts.
  */
  expireDaysBefore: number | null
  effectSummary: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class DiscountDefinitionConfiguration {
  
  /** Mutates the input object and its descendents into a valid DiscountDefinitionConfiguration implementation. */
  static convert(data?: Partial<DiscountDefinitionConfiguration>): DiscountDefinitionConfiguration {
    return convertToModel(data || {}, metadata.DiscountDefinitionConfiguration) 
  }
  
  /** Maps the input object and its descendents to a new, valid DiscountDefinitionConfiguration implementation. */
  static map(data?: Partial<DiscountDefinitionConfiguration>): DiscountDefinitionConfiguration {
    return mapToModel(data || {}, metadata.DiscountDefinitionConfiguration) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.DiscountDefinitionConfiguration; }
  
  /** Instantiate a new DiscountDefinitionConfiguration, optionally basing it on the given data. */
  constructor(data?: Partial<DiscountDefinitionConfiguration> | {[k: string]: any}) {
    Object.assign(this, DiscountDefinitionConfiguration.map(data || {}));
  }
}


export interface EmailTemplate extends Model<typeof metadata.EmailTemplate> {
  id: number | null
  description: string | null
  subject: string | null
  bodyText: string | null
  bodyHtml: string | null
  designJson: string | null
  type: EmailType | null
  isActive: boolean | null
  eventEmailTemplates: EventEmailTemplate[] | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EmailTemplate {
  
  /** Mutates the input object and its descendents into a valid EmailTemplate implementation. */
  static convert(data?: Partial<EmailTemplate>): EmailTemplate {
    return convertToModel(data || {}, metadata.EmailTemplate) 
  }
  
  /** Maps the input object and its descendents to a new, valid EmailTemplate implementation. */
  static map(data?: Partial<EmailTemplate>): EmailTemplate {
    return mapToModel(data || {}, metadata.EmailTemplate) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EmailTemplate; }
  
  /** Instantiate a new EmailTemplate, optionally basing it on the given data. */
  constructor(data?: Partial<EmailTemplate> | {[k: string]: any}) {
    Object.assign(this, EmailTemplate.map(data || {}));
  }
}


export interface Event extends Model<typeof metadata.Event> {
  id: number | null
  name: string | null
  defaultCapacity: number | null
  boardingTime: number | null
  location: string | null
  shortDescription: string | null
  longDescription: string | null
  
  /** This text will be displayed once tickets were sold out. And only visible in the public purchase */
  customSoldOutText: string | null
  isActive: boolean | null
  
  /** 
    If true, EventDates for this Event are purchasable by
    public users. Such EventDates can be browsed freely on list and calendar views
    unless UnlistedOnWeb is true.
  */
  forSaleOnWeb: boolean | null
  
  /** 
    If true, EventDates for this Event are not browsable by public users,
    but can still be queried directly by ID and are still available
    for purchase by users who have obtained a direct link to an event date.
  */
  unlistedOnWeb: boolean | null
  forSaleOnPos: boolean | null
  unlistedOnWebAfterFirstPurchase: boolean | null
  updateCapacityToTicketCountAfterFirstPurchase: boolean | null
  
  /** 
    The Custom Button Text sections below are the defaults for the Event when custom button text is enabled.
    You can override these using the same names on the Event Date
  */
  customMoreOptionsButtonText: string | null
  customSelectTicketsButtonText: string | null
  customMoreOptionsIcon: string | null
  customSelectTicketsIcon: string | null
  eventTypeId: number | null
  eventType: EventType | null
  ticketTemplateId: number | null
  ticketTemplate: TicketTemplate | null
  confirmationEmailId: number | null
  confirmationEmail: EmailTemplate | null
  cancellationEmailId: number | null
  cancellationEmail: EmailTemplate | null
  venueId: number | null
  venue: Venue | null
  seatMapId: number | null
  seatMap: SeatMap | null
  eventSuggestedList: EventSuggestedEvent[] | null
  
  /** Whether the premium refunds option (PBI #1127) is offered for sales of this event. */
  premiumRefundsEnabled: boolean | null
  refundableFeePercentage: number | null
  isTenantShowImageOnListView: boolean | null
  isTenantAllowFreeEvent: boolean | null
  isTenantShowRemainingSeats: boolean | null
  
  /** 
    Whether the event serves food or not.
    Used to determine if customers are prompted for dietary restrictions.
  */
  hasFood: boolean | null
  
  /** Configuration of the sections of the selected seat map */
  seatSections: EventSection[] | null
  eventDates: EventDate[] | null
  
  /** Images for each event that will be stored in Azure storage blob and populated into a carousel */
  eventImages: EventImage[] | null
  
  /** 
    A banner image for each event. Being stored as a string url.
    This will be store explicitly in Azure File Upload Service
  */
  bannerImageSrc: string | null
  eventDiscountDefinitions: EventDiscountDefinition[] | null
  eventEmailTemplates: EventEmailTemplate[] | null
  eventAddonItems: EventAddonItemDefinition[] | null
  eventPrices: EventPrice[] | null
  activeEventPrices: EventPrice[] | null
  
  /** Used to determine if the event is a free or paid ticket. */
  isFreeEvent: boolean | null
  
  /** 
    This is a toggle to trigger email notification to all users who has EventManager role
    when a ticket was purchased.
  */
  notifyViaEmailWhenTicketPurchased: boolean | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class Event {
  
  /** Mutates the input object and its descendents into a valid Event implementation. */
  static convert(data?: Partial<Event>): Event {
    return convertToModel(data || {}, metadata.Event) 
  }
  
  /** Maps the input object and its descendents to a new, valid Event implementation. */
  static map(data?: Partial<Event>): Event {
    return mapToModel(data || {}, metadata.Event) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.Event; }
  
  /** Instantiate a new Event, optionally basing it on the given data. */
  constructor(data?: Partial<Event> | {[k: string]: any}) {
    Object.assign(this, Event.map(data || {}));
  }
}


export interface EventAddonDto extends Model<typeof metadata.EventAddonDto> {
  definitionId: number | null
  configurationId: number | null
  name: string | null
  description: string | null
  
  /** The price per item of this addon, given the current state of the purchase. */
  price: number | null
  
  /** A description of the pricing of this addon. */
  priceDescription: string | null
  hasQuantity: boolean | null
}
export class EventAddonDto {
  
  /** Mutates the input object and its descendents into a valid EventAddonDto implementation. */
  static convert(data?: Partial<EventAddonDto>): EventAddonDto {
    return convertToModel(data || {}, metadata.EventAddonDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventAddonDto implementation. */
  static map(data?: Partial<EventAddonDto>): EventAddonDto {
    return mapToModel(data || {}, metadata.EventAddonDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventAddonDto; }
  
  /** Instantiate a new EventAddonDto, optionally basing it on the given data. */
  constructor(data?: Partial<EventAddonDto> | {[k: string]: any}) {
    Object.assign(this, EventAddonDto.map(data || {}));
  }
}


export interface EventAddonItemDefinition extends Model<typeof metadata.EventAddonItemDefinition> {
  id: number | null
  eventId: number | null
  addonItemDefinitionId: number | null
  addonItemDefinition: AddonItemDefinition | null
  event: Event | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventAddonItemDefinition {
  
  /** Mutates the input object and its descendents into a valid EventAddonItemDefinition implementation. */
  static convert(data?: Partial<EventAddonItemDefinition>): EventAddonItemDefinition {
    return convertToModel(data || {}, metadata.EventAddonItemDefinition) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventAddonItemDefinition implementation. */
  static map(data?: Partial<EventAddonItemDefinition>): EventAddonItemDefinition {
    return mapToModel(data || {}, metadata.EventAddonItemDefinition) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventAddonItemDefinition; }
  
  /** Instantiate a new EventAddonItemDefinition, optionally basing it on the given data. */
  constructor(data?: Partial<EventAddonItemDefinition> | {[k: string]: any}) {
    Object.assign(this, EventAddonItemDefinition.map(data || {}));
  }
}


export interface EventDate extends Model<typeof metadata.EventDate> {
  id: number | null
  eventStartDateTime: Date | null
  eventEndDateTime: Date | null
  
  /** The duration of the event date, in minutes. */
  durationMinutes: number | null
  
  /** 
    The duration of the public selling ticket, in minutes.
    If nothing set, this will be 0 as you can making purchases til the event starts
  */
  eventPublicPurchaseDatetime: Date | null
  eventCapacity: number | null
  cancelled: boolean | null
  hiddenFromInitialPurchase: boolean | null
  eventId: number | null
  event: Event | null
  eventDateDiscountDefinitions: EventDateDiscountDefinition[] | null
  seatRestrictions: EventDateSeatRestriction[] | null
  priceConfigurations: EventDatePriceConfig[] | null
  
  /** The Custom Button Text sections below will override those set in the Event if custom button text is enabled for the tenant. */
  customMoreOptionsUrl: string | null
  customSelectTicketsUrl: string | null
  customMoreOptionsButtonText: string | null
  customSelectTicketsButtonText: string | null
  customMoreOptionsIcon: string | null
  customSelectTicketsIcon: string | null
  
  /** This is kept up to date by methods in EventDateService. */
  availableSeats: number | null
  availableSeatsCacheDate: Date | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventDate {
  
  /** Mutates the input object and its descendents into a valid EventDate implementation. */
  static convert(data?: Partial<EventDate>): EventDate {
    return convertToModel(data || {}, metadata.EventDate) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDate implementation. */
  static map(data?: Partial<EventDate>): EventDate {
    return mapToModel(data || {}, metadata.EventDate) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDate; }
  
  /** Instantiate a new EventDate, optionally basing it on the given data. */
  constructor(data?: Partial<EventDate> | {[k: string]: any}) {
    Object.assign(this, EventDate.map(data || {}));
  }
}


export interface EventDateDiscountDefinition extends Model<typeof metadata.EventDateDiscountDefinition> {
  id: number | null
  eventDateId: number | null
  discountDefinitionId: number | null
  discountDefinition: DiscountDefinition | null
  eventDate: EventDate | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventDateDiscountDefinition {
  
  /** Mutates the input object and its descendents into a valid EventDateDiscountDefinition implementation. */
  static convert(data?: Partial<EventDateDiscountDefinition>): EventDateDiscountDefinition {
    return convertToModel(data || {}, metadata.EventDateDiscountDefinition) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDateDiscountDefinition implementation. */
  static map(data?: Partial<EventDateDiscountDefinition>): EventDateDiscountDefinition {
    return mapToModel(data || {}, metadata.EventDateDiscountDefinition) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDateDiscountDefinition; }
  
  /** Instantiate a new EventDateDiscountDefinition, optionally basing it on the given data. */
  constructor(data?: Partial<EventDateDiscountDefinition> | {[k: string]: any}) {
    Object.assign(this, EventDateDiscountDefinition.map(data || {}));
  }
}


export interface EventDatePriceConfig extends Model<typeof metadata.EventDatePriceConfig> {
  id: number | null
  eventDateId: number | null
  eventDate: EventDate | null
  
  /** 
    The family of EventPrice records with a matching RootId 
    are what this configuration governs.
  */
  eventPriceRootId: number | null
  
  /** 
    The maximum number of Ticket records that can be sold for the EventDate
    for the given EventPriceRootId.
    Only applicable for events without a SeatMap configured.
  */
  maxQuantity: number | null
  
  /** 
    The current computed number of remaining available tickets of this price.
    Only applicable if MaxQuantity is set.
    Updated by EventDateService.
  */
  availableTickets: number | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventDatePriceConfig {
  
  /** Mutates the input object and its descendents into a valid EventDatePriceConfig implementation. */
  static convert(data?: Partial<EventDatePriceConfig>): EventDatePriceConfig {
    return convertToModel(data || {}, metadata.EventDatePriceConfig) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDatePriceConfig implementation. */
  static map(data?: Partial<EventDatePriceConfig>): EventDatePriceConfig {
    return mapToModel(data || {}, metadata.EventDatePriceConfig) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDatePriceConfig; }
  
  /** Instantiate a new EventDatePriceConfig, optionally basing it on the given data. */
  constructor(data?: Partial<EventDatePriceConfig> | {[k: string]: any}) {
    Object.assign(this, EventDatePriceConfig.map(data || {}));
  }
}


export interface EventDateSeatingInfo extends Model<typeof metadata.EventDateSeatingInfo> {
  eventDateId: number | null
  seatMap: SeatMapDto | null
  availableSeats: string[] | null
  availableSeatsDetails: AvailableSeatsDetails[] | null
  totalAmountOfAvailableSeats: number | null
  restrictions: EventDateSeatingRestrictionInfo[] | null
}
export class EventDateSeatingInfo {
  
  /** Mutates the input object and its descendents into a valid EventDateSeatingInfo implementation. */
  static convert(data?: Partial<EventDateSeatingInfo>): EventDateSeatingInfo {
    return convertToModel(data || {}, metadata.EventDateSeatingInfo) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDateSeatingInfo implementation. */
  static map(data?: Partial<EventDateSeatingInfo>): EventDateSeatingInfo {
    return mapToModel(data || {}, metadata.EventDateSeatingInfo) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDateSeatingInfo; }
  
  /** Instantiate a new EventDateSeatingInfo, optionally basing it on the given data. */
  constructor(data?: Partial<EventDateSeatingInfo> | {[k: string]: any}) {
    Object.assign(this, EventDateSeatingInfo.map(data || {}));
  }
}


export interface EventDateSeatingRestrictionInfo extends Model<typeof metadata.EventDateSeatingRestrictionInfo> {
  seats: string[] | null
  id: number | null
  agentOnly: boolean | null
  agentNote: string | null
}
export class EventDateSeatingRestrictionInfo {
  
  /** Mutates the input object and its descendents into a valid EventDateSeatingRestrictionInfo implementation. */
  static convert(data?: Partial<EventDateSeatingRestrictionInfo>): EventDateSeatingRestrictionInfo {
    return convertToModel(data || {}, metadata.EventDateSeatingRestrictionInfo) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDateSeatingRestrictionInfo implementation. */
  static map(data?: Partial<EventDateSeatingRestrictionInfo>): EventDateSeatingRestrictionInfo {
    return mapToModel(data || {}, metadata.EventDateSeatingRestrictionInfo) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDateSeatingRestrictionInfo; }
  
  /** Instantiate a new EventDateSeatingRestrictionInfo, optionally basing it on the given data. */
  constructor(data?: Partial<EventDateSeatingRestrictionInfo> | {[k: string]: any}) {
    Object.assign(this, EventDateSeatingRestrictionInfo.map(data || {}));
  }
}


export interface EventDateSeatRestriction extends Model<typeof metadata.EventDateSeatRestriction> {
  id: number | null
  eventDateId: number | null
  eventDate: EventDate | null
  agentOnly: boolean | null
  
  /** A note about the restriction that will be visible to agents. */
  agentNote: string | null
  seatNumbersJson: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventDateSeatRestriction {
  
  /** Mutates the input object and its descendents into a valid EventDateSeatRestriction implementation. */
  static convert(data?: Partial<EventDateSeatRestriction>): EventDateSeatRestriction {
    return convertToModel(data || {}, metadata.EventDateSeatRestriction) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDateSeatRestriction implementation. */
  static map(data?: Partial<EventDateSeatRestriction>): EventDateSeatRestriction {
    return mapToModel(data || {}, metadata.EventDateSeatRestriction) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDateSeatRestriction; }
  
  /** Instantiate a new EventDateSeatRestriction, optionally basing it on the given data. */
  constructor(data?: Partial<EventDateSeatRestriction> | {[k: string]: any}) {
    Object.assign(this, EventDateSeatRestriction.map(data || {}));
  }
}


export interface EventDiscountDefinition extends Model<typeof metadata.EventDiscountDefinition> {
  id: number | null
  eventId: number | null
  discountDefinitionId: number | null
  discountDefinition: DiscountDefinition | null
  event: Event | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventDiscountDefinition {
  
  /** Mutates the input object and its descendents into a valid EventDiscountDefinition implementation. */
  static convert(data?: Partial<EventDiscountDefinition>): EventDiscountDefinition {
    return convertToModel(data || {}, metadata.EventDiscountDefinition) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventDiscountDefinition implementation. */
  static map(data?: Partial<EventDiscountDefinition>): EventDiscountDefinition {
    return mapToModel(data || {}, metadata.EventDiscountDefinition) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventDiscountDefinition; }
  
  /** Instantiate a new EventDiscountDefinition, optionally basing it on the given data. */
  constructor(data?: Partial<EventDiscountDefinition> | {[k: string]: any}) {
    Object.assign(this, EventDiscountDefinition.map(data || {}));
  }
}


export interface EventEmailTemplate extends Model<typeof metadata.EventEmailTemplate> {
  id: number | null
  eventId: number | null
  emailTemplateId: number | null
  emailTemplate: EmailTemplate | null
  event: Event | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventEmailTemplate {
  
  /** Mutates the input object and its descendents into a valid EventEmailTemplate implementation. */
  static convert(data?: Partial<EventEmailTemplate>): EventEmailTemplate {
    return convertToModel(data || {}, metadata.EventEmailTemplate) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventEmailTemplate implementation. */
  static map(data?: Partial<EventEmailTemplate>): EventEmailTemplate {
    return mapToModel(data || {}, metadata.EventEmailTemplate) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventEmailTemplate; }
  
  /** Instantiate a new EventEmailTemplate, optionally basing it on the given data. */
  constructor(data?: Partial<EventEmailTemplate> | {[k: string]: any}) {
    Object.assign(this, EventEmailTemplate.map(data || {}));
  }
}


export interface EventImage extends Model<typeof metadata.EventImage> {
  id: number | null
  eventId: number | null
  imageSrc: string | null
  altText: string | null
  order: number | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventImage {
  
  /** Mutates the input object and its descendents into a valid EventImage implementation. */
  static convert(data?: Partial<EventImage>): EventImage {
    return convertToModel(data || {}, metadata.EventImage) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventImage implementation. */
  static map(data?: Partial<EventImage>): EventImage {
    return mapToModel(data || {}, metadata.EventImage) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventImage; }
  
  /** Instantiate a new EventImage, optionally basing it on the given data. */
  constructor(data?: Partial<EventImage> | {[k: string]: any}) {
    Object.assign(this, EventImage.map(data || {}));
  }
}


export interface EventPrice extends Model<typeof metadata.EventPrice> {
  id: number | null
  
  /** 
    The original EventPrice record that this EventPrice is a revision of.
    We use this to correlate purchases of different versions of an EventPrice
    together for purpose of per-ticket-type maximums.
  */
  rootId: number | null
  eventId: number | null
  event: Event | null
  
  /** 
    If non-null, this EventPrice is available for selection when creating a ticket purchase.
    Otherwise, this record represents a previous historical price for the event that is no longer active.
  */
  activeEventId: number | null
  activeEvent: Event | null
  displayName: string | null
  details: string | null
  price: number | null
  
  /** 
    The service fee if CurrentServiceFeeFromTenant is null.
    https://hagadone.visualstudio.com/Ticketing/_workitems/edit/1186
  */
  serviceFee: number | null
  
  /** 
    The current configured value of the tenant-level service fee amount.
    This is NOT a point-in-time snapshot. This exists to show the tenant's
    value in the UI when configuring an EventPrice.
    See https://hagadone.visualstudio.com/Ticketing/_workitems/edit/1186
  */
  currentServiceFeeFromTenant: number | null
  revenueDistributions: EventPriceRevenueDistribution[] | null
  
  /** 
    The number of seats that this ticket counts as.
    This is the number that will be counted against the EventDate's Capacity.
    For events that have a SeatMap, this setting has no effect.
    This allows a "table for two" to be sold as a single atomic unit. PBI 1004.
    We also allow zero because there's no reason not to.
  */
  seats: number | null
  
  /** 
    The default maximum quantity of this ticket type.
    Read from and overridden by MaxQuantity.
  */
  defaultMaxQuantity: number | null
  
  /** 
    An optional field that forces users to buy tickets with the minimum amount per ticket type.
    See github issue #169 for more info
  */
  minimumQuantity: number | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventPrice {
  
  /** Mutates the input object and its descendents into a valid EventPrice implementation. */
  static convert(data?: Partial<EventPrice>): EventPrice {
    return convertToModel(data || {}, metadata.EventPrice) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventPrice implementation. */
  static map(data?: Partial<EventPrice>): EventPrice {
    return mapToModel(data || {}, metadata.EventPrice) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventPrice; }
  
  /** Instantiate a new EventPrice, optionally basing it on the given data. */
  constructor(data?: Partial<EventPrice> | {[k: string]: any}) {
    Object.assign(this, EventPrice.map(data || {}));
  }
}


export interface EventPriceDto extends Model<typeof metadata.EventPriceDto> {
  id: number | null
  rootId: number | null
  displayName: string | null
  description: string | null
  price: number | null
  serviceFee: number | null
  seats: number | null
  minimumQuantity: number | null
}
export class EventPriceDto {
  
  /** Mutates the input object and its descendents into a valid EventPriceDto implementation. */
  static convert(data?: Partial<EventPriceDto>): EventPriceDto {
    return convertToModel(data || {}, metadata.EventPriceDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventPriceDto implementation. */
  static map(data?: Partial<EventPriceDto>): EventPriceDto {
    return mapToModel(data || {}, metadata.EventPriceDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventPriceDto; }
  
  /** Instantiate a new EventPriceDto, optionally basing it on the given data. */
  constructor(data?: Partial<EventPriceDto> | {[k: string]: any}) {
    Object.assign(this, EventPriceDto.map(data || {}));
  }
}


export interface EventPriceRevenueDistribution extends Model<typeof metadata.EventPriceRevenueDistribution> {
  id: number | null
  eventPriceId: number | null
  eventPrice: EventPrice | null
  revenueCategoryId: number | null
  revenueCategory: RevenueCategory | null
  revenue: number | null
}
export class EventPriceRevenueDistribution {
  
  /** Mutates the input object and its descendents into a valid EventPriceRevenueDistribution implementation. */
  static convert(data?: Partial<EventPriceRevenueDistribution>): EventPriceRevenueDistribution {
    return convertToModel(data || {}, metadata.EventPriceRevenueDistribution) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventPriceRevenueDistribution implementation. */
  static map(data?: Partial<EventPriceRevenueDistribution>): EventPriceRevenueDistribution {
    return mapToModel(data || {}, metadata.EventPriceRevenueDistribution) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventPriceRevenueDistribution; }
  
  /** Instantiate a new EventPriceRevenueDistribution, optionally basing it on the given data. */
  constructor(data?: Partial<EventPriceRevenueDistribution> | {[k: string]: any}) {
    Object.assign(this, EventPriceRevenueDistribution.map(data || {}));
  }
}


export interface EventSection extends Model<typeof metadata.EventSection> {
  id: number | null
  eventId: number | null
  event: Event | null
  seatMapSectionId: number | null
  seatMapSection: SeatMapSection | null
  
  /** Whether the seats within this section are available for the event. */
  isEnabled: boolean | null
  
  /** The prices for the event that are available in this section. */
  prices: EventSectionPrice[] | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventSection {
  
  /** Mutates the input object and its descendents into a valid EventSection implementation. */
  static convert(data?: Partial<EventSection>): EventSection {
    return convertToModel(data || {}, metadata.EventSection) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventSection implementation. */
  static map(data?: Partial<EventSection>): EventSection {
    return mapToModel(data || {}, metadata.EventSection) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventSection; }
  
  /** Instantiate a new EventSection, optionally basing it on the given data. */
  constructor(data?: Partial<EventSection> | {[k: string]: any}) {
    Object.assign(this, EventSection.map(data || {}));
  }
}


export interface EventSectionPrice extends Model<typeof metadata.EventSectionPrice> {
  id: number | null
  eventSectionId: number | null
  eventSection: EventSection | null
  eventPriceId: number | null
  eventPrice: EventPrice | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventSectionPrice {
  
  /** Mutates the input object and its descendents into a valid EventSectionPrice implementation. */
  static convert(data?: Partial<EventSectionPrice>): EventSectionPrice {
    return convertToModel(data || {}, metadata.EventSectionPrice) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventSectionPrice implementation. */
  static map(data?: Partial<EventSectionPrice>): EventSectionPrice {
    return mapToModel(data || {}, metadata.EventSectionPrice) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventSectionPrice; }
  
  /** Instantiate a new EventSectionPrice, optionally basing it on the given data. */
  constructor(data?: Partial<EventSectionPrice> | {[k: string]: any}) {
    Object.assign(this, EventSectionPrice.map(data || {}));
  }
}


export interface EventSuggestedEvent extends Model<typeof metadata.EventSuggestedEvent> {
  id: number | null
  eventId: number | null
  event: Event | null
  suggestedEventId: number | null
  suggestedEvent: Event | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventSuggestedEvent {
  
  /** Mutates the input object and its descendents into a valid EventSuggestedEvent implementation. */
  static convert(data?: Partial<EventSuggestedEvent>): EventSuggestedEvent {
    return convertToModel(data || {}, metadata.EventSuggestedEvent) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventSuggestedEvent implementation. */
  static map(data?: Partial<EventSuggestedEvent>): EventSuggestedEvent {
    return mapToModel(data || {}, metadata.EventSuggestedEvent) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventSuggestedEvent; }
  
  /** Instantiate a new EventSuggestedEvent, optionally basing it on the given data. */
  constructor(data?: Partial<EventSuggestedEvent> | {[k: string]: any}) {
    Object.assign(this, EventSuggestedEvent.map(data || {}));
  }
}


export interface EventType extends Model<typeof metadata.EventType> {
  id: number | null
  
  /** Shown to users in the Event Type filters on the calendar + list views. */
  shortDescription: string | null
  
  /** This field is currently not shown anywhere in the app. */
  longDescription: string | null
  
  /** 
    Description of the premium refunds option presented to customers
    for events where PremiumRefundsEnabled == true
  */
  refundPolicy: string | null
  events: Event[] | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class EventType {
  
  /** Mutates the input object and its descendents into a valid EventType implementation. */
  static convert(data?: Partial<EventType>): EventType {
    return convertToModel(data || {}, metadata.EventType) 
  }
  
  /** Maps the input object and its descendents to a new, valid EventType implementation. */
  static map(data?: Partial<EventType>): EventType {
    return mapToModel(data || {}, metadata.EventType) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.EventType; }
  
  /** Instantiate a new EventType, optionally basing it on the given data. */
  constructor(data?: Partial<EventType> | {[k: string]: any}) {
    Object.assign(this, EventType.map(data || {}));
  }
}


export interface PaymentGateway extends Model<typeof metadata.PaymentGateway> {
  paymentGatewayId: number | null
  displayName: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class PaymentGateway {
  
  /** Mutates the input object and its descendents into a valid PaymentGateway implementation. */
  static convert(data?: Partial<PaymentGateway>): PaymentGateway {
    return convertToModel(data || {}, metadata.PaymentGateway) 
  }
  
  /** Maps the input object and its descendents to a new, valid PaymentGateway implementation. */
  static map(data?: Partial<PaymentGateway>): PaymentGateway {
    return mapToModel(data || {}, metadata.PaymentGateway) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.PaymentGateway; }
  
  /** Instantiate a new PaymentGateway, optionally basing it on the given data. */
  constructor(data?: Partial<PaymentGateway> | {[k: string]: any}) {
    Object.assign(this, PaymentGateway.map(data || {}));
  }
}


export interface PostalCodeInfo extends Model<typeof metadata.PostalCodeInfo> {
  country: string | null
  zip: string | null
  city: string | null
  state: string | null
  stateAbbr: string | null
}
export class PostalCodeInfo {
  
  /** Mutates the input object and its descendents into a valid PostalCodeInfo implementation. */
  static convert(data?: Partial<PostalCodeInfo>): PostalCodeInfo {
    return convertToModel(data || {}, metadata.PostalCodeInfo) 
  }
  
  /** Maps the input object and its descendents to a new, valid PostalCodeInfo implementation. */
  static map(data?: Partial<PostalCodeInfo>): PostalCodeInfo {
    return mapToModel(data || {}, metadata.PostalCodeInfo) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.PostalCodeInfo; }
  
  /** Instantiate a new PostalCodeInfo, optionally basing it on the given data. */
  constructor(data?: Partial<PostalCodeInfo> | {[k: string]: any}) {
    Object.assign(this, PostalCodeInfo.map(data || {}));
  }
}


export interface RevenueCategory extends Model<typeof metadata.RevenueCategory> {
  id: number | null
  
  /** Name used in report headings, recorded immutably in the app's transaction log. */
  name: string | null
  
  /** Explanation of the purpose of this category. */
  description: string | null
  
  /** The order of this category in reports. */
  order: number | null
  
  /** 
    Soft deletes. A revenue category can be prevented from being added to any events in the future,
    but removing it from existing configuration is nigh impossible, since even if it is only used by
    inactive EventPrice configuration, that configuration must be kept in case of future exchanges/refunds.
  */
  isArchived: boolean | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class RevenueCategory {
  
  /** Mutates the input object and its descendents into a valid RevenueCategory implementation. */
  static convert(data?: Partial<RevenueCategory>): RevenueCategory {
    return convertToModel(data || {}, metadata.RevenueCategory) 
  }
  
  /** Maps the input object and its descendents to a new, valid RevenueCategory implementation. */
  static map(data?: Partial<RevenueCategory>): RevenueCategory {
    return mapToModel(data || {}, metadata.RevenueCategory) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.RevenueCategory; }
  
  /** Instantiate a new RevenueCategory, optionally basing it on the given data. */
  constructor(data?: Partial<RevenueCategory> | {[k: string]: any}) {
    Object.assign(this, RevenueCategory.map(data || {}));
  }
}


export interface SeatMap extends Model<typeof metadata.SeatMap> {
  id: number | null
  venueId: number | null
  venue: Venue | null
  name: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class SeatMap {
  
  /** Mutates the input object and its descendents into a valid SeatMap implementation. */
  static convert(data?: Partial<SeatMap>): SeatMap {
    return convertToModel(data || {}, metadata.SeatMap) 
  }
  
  /** Maps the input object and its descendents to a new, valid SeatMap implementation. */
  static map(data?: Partial<SeatMap>): SeatMap {
    return mapToModel(data || {}, metadata.SeatMap) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.SeatMap; }
  
  /** Instantiate a new SeatMap, optionally basing it on the given data. */
  constructor(data?: Partial<SeatMap> | {[k: string]: any}) {
    Object.assign(this, SeatMap.map(data || {}));
  }
}


export interface SeatMapAnnotationDto extends Model<typeof metadata.SeatMapAnnotationDto> {
  id: number | null
  x: number | null
  y: number | null
  width: number | null
  height: number | null
  label: string | null
  fontScale: number | null
  shape: SeatMapAnnotationShape | null
}
export class SeatMapAnnotationDto {
  
  /** Mutates the input object and its descendents into a valid SeatMapAnnotationDto implementation. */
  static convert(data?: Partial<SeatMapAnnotationDto>): SeatMapAnnotationDto {
    return convertToModel(data || {}, metadata.SeatMapAnnotationDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid SeatMapAnnotationDto implementation. */
  static map(data?: Partial<SeatMapAnnotationDto>): SeatMapAnnotationDto {
    return mapToModel(data || {}, metadata.SeatMapAnnotationDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.SeatMapAnnotationDto; }
  
  /** Instantiate a new SeatMapAnnotationDto, optionally basing it on the given data. */
  constructor(data?: Partial<SeatMapAnnotationDto> | {[k: string]: any}) {
    Object.assign(this, SeatMapAnnotationDto.map(data || {}));
  }
}


export interface SeatMapDto extends Model<typeof metadata.SeatMapDto> {
  id: number | null
  name: string | null
  venueId: number | null
  venueName: string | null
  sections: SeatMapSectionDto[] | null
  annotations: SeatMapAnnotationDto[] | null
}
export class SeatMapDto {
  
  /** Mutates the input object and its descendents into a valid SeatMapDto implementation. */
  static convert(data?: Partial<SeatMapDto>): SeatMapDto {
    return convertToModel(data || {}, metadata.SeatMapDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid SeatMapDto implementation. */
  static map(data?: Partial<SeatMapDto>): SeatMapDto {
    return mapToModel(data || {}, metadata.SeatMapDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.SeatMapDto; }
  
  /** Instantiate a new SeatMapDto, optionally basing it on the given data. */
  constructor(data?: Partial<SeatMapDto> | {[k: string]: any}) {
    Object.assign(this, SeatMapDto.map(data || {}));
  }
}


export interface SeatMapSeatDto extends Model<typeof metadata.SeatMapSeatDto> {
  seatNumber: string | null
  x: number | null
  y: number | null
  amountOfSeats: number | null
  width: number | null
  height: number | null
  fontScale: number | null
}
export class SeatMapSeatDto {
  
  /** Mutates the input object and its descendents into a valid SeatMapSeatDto implementation. */
  static convert(data?: Partial<SeatMapSeatDto>): SeatMapSeatDto {
    return convertToModel(data || {}, metadata.SeatMapSeatDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid SeatMapSeatDto implementation. */
  static map(data?: Partial<SeatMapSeatDto>): SeatMapSeatDto {
    return mapToModel(data || {}, metadata.SeatMapSeatDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.SeatMapSeatDto; }
  
  /** Instantiate a new SeatMapSeatDto, optionally basing it on the given data. */
  constructor(data?: Partial<SeatMapSeatDto> | {[k: string]: any}) {
    Object.assign(this, SeatMapSeatDto.map(data || {}));
  }
}


export interface SeatMapSection extends Model<typeof metadata.SeatMapSection> {
  id: number | null
  seatMapId: number | null
  seatMap: SeatMap | null
  name: string | null
  color: string | null
  type: SectionTypes | null
}
export class SeatMapSection {
  
  /** Mutates the input object and its descendents into a valid SeatMapSection implementation. */
  static convert(data?: Partial<SeatMapSection>): SeatMapSection {
    return convertToModel(data || {}, metadata.SeatMapSection) 
  }
  
  /** Maps the input object and its descendents to a new, valid SeatMapSection implementation. */
  static map(data?: Partial<SeatMapSection>): SeatMapSection {
    return mapToModel(data || {}, metadata.SeatMapSection) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.SeatMapSection; }
  
  /** Instantiate a new SeatMapSection, optionally basing it on the given data. */
  constructor(data?: Partial<SeatMapSection> | {[k: string]: any}) {
    Object.assign(this, SeatMapSection.map(data || {}));
  }
}


export interface SeatMapSectionDto extends Model<typeof metadata.SeatMapSectionDto> {
  id: number | null
  name: string | null
  color: string | null
  type: SectionTypes | null
  seats: SeatMapSeatDto[] | null
  
  /** 
    When a seat map is requested in the context of a particular event,
    this represents the specific prices available for the section.
  */
  prices: EventPriceDto[] | null
}
export class SeatMapSectionDto {
  
  /** Mutates the input object and its descendents into a valid SeatMapSectionDto implementation. */
  static convert(data?: Partial<SeatMapSectionDto>): SeatMapSectionDto {
    return convertToModel(data || {}, metadata.SeatMapSectionDto) 
  }
  
  /** Maps the input object and its descendents to a new, valid SeatMapSectionDto implementation. */
  static map(data?: Partial<SeatMapSectionDto>): SeatMapSectionDto {
    return mapToModel(data || {}, metadata.SeatMapSectionDto) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.SeatMapSectionDto; }
  
  /** Instantiate a new SeatMapSectionDto, optionally basing it on the given data. */
  constructor(data?: Partial<SeatMapSectionDto> | {[k: string]: any}) {
    Object.assign(this, SeatMapSectionDto.map(data || {}));
  }
}


export interface Tenant extends Model<typeof metadata.Tenant> {
  tenantId: number | null
  
  /** The name of the tenant, shown throughout the application to both patrons and logged-in users. */
  name: string | null
  
  /** A short code that uniquely identifies the tenant. Depending on Payment Gateway settings, this may be prepended to invoice numbers. */
  shortCode: string | null
  
  /** An email address that patrons may message to receive customer support. */
  supportEmail: string | null
  
  /** A phone number that patrons may call to receive customer support. */
  supportPhone: string | null
  
  /** The 'From' address + domain when sending email with Mailgun. Required to send purchase confirmations and other essential communication. */
  emailSenderAddress: string | null
  
  /** A Mailgun API key that corresponds to the domain used in Email Sender Address. Required to send purchase confirmations and other essential communication. */
  mailgunApiKey: string | null
  twilioMessageServiceSid: string | null
  
  /** External API Key used by Integrations to read/write on Ticket Goat */
  externalApiKey: string | null
  
  /** IP Addresses that are allowed to connect to the API. */
  allowedIpAddresses: string | null
  
  /** If enabled, patrons will be asked during their purchase if they want to sign up for marketing communications. Note that the actual sending of marketing communications is an orthogonal process not handled by this application in any way. */
  marketingEnabled: boolean | null
  
  /** If enabled, tenant can send text messages to all customers who subscribed to the service */
  textMessagingEnabled: boolean | null
  
  /** If enabled, Meta Pixels will be running in the front-end web pages */
  metaPixelsEnabled: boolean | null
  
  /** If enabled, tenant will see the Ticket Goat disclaimer message during checkout */
  bankStatementNotificationEnabled: boolean | null
  
  /** A Meta Pixel Application ID is unique key for each tenant and can be managed over Meta Pixel admin console */
  metaPixelAppId: string | null
  paymentGatewayId: number | null
  paymentGateway: PaymentGateway | null
  
  /** The cost of the purchase protection option, as a percentage of the total of the Ticket Prices for the purchase. */
  refundableFeePercentage: number | null
  
  /** A percent surcharge applied to the grand total (before taxes) of all purchases. */
  convenienceFeePercentage: number | null
  
  /** A surcharge, in dollars, applied for each ticket in a purchase. If blank, Service Fee will be customizable per Ticket Type and agents will be able to waive this fee on a per-purchase basis. */
  serviceFeeAmount: number | null
  
  /** This will allow adding images to the event list view. */
  showImageOnListView: boolean | null
  
  /** This flag indicates whether this Tenant is allowed to use thier API key to integrate with Ticket Goat. */
  isIntegrationsEnabled: boolean | null
  
  /** Allows Tenant to alter the text on the Sell Tickets and More Options button label */
  isCustomMoreOptionsButtonEnabled: boolean | null
  
  /** Shows the status column when on the list view. */
  showStatusColumnOnListView: boolean | null
  
  /** This will allow tenant to create non-paid events. Provide an extra step while creating an event. */
  isAllowFreeEvent: boolean | null
  
  /** Enabling this will show remaining seats for all active events. */
  showRemainingSeats: boolean | null
  
  /** The email to notify when a purchase with dietary restrictions is made. */
  dietaryRestrictionNotificationEmailAddress: string | null
  colorPrimary: string | null
  colorSecondary: string | null
  colorAccent: string | null
  hosts: TenantHost[] | null
  faviconUrl: string | null
  fullLogoUrl: string | null
  squareLogoUrl: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class Tenant {
  
  /** Mutates the input object and its descendents into a valid Tenant implementation. */
  static convert(data?: Partial<Tenant>): Tenant {
    return convertToModel(data || {}, metadata.Tenant) 
  }
  
  /** Maps the input object and its descendents to a new, valid Tenant implementation. */
  static map(data?: Partial<Tenant>): Tenant {
    return mapToModel(data || {}, metadata.Tenant) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.Tenant; }
  
  /** Instantiate a new Tenant, optionally basing it on the given data. */
  constructor(data?: Partial<Tenant> | {[k: string]: any}) {
    Object.assign(this, Tenant.map(data || {}));
  }
}


export interface TenantHost extends Model<typeof metadata.TenantHost> {
  tenantHostId: number | null
  tenantId: number | null
  tenant: Tenant | null
  
  /** 
    A valid domain for the tenant, like
    "tickets.cdacruises.com", "localhost", 127.0.0.1, or "cdaevents-test.azurewebsites.net"
  */
  host: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class TenantHost {
  
  /** Mutates the input object and its descendents into a valid TenantHost implementation. */
  static convert(data?: Partial<TenantHost>): TenantHost {
    return convertToModel(data || {}, metadata.TenantHost) 
  }
  
  /** Maps the input object and its descendents to a new, valid TenantHost implementation. */
  static map(data?: Partial<TenantHost>): TenantHost {
    return mapToModel(data || {}, metadata.TenantHost) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TenantHost; }
  
  /** Instantiate a new TenantHost, optionally basing it on the given data. */
  constructor(data?: Partial<TenantHost> | {[k: string]: any}) {
    Object.assign(this, TenantHost.map(data || {}));
  }
}


export interface TenantMembership extends Model<typeof metadata.TenantMembership> {
  tenantMembershipId: number | null
  tenantId: number | null
  tenant: Tenant | null
  userId: string | null
  user: AppUser | null
  
  /** 
    This config will be controlling subscription per tenant level
    ///
  */
  isEmailSubscribed: boolean | null
  
  /** 
    This config will be controlling subscription per tenant level
    ///
  */
  isPhoneSubscribed: boolean | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class TenantMembership {
  
  /** Mutates the input object and its descendents into a valid TenantMembership implementation. */
  static convert(data?: Partial<TenantMembership>): TenantMembership {
    return convertToModel(data || {}, metadata.TenantMembership) 
  }
  
  /** Maps the input object and its descendents to a new, valid TenantMembership implementation. */
  static map(data?: Partial<TenantMembership>): TenantMembership {
    return mapToModel(data || {}, metadata.TenantMembership) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TenantMembership; }
  
  /** Instantiate a new TenantMembership, optionally basing it on the given data. */
  constructor(data?: Partial<TenantMembership> | {[k: string]: any}) {
    Object.assign(this, TenantMembership.map(data || {}));
  }
}


export interface TicketInput extends Model<typeof metadata.TicketInput> {
  eventPriceId: number | null
  quantity: number | null
}
export class TicketInput {
  
  /** Mutates the input object and its descendents into a valid TicketInput implementation. */
  static convert(data?: Partial<TicketInput>): TicketInput {
    return convertToModel(data || {}, metadata.TicketInput) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketInput implementation. */
  static map(data?: Partial<TicketInput>): TicketInput {
    return mapToModel(data || {}, metadata.TicketInput) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketInput; }
  
  /** Instantiate a new TicketInput, optionally basing it on the given data. */
  constructor(data?: Partial<TicketInput> | {[k: string]: any}) {
    Object.assign(this, TicketInput.map(data || {}));
  }
}


export interface TicketPurchaseAddon extends Model<typeof metadata.TicketPurchaseAddon> {
  addonId: number | null
  addonItemConfigurationId: number | null
  name: string | null
  marketingDescription: string | null
  price: number | null
  convenienceFee: number | null
  refundedPrice: number | null
  totalValue: number | null
  salesTax: number | null
  isPerTicket: boolean | null
  status: AddonStatus | null
  
  /** Indicates if the addon will have a printed, scannable ticket or not. */
  isPrintable: boolean | null
  relatedPurchase: TicketPurchaseRelatedPurchase | null
}
export class TicketPurchaseAddon {
  
  /** Mutates the input object and its descendents into a valid TicketPurchaseAddon implementation. */
  static convert(data?: Partial<TicketPurchaseAddon>): TicketPurchaseAddon {
    return convertToModel(data || {}, metadata.TicketPurchaseAddon) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchaseAddon implementation. */
  static map(data?: Partial<TicketPurchaseAddon>): TicketPurchaseAddon {
    return mapToModel(data || {}, metadata.TicketPurchaseAddon) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchaseAddon; }
  
  /** Instantiate a new TicketPurchaseAddon, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchaseAddon> | {[k: string]: any}) {
    Object.assign(this, TicketPurchaseAddon.map(data || {}));
  }
}


export interface TicketPurchaseDietaryRestriction extends Model<typeof metadata.TicketPurchaseDietaryRestriction> {
  allergen: string | null
  guestQuantity: number | null
  comment: string | null
  canComment: boolean | null
}
export class TicketPurchaseDietaryRestriction {
  
  /** Mutates the input object and its descendents into a valid TicketPurchaseDietaryRestriction implementation. */
  static convert(data?: Partial<TicketPurchaseDietaryRestriction>): TicketPurchaseDietaryRestriction {
    return convertToModel(data || {}, metadata.TicketPurchaseDietaryRestriction) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchaseDietaryRestriction implementation. */
  static map(data?: Partial<TicketPurchaseDietaryRestriction>): TicketPurchaseDietaryRestriction {
    return mapToModel(data || {}, metadata.TicketPurchaseDietaryRestriction) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchaseDietaryRestriction; }
  
  /** Instantiate a new TicketPurchaseDietaryRestriction, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchaseDietaryRestriction> | {[k: string]: any}) {
    Object.assign(this, TicketPurchaseDietaryRestriction.map(data || {}));
  }
}


export interface TicketPurchaseDiscount extends Model<typeof metadata.TicketPurchaseDiscount> {
  discountId: number | null
  description: string | null
  amount: number | null
  error: string | null
}
export class TicketPurchaseDiscount {
  
  /** Mutates the input object and its descendents into a valid TicketPurchaseDiscount implementation. */
  static convert(data?: Partial<TicketPurchaseDiscount>): TicketPurchaseDiscount {
    return convertToModel(data || {}, metadata.TicketPurchaseDiscount) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchaseDiscount implementation. */
  static map(data?: Partial<TicketPurchaseDiscount>): TicketPurchaseDiscount {
    return mapToModel(data || {}, metadata.TicketPurchaseDiscount) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchaseDiscount; }
  
  /** Instantiate a new TicketPurchaseDiscount, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchaseDiscount> | {[k: string]: any}) {
    Object.assign(this, TicketPurchaseDiscount.map(data || {}));
  }
}


export interface TicketPurchasePayment extends Model<typeof metadata.TicketPurchasePayment> {
  paymentId: number | null
  paymentTypeName: string | null
  amount: number | null
  description: string | null
  paymentKind: PaymentKind | null
  requiresCcInput: boolean | null
  canChangeAmount: boolean | null
  canRemove: boolean | null
  isRefund: boolean | null
  allowsNegativeSum: boolean | null
  allowsPositiveSum: boolean | null
  maxRefundableAmount: number | null
  
  /** 
    Collection of related purchases. 
    Includes both the payment that this is a refund of (if any),
    and the payments that are refunds of this payment (if any).
  */
  relatedPurchases: TicketPurchaseRelatedPurchase[] | null
}
export class TicketPurchasePayment {
  
  /** Mutates the input object and its descendents into a valid TicketPurchasePayment implementation. */
  static convert(data?: Partial<TicketPurchasePayment>): TicketPurchasePayment {
    return convertToModel(data || {}, metadata.TicketPurchasePayment) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchasePayment implementation. */
  static map(data?: Partial<TicketPurchasePayment>): TicketPurchasePayment {
    return mapToModel(data || {}, metadata.TicketPurchasePayment) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchasePayment; }
  
  /** Instantiate a new TicketPurchasePayment, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchasePayment> | {[k: string]: any}) {
    Object.assign(this, TicketPurchasePayment.map(data || {}));
  }
}


export interface TicketPurchaseRelatedPurchase extends Model<typeof metadata.TicketPurchaseRelatedPurchase> {
  publicId: string | null
  description: string | null
  
  /** The specific tickets from the related purchase that have a relation to the parent TicketPurchase. Not populated in all cases. */
  ticketIds: number[] | null
  
  /** The specific addons from the related purchase that have a relation to the parent TicketPurchase. Not populated in all cases. */
  addonIds: number[] | null
}
export class TicketPurchaseRelatedPurchase {
  
  /** Mutates the input object and its descendents into a valid TicketPurchaseRelatedPurchase implementation. */
  static convert(data?: Partial<TicketPurchaseRelatedPurchase>): TicketPurchaseRelatedPurchase {
    return convertToModel(data || {}, metadata.TicketPurchaseRelatedPurchase) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchaseRelatedPurchase implementation. */
  static map(data?: Partial<TicketPurchaseRelatedPurchase>): TicketPurchaseRelatedPurchase {
    return mapToModel(data || {}, metadata.TicketPurchaseRelatedPurchase) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchaseRelatedPurchase; }
  
  /** Instantiate a new TicketPurchaseRelatedPurchase, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchaseRelatedPurchase> | {[k: string]: any}) {
    Object.assign(this, TicketPurchaseRelatedPurchase.map(data || {}));
  }
}


export interface TicketPurchaseTicket extends Model<typeof metadata.TicketPurchaseTicket> {
  ticketId: number | null
  eventPriceId: number | null
  seatNumber: string | null
  description: string | null
  price: number | null
  
  /** The ServiceFee for the ticket. */
  fees: number | null
  convenienceFee: number | null
  salesTax: number | null
  refundableFee: number | null
  discountTotal: number | null
  subtotalWithFees: number | null
  addons: number | null
  totalValue: number | null
  status: TicketStatus | null
  relatedPurchase: TicketPurchaseRelatedPurchase | null
}
export class TicketPurchaseTicket {
  
  /** Mutates the input object and its descendents into a valid TicketPurchaseTicket implementation. */
  static convert(data?: Partial<TicketPurchaseTicket>): TicketPurchaseTicket {
    return convertToModel(data || {}, metadata.TicketPurchaseTicket) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketPurchaseTicket implementation. */
  static map(data?: Partial<TicketPurchaseTicket>): TicketPurchaseTicket {
    return mapToModel(data || {}, metadata.TicketPurchaseTicket) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketPurchaseTicket; }
  
  /** Instantiate a new TicketPurchaseTicket, optionally basing it on the given data. */
  constructor(data?: Partial<TicketPurchaseTicket> | {[k: string]: any}) {
    Object.assign(this, TicketPurchaseTicket.map(data || {}));
  }
}


export interface TicketTemplate extends Model<typeof metadata.TicketTemplate> {
  id: number | null
  description: string | null
  htmlContent: string | null
  pageHeightInches: number | null
  pageWidthInches: number | null
  companyName: string | null
  events: Event[] | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class TicketTemplate {
  
  /** Mutates the input object and its descendents into a valid TicketTemplate implementation. */
  static convert(data?: Partial<TicketTemplate>): TicketTemplate {
    return convertToModel(data || {}, metadata.TicketTemplate) 
  }
  
  /** Maps the input object and its descendents to a new, valid TicketTemplate implementation. */
  static map(data?: Partial<TicketTemplate>): TicketTemplate {
    return mapToModel(data || {}, metadata.TicketTemplate) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.TicketTemplate; }
  
  /** Instantiate a new TicketTemplate, optionally basing it on the given data. */
  constructor(data?: Partial<TicketTemplate> | {[k: string]: any}) {
    Object.assign(this, TicketTemplate.map(data || {}));
  }
}


export interface Venue extends Model<typeof metadata.Venue> {
  id: number | null
  displayName: string | null
  
  /** The type of early arrival that can occur for events in this venue. This verbiage will be displayed next to event descriptions and is available in ticket/email templates. */
  boardingLabel: BoardingLabel | null
  
  /** The tax percentage applied to the grand total of the purchase. */
  salesTaxPercentage: number | null
  disclaimer: string | null
  events: Event[] | null
  seatMaps: SeatMap[] | null
  address: string | null
  state: string | null
  zip: string | null
  country: string | null
  
  /** The time zone of the venue. Choose from the available US time zones. */
  timeZone: string | null
  creationUserName: string | null
  creationDate: Date | null
  lastChangeUserName: string | null
  lastChangeDate: Date | null
}
export class Venue {
  
  /** Mutates the input object and its descendents into a valid Venue implementation. */
  static convert(data?: Partial<Venue>): Venue {
    return convertToModel(data || {}, metadata.Venue) 
  }
  
  /** Maps the input object and its descendents to a new, valid Venue implementation. */
  static map(data?: Partial<Venue>): Venue {
    return mapToModel(data || {}, metadata.Venue) 
  }
  
  static [Symbol.hasInstance](x: any) { return x?.$metadata === metadata.Venue; }
  
  /** Instantiate a new Venue, optionally basing it on the given data. */
  constructor(data?: Partial<Venue> | {[k: string]: any}) {
    Object.assign(this, Venue.map(data || {}));
  }
}


declare module "coalesce-vue/lib/model" {
  interface EnumTypeLookup {
    AddonItemType: AddonItemType
    AddonStatus: AddonStatus
    BoardingLabel: BoardingLabel
    DiscountType: DiscountType
    EmailType: EmailType
    PaymentKind: PaymentKind
    PurchaseStatus: PurchaseStatus
    PurchaseType: PurchaseType
    RefundableState: RefundableState
    SeatMapAnnotationShape: SeatMapAnnotationShape
    SectionTypes: SectionTypes
    TicketStatus: TicketStatus
  }
  interface ModelTypeLookup {
    AddonItemConfiguration: AddonItemConfiguration
    AddonItemDefinition: AddonItemDefinition
    AppRole: AppRole
    AppUser: AppUser
    AppUserAllTenantRole: AppUserAllTenantRole
    AppUserRole: AppUserRole
    AvailableSeatsDetails: AvailableSeatsDetails
    Consignee: Consignee
    ConsigneeCommission: ConsigneeCommission
    ConsigneeDiscountDefinition: ConsigneeDiscountDefinition
    ConsigneeRep: ConsigneeRep
    CreditCardDetails: CreditCardDetails
    DiscountDefinition: DiscountDefinition
    DiscountDefinitionConfiguration: DiscountDefinitionConfiguration
    EmailTemplate: EmailTemplate
    Event: Event
    EventAddonDto: EventAddonDto
    EventAddonItemDefinition: EventAddonItemDefinition
    EventDate: EventDate
    EventDateDiscountDefinition: EventDateDiscountDefinition
    EventDateDto: EventDateDto
    EventDatePriceConfig: EventDatePriceConfig
    EventDateSeatingInfo: EventDateSeatingInfo
    EventDateSeatingRestrictionInfo: EventDateSeatingRestrictionInfo
    EventDateSeatRestriction: EventDateSeatRestriction
    EventDiscountDefinition: EventDiscountDefinition
    EventDto: EventDto
    EventEmailTemplate: EventEmailTemplate
    EventImage: EventImage
    EventPrice: EventPrice
    EventPriceDto: EventPriceDto
    EventPriceRevenueDistribution: EventPriceRevenueDistribution
    EventSection: EventSection
    EventSectionPrice: EventSectionPrice
    EventSuggestedEvent: EventSuggestedEvent
    EventType: EventType
    EventTypeDto: EventTypeDto
    PaymentGateway: PaymentGateway
    PostalCodeInfo: PostalCodeInfo
    RevenueCategory: RevenueCategory
    SeatMap: SeatMap
    SeatMapAnnotationDto: SeatMapAnnotationDto
    SeatMapDto: SeatMapDto
    SeatMapSeatDto: SeatMapSeatDto
    SeatMapSection: SeatMapSection
    SeatMapSectionDto: SeatMapSectionDto
    Tenant: Tenant
    TenantHost: TenantHost
    TenantMembership: TenantMembership
    TicketInput: TicketInput
    TicketPurchaseAddon: TicketPurchaseAddon
    TicketPurchaseDietaryRestriction: TicketPurchaseDietaryRestriction
    TicketPurchaseDiscount: TicketPurchaseDiscount
    TicketPurchaseDto: TicketPurchaseDto
    TicketPurchasePayment: TicketPurchasePayment
    TicketPurchaseRelatedPurchase: TicketPurchaseRelatedPurchase
    TicketPurchaseTicket: TicketPurchaseTicket
    TicketTemplate: TicketTemplate
    Venue: Venue
  }
}
